import { Icon } from "@chakra-ui/react";
import React from "react";

const OptionIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      {...rest}
      fill="none"
    >
      <rect width="20" height="20" rx="3" fill={color} />
      <rect x="4" y="8" width="2" height="10" rx="0.2" fill="white" />
      <rect x="9" y="8" width="2" height="10" rx="0.2" fill="white" />
      <rect x="14" y="10" width="2" height="8" rx="0.2" fill="white" />
      <path
        d="M4 4.1C3.77909 4.1 3.6 4.27909 3.6 4.5C3.6 4.72091 3.77909 4.9 4 4.9V4.1ZM16 4.5L12 2.1906V6.8094L16 4.5ZM4 4.9H12.4V4.1H4V4.9Z"
        fill="white"
      />
    </Icon>
  );
};

export default OptionIcon;
