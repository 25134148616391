import { Spinner, Stack } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Stack
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Spinner color="brand.900" size={"xl"} />
    </Stack>
  );
};

export default Loading;
