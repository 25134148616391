import {
  Avatar,
  Box,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import withRouter from "components/hoc/withRouter";
import React from "react";

const OverView = (props) => {
  const { title, data, router, isDaily, refer,isMobile  } = props;
  return (
    <Stack p={refer ? 0 : 4} bg="white">
      <Text fontSize={18} fontWeight={500} borderRadius="2px">
        {title}
      </Text>
      <Grid
        gap={4}
        templateColumns={isMobile?'repeat(1,1fr)':`repeat(${isDaily ? 3 : data.length}, 1fr)`}
      >
        {data?.map((item, i) => {
          return (
            <GridItem w="100%" key={i}>
              <Stack
                bg={item.color}
                py={6}
                px={4}
                borderRadius="5px"
                direction={"row"}
                alignItems="center"
                cursor={item.isNotClickable && "pointer"}
                onClick={
                  item?.onClick
                    ? item?.onClick
                    : () =>
                        item.isNotClickable &&
                        router.navigate(item.link, {
                          state: {
                            tab: item?.tab,
                          },
                        })
                }
                border={`2px solid ${
                  item?.isSelected ? item?.border : "transparent"
                }`}
                _hover={{
                  border: item.isNotClickable && `2px solid ${item.border}`,
                }}
              >
                sss{" "}
                {item.width ? (
                  <Box
                    w="48px"
                    h="48px"
                    bg="white"
                    borderRadius={"15px"}
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                  >
                    <Image
                      src={item.icon}
                      // bg="white"
                      // color="black"
                      // borderRadius={"0"}
                      // size={4}
                      width={item.width}
                      height={item.height}
                      // p={2}
                    />
                  </Box>
                ) : (
                  <Box
                    w="48px"
                    h="48px"
                    borderRadius={"15px"}
                    overflow="hidden"
                  >
                    <Avatar
                      src={item.icon}
                      bg="white"
                      color="black"
                      borderRadius={"0"}
                      // size={4}
                      // w={item.width && item.width}
                      // h={item.height && item.height}
                      p={2}
                    />
                  </Box>
                )}
                <Stack spacing={0}>
                  <Text fontSize={14} color="#888888">
                    {item.title}
                  </Text>
                  <Text fontSize={18} color="#141414">
                    {item.value}
                  </Text>
                </Stack>
              </Stack>
            </GridItem>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default withRouter(OverView);
