import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connectorBanner: true,
  optionBanner: true,
  scannerBanner: true,
  brokerBanner: true,
  createStrategyBanner: true,
  createScannerBanner: true,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    toggleUniversalBanner: (state, action) => {
      state[action.payload.myKey] = !state[action.payload.myKey];
    },
  },
  extraReducers: {},
});

export const { toggleUniversalBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
