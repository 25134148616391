import { Icon } from "@chakra-ui/react";
import React from "react";

const CopyIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6429 4.5H6V4C6 1.79086 7.79086 0 10 0H16C18.2091 0 20 1.79086 20 4V10C20 12.2091 18.2091 14 16 14H15.5V9.35714C15.5 6.67462 13.3254 4.5 10.6429 4.5ZM4 20H10C12.2091 20 14 18.2091 14 16V10C14 7.79086 12.2091 6 10 6H4C1.79086 6 0 7.79086 0 10V16C0 18.2091 1.79086 20 4 20ZM10.4939 10.4356C10.8056 10.7083 10.8372 11.1822 10.5644 11.4939L7.69452 14.7738C7.07783 15.4786 6.01558 15.5729 5.2843 14.9879L3.53148 13.5857C3.20803 13.3269 3.15559 12.8549 3.41435 12.5315C3.6731 12.208 4.14507 12.1556 4.46852 12.4144L6.22134 13.8166C6.32581 13.9002 6.47756 13.8867 6.56566 13.786L9.43556 10.5061C9.70833 10.1944 10.1821 10.1628 10.4939 10.4356Z"
        fill={ color || "#888888"}
      />
      <rect x="2" y="7" width="9" height="11" fill={ color || "#888888"} />
    </Icon>
  );
};

export default CopyIcon;
