import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "utils/interceptors";
const initialState = {
  isAuthenticated: false,
  isFirstTime: true,
  token: null,
  product: null,
  totalBacktest: 0,
  totalOptimization: 0,
  totalLive: 0,
  totalVirtual: 0,
  email: null,
  user_name: null,
  isContact: false,
  isPremium: false,
};

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue }) => {
    try {
      localStorage.clear();
      window.location.href = "/";
      const res = await instance.post("/api/user/v2/logout/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserDetails: (state, action) => {
      // let premVal = action.payload.user_subscription.message === "You have not subscribe for any pack." || undefined ? false : true,
      return {
        ...state,
        isAuthenticated: true,
        isFirstTime: false,
        token: action.payload.token,
        product: action.payload.user_subscription?.product || "Not Subscribed",
        totalBacktest: action.payload.user_subscription?.total_backtest || 0,
        totalOptimization:
          action.payload.user_subscription?.total_optimization || 0,
        totalLive: action.payload.user_subscription?.total_live_trade || 0,
        totalVirtual:
          action.payload.user_subscription?.total_virtual_trade || 0,
        totalScanner: action.payload.user_subscription?.total_scanner || 0,
        email: action.payload.email,
        user_name: action.payload.name,
        billingDate:
          action.payload?.subscription_status?.next_billing_date || "-",
        isContact: action.payload.contact_no === null,
        // isPremium: !(
        //   action.payload.user_subscription.message ===
        //   "You have not subscribe for any pack."
        // )
        //   ? action.payload.user_subscription?.product === "Premium"
        //     ? true
        //     : false
        //   : false,
        isPremium : action.payload.user_subscription?.allowed_option_trade,
      };
    },
    setFirstTime: (state, action) => {
      state.isFirstTime = true;
    },
    setContactModal: (state, action) => {
      state.isContact = !state.isContact;
    },
  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      return initialState;
    },
  },
});

export const { setIsLoading, setUserDetails, setContactModal, setFirstTime } =
  authSlice.actions;
export default authSlice.reducer;
