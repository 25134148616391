import {
  Button,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from "@chakra-ui/react";
import FormInput from "controls/FormInput";
import FormNumberInput from "controls/FormNumberInput";
import React from "react";
import { flags } from "utils/flags";
import CustomForm from "utils/useForm";
import { useForm } from "react-hook-form";
import FormButton from "controls/Button";

const SubscribeDetails = () => {
  const methods = useForm();
  const handleSubmit = async (data) => {
    window.open("_blank");
  };
  return (
    <>
      <ModalHeader textAlign={"center"} marginTop={7}>
        <Text fontSize={"25px"}>Hi Investors !!</Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form
          onSubmit={handleSubmit}
          method="POST"
          action="https://script.google.com/macros/s/AKfycby408xhzmWdRWW0MpA7qTsv8KhW8COlT95J3kE0tb7vFtBOfgjVBCSi68erINYbTRPoQw/exec"
        >
          <Stack marginBottom={3}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input type="text" id="name" name="name" />
          </Stack>
          <Stack marginBottom={3} marginTop={2}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input type="text" id="email" name="email" />
          </Stack>
          <Stack>
            <FormLabel htmlFor="contact_no" marginTop={2}>
              Mobile No.
            </FormLabel>
            <Input type="text" id="contact_no" name="contact_no" />
          </Stack>

          <Stack marginBottom={5} marginTop={10}>
            <Button
              bgColor={"#01a2bb"}
              color={"white"}
              variant="solid"
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </ModalBody>
    </>
  );
};

export default SubscribeDetails;
