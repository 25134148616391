import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Hide,
    Modal,
    Stack,
    Text,
    useColorMode,
    useDisclosure,
    Show,
  } from "@chakra-ui/react";
  import AstraInactiveIcon from "assets/astra";
  import AstraIcon from "assets/astraactive";
  import BasketIcon from "assets/basket";
  import BrokerIcon from "assets/broker";
  import BrokerActiveIcon from "assets/brokerActive";
  import ChartingToolIcon from "assets/charting";
  import ChartingToolInactiveIcon from "assets/chartinginactive";
  import ConnectorIcon from "assets/connector";
  import ConnectorInactiveIcon from "assets/connector-inactive";
  import DashboardIcon from "assets/dashboard";
  import DashboardInactiveIcon from "assets/dashboardinactive";
  import OptionInactiveIcon from "assets/option";
  import OptionIcon from "assets/optionactive";
  import OrdersIcon from "assets/order";
  import OrdersInactiveIcon from "assets/orders-inactive";
  import ScannerInactiveIcon from "assets/scanner";
  import ScannerIcon from "assets/scanneractive";
  import StrategyInactiveIcon from "assets/strategies";
  import StrategiesIcon from "assets/strategiesFilled";
  import StrategiesLiveIcon from "assets/StrategiesLiveFilled";
  import StrategiesLiveDisabledIcon from "assets/StrategiesLiveOutlined";
  import StrategiesOptimizeIcon from "assets/StrategiesOptimizeFilled";
  import StrategiesOptimizeDisabledIcon from "assets/StrategiesOptimizeOutlined";
  import StrategiesVirtualIcon from "assets/StrategiesVirtualFilled";
  import StrategiesVirtualDisabledIcon from "assets/StrategiesVirtualOutlined";
  import withRouter from "components/hoc/withRouter";
  import AllBasket from "components/modals/AllBasket";
  import React from "react";
  import { Link } from "react-router-dom";
  import styles from "styles/App.module.css";
  
  import routes from "utils/routes";
  // #004393 - Primary
  // #004393 - Secondary
  // #1B1464 - Icons
  // #F9EB21 - Illustrations elements, Social media, Chat Button etc.
  const FooterMenu = (props) => {
    const { colorMode } = useColorMode();
    const {
      isOpen: isOpenBasket,
      onOpen: onOpenBasket,
      onClose: onCloseBasket,
    } = useDisclosure();
  const childrenLinks=[
    {
      name: "My Strategy",
      isMobile:false,
      link: routes.stratezy,
      icon: [
        routes.stratezy,
        routes.optimize,
        routes.live,
        routes.virtual,
      ].includes(props?.router.location?.pathname) ? (
        <StrategiesIcon
          color={
            [
              routes.stratezy,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ) : (
        <StrategyInactiveIcon
          color={
            [
              routes.stratezy,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ),
    },
    {
      name: "Optimize ",
      link: routes.optimize,
      isMobile:true,
      icon: [
        routes.stratezy,
        routes.optimize,
        routes.live,
        routes.virtual,
      ].includes(props?.router.location?.pathname) ? (
        <StrategiesOptimizeIcon
          color={
            [
              routes.optimize,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ) : (
        <StrategiesOptimizeDisabledIcon
          color={
            [
              routes.stratezy,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ),
    },
    {
      name: "Virtual",
      link: routes.virtual,
      isMobile:true,
      icon: [
        routes.stratezy,
        routes.optimize,
        routes.live,
        routes.virtual,
      ].includes(props?.router.location?.pathname) ? (
        <StrategiesVirtualIcon
          color={
            [
              routes.virtual,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ) : (
        <StrategiesVirtualDisabledIcon
          color={
            [
              routes.stratezy,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ),
    },
    {
      name: "Live",
      link: routes.live,
      isMobile:true,
      icon: [
        routes.stratezy,
        routes.optimize,
        routes.live,
        routes.virtual,
      ].includes(props?.router.location?.pathname) ? (
        <StrategiesLiveIcon
          color={
            [
              routes.live,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ) : (
        <StrategiesLiveDisabledIcon
          color={
            [
              routes.stratezy,
            ].includes(props?.router.location?.pathname)
              ? "#004393"
              : "#888888"
          }
        />
      ),
    },
  ]
    const sideLinks = [
      {
        name: "Dashboard",
        link: routes.home,
        isMobile:true,
        icon:
          props?.router.location?.pathname === routes.home ? (
            <DashboardIcon
              color={
                props?.router.location?.pathname === routes.home
                  ? "#004393"
                  : "#888888"
              }
            />
          ) : (
            <DashboardInactiveIcon />
          ),
      },
      {
        name: "Strategies",
        // have to remove this
        mobileLink:routes.stratezy,
        isMobile:true,
        icon: [
          routes.stratezy,
          routes.optimize,
          routes.live,
          routes.virtual,
        ].includes(props?.router.location?.pathname) ? (
          <StrategiesIcon
            color={
              [
                routes.stratezy,
                routes.optimize,
                routes.live,
                routes.virtual,
              ].includes(props?.router.location?.pathname)
                ? "#004393"
                : "#888888"
            }
          />
        ) : (
          <StrategyInactiveIcon
            color={
              [
                routes.stratezy,
                routes.optimize,
                routes.live,
                routes.virtual,
              ].includes(props?.router.location?.pathname)
                ? "#004393"
                : "#888888"
            }
          />
        ),
        children: childrenLinks
      },
      // {
      //   name: "Broker",
      //   link: routes.broker,
      //   icon:
      //     props?.router.location?.pathname === routes.broker ? (
      //       <BrokerActiveIcon />
      //     ) : (
      //       <BrokerIcon
      //         color={
      //           props?.router.location?.pathname === routes.broker
      //             ? "#004393"
      //             : "#888888"
      //         }
      //       />
      //     ),
      // },
      {
        name: "Astra",
        link: routes.astra,
        isMobile:true,
        icon:
          props?.router.location?.pathname === routes.astra ? (
            <AstraIcon color="#004393" />
          ) : (
            <AstraInactiveIcon
              color={
                props?.router.location?.pathname === routes.astra
                  ? "#004393"
                  : "#888888"
              }
            />
          ),
      },
      {
        name: "Connectors",
        link: routes.connectors,
        isMobile:true,
        icon:
          props?.router.location?.pathname === routes.connectors ? (
            <ConnectorIcon color={"#004393"} />
          ) : (
            <ConnectorInactiveIcon />
          ),
      },
      {
        name: "Scanners",
        link: routes.scanners,
        isMobile:true,
        icon:
          props?.router.location?.pathname === routes.scanners ? (
            <ScannerIcon color="#004393" />
          ) : (
            <ScannerInactiveIcon
              color={
                props?.router.location?.pathname === routes.scanners
                  ? "#004393"
                  : "#888888"
              }
            />
          ),
      },
      {
        name: "Option Strategy",
        link: routes.optionStrategy,
        isMobile:true,
        shortName:"Option",
        icon:
          props?.router.location?.pathname === routes.optionStrategy ? (
            <OptionIcon color="#004393" />
          ) : (
            <OptionInactiveIcon
              color={
                props?.router.location?.pathname === routes.optionStrategy
                  ? "#004393"
                  : "#888888"
              }
            />
          ),
      },
      {
        name: "Charting Tool",
        link: routes.chartingTool,
        icon:
          props?.router.location?.pathname === routes.chartingTool ? (
            <ChartingToolIcon color="#004393" />
          ) : (
            <ChartingToolInactiveIcon
              color={
                props?.router.location?.pathname === routes.chartingTool
                  ? "#004393"
                  : "#888888"
              }
            />
          ),
      },
      {
        name: "Order Book",
        link: routes.orders,
        isMobile:true,
        shortName:"Order",
        icon:
          props?.router.location?.pathname === routes.orders ? (
            <OrdersIcon
              color={
                props?.router.location?.pathname === routes.orders
                  ? "#004393"
                  : "#888888"
              }
            />
          ) : (
            <OrdersInactiveIcon color="#888888" />
          ),
      },
      {
        name: "Basket",
        link: null,
        isMobile:true,
        icon: <BasketIcon cursor="pointer" />,
        onClick: () => onOpenBasket(),
      },
    ];
  
    const mobileSideLinks=[...sideLinks,...childrenLinks];
    return (
      <>
          <Box
            pos="absolute"
            display="flex"
            width="100vw"
            overflowX="scroll"
            zIndex="100"
            background="#fff"
            bottom={0}
            className={`${styles.footer}`}
            // boxShadow='md'
            boxShadow="0px -1px 4px 1px #0000005c"
          >
            {mobileSideLinks.map((sideLink) => {
              // {console.log(sideLink)}
             if(sideLink?.isMobile){
              return (
                <Box
                  key={sideLink.name}
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                  gap={1}
                  p={1}
                  fontSize={"10px"}
                  flexDirection="column"
                  w="20vw"
                  background={
                    (sideLink.link === props?.router.location?.pathname || sideLink.mobileLink === props?.router.location?.pathname)
                      ? colorMode === "light"
                        ? "rgba(208, 235, 255, 0.65)"
                        : "#1971c233"
                      : null
                  }
                  borderTop={
                    (sideLink.link === props?.router.location?.pathname || sideLink.mobileLink === props?.router.location?.pathname)
                      ? "5px solid #1B1464"
                      : "5px solid transparent"
                  }
                  as={Link}
                  to={sideLink.link || sideLink.mobileLink}
                  onClick={(sideLink?.link || sideLink.mobileLink) ? null : sideLink.onClick}
                >
                  {sideLink.icon}
                  <div>{sideLink?.shortName || sideLink.name}</div>
                </Box>
              )};
            })}
          </Box>
       
        <Modal
          isOpen={isOpenBasket}
          onClose={onCloseBasket}
          isCentered
          closeOnOverlayClick={false}
          size="xl"
          scrollBehavior={"inside"}
        >
          <AllBasket onCloseBasket={onCloseBasket} />
        </Modal>
      </>
    );
  };
  
  export default withRouter(FooterMenu);
  