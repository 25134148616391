import { Icon } from "@chakra-ui/react";
import React from "react";

const StrategiesIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      role="img"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 6.5C18.4459 6.5 19.3152 6.17164 20 5.62269V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0H14.3773C13.8284 0.684799 13.5 1.55405 13.5 2.5C13.5 4.70914 15.2909 6.5 17.5 6.5ZM6.75 7C6.75 6.30964 7.30964 5.75 8 5.75C8.69036 5.75 9.25 6.30964 9.25 7V13C9.25 14.5188 10.4812 15.75 12 15.75C13.5188 15.75 14.75 14.5188 14.75 13V12C14.75 11.3096 15.3096 10.75 16 10.75H17C17.4142 10.75 17.75 10.4142 17.75 10C17.75 9.58579 17.4142 9.25 17 9.25H16C14.4812 9.25 13.25 10.4812 13.25 12V13C13.25 13.6904 12.6904 14.25 12 14.25C11.3096 14.25 10.75 13.6904 10.75 13V7C10.75 5.48122 9.51878 4.25 8 4.25C6.48122 4.25 5.25 5.48122 5.25 7V8C5.25 8.69036 4.69036 9.25 4 9.25H3C2.58579 9.25 2.25 9.58579 2.25 10C2.25 10.4142 2.58579 10.75 3 10.75H4C5.51878 10.75 6.75 9.51878 6.75 8V7ZM17.5 5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0C16.1193 0 15 1.11929 15 2.5C15 3.88071 16.1193 5 17.5 5Z"
        fill={color}
      />
    </Icon>
  );
};

export default StrategiesIcon;
