import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Modal,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import AstraInactiveIcon from "assets/astra";
import AstraIcon from "assets/astraactive";
import BasketIcon from "assets/basket";
import BrokerIcon from "assets/broker";
import BrokerActiveIcon from "assets/brokerActive";
import ChartingToolIcon from "assets/charting";
import ChartingToolInactiveIcon from "assets/chartinginactive";
import ConnectorIcon from "assets/connector";
import ConnectorInactiveIcon from "assets/connector-inactive";
import DashboardIcon from "assets/dashboard";
import DashboardInactiveIcon from "assets/dashboardinactive";
import OptionInactiveIcon from "assets/option";
import OptionIcon from "assets/optionactive";
import OrdersIcon from "assets/order";
import OrdersInactiveIcon from "assets/orders-inactive";
import ScannerInactiveIcon from "assets/scanner";
import ScannerIcon from "assets/scanneractive";
import StrategyInactiveIcon from "assets/strategies";
import StrategiesIcon from "assets/strategiesFilled";
import withRouter from "components/hoc/withRouter";
import AllBasket from "components/modals/AllBasket";
import React from "react";
import { Link } from "react-router-dom";
import routes from "utils/routes";
import styles from "styles/App.module.css";
import { UnlockIcon, ViewIcon } from "@chakra-ui/icons";

const Sidebar = (props) => {
  const { colorMode } = useColorMode();
  const {
    isOpen: isOpenBasket,
    onOpen: onOpenBasket,
    onClose: onCloseBasket,
  } = useDisclosure();
  const sideLinks = [
    {
      name: "Dashboard",
      link: routes.home,
      icon:
        props?.router.location?.pathname === routes.home ? (
          <DashboardIcon
            color={
              props?.router.location?.pathname === routes.home
                ? "#00a3be"
                : "#888888"
            }
          />
        ) : (
          <DashboardInactiveIcon />
        ),
    },
    // {
    //   name: "Strategies",
    //   link:
    //     routes.stratezy,
    //   icon: [
    //     routes.stratezy,
    //     routes.optimize,
    //     routes.live,
    //     routes.virtual,
    //   ].includes(props?.router.location?.pathname) ? (
    //     <StrategiesIcon
    //       color={
    //         [
    //           routes.stratezy,
    //           routes.optimize,
    //           routes.live,
    //           routes.virtual,
    //         ].includes(props?.router.location?.pathname)
    //           ? "#00a3be"
    //           : "#888888"
    //       }
    //     />
    //   ) : (
    //     <StrategyInactiveIcon
    //       color={
    //         [
    //           routes.stratezy,
    //           routes.optimize,
    //           routes.live,
    //           routes.virtual,
    //         ].includes(props?.router.location?.pathname)
    //           ? "#00a3be"
    //           : "#888888"
    //       }
    //     />
    //   ),
    //   children: [
    //     {
    //       name: "My Strategy",
    //       link: routes.stratezy,
    //     },
    //     {
    //       name: "Optimize ",
    //       link: routes.optimize,
    //     },
    //     {
    //       name: "Virtual",
    //       link: routes.virtual,
    //     },
    //     {
    //       name: "Live",
    //       link: routes.live,
    //     },
    //   ],
    // },
    {
      name: "Brokers",
      link: routes.broker,
      icon:
        props?.router.location?.pathname === routes.broker ? (
          <BrokerActiveIcon />
        ) : (
          <BrokerIcon
            color={
              props?.router.location?.pathname === routes.broker
                ? "#00a3be"
                : "#888888"
            }
          />
        ),
    },
    // {
    //   name: "Astra",
    //   link: routes.astra,
    //   icon:
    //     props?.router.location?.pathname === routes.astra ? (
    //       <AstraIcon color="#00a3be" />
    //     ) : (
    //       <AstraInactiveIcon
    //         color={
    //           props?.router.location?.pathname === routes.astra
    //             ? "#00a3be"
    //             : "#888888"
    //         }
    //       />
    //     ),
    // },
    // {
    //   name: "Connectors",
    //   link: routes.connectors,
    //   icon:
    //     props?.router.location?.pathname === routes.connectors ? (
    //       <ConnectorIcon color={"#00a3be"} />
    //     ) : (
    //       <ConnectorInactiveIcon />
    //     ),
    // },
    // {
    //   name: "Scanners",
    //   link: routes.scanners,
    //   icon:
    //     props?.router.location?.pathname === routes.scanners ? (
    //       <ScannerIcon color="#00a3be" />
    //     ) : (
    //       <ScannerInactiveIcon
    //         color={
    //           props?.router.location?.pathname === routes.scanners
    //             ? "#00a3be"
    //             : "#888888"
    //         }
    //       />
    //     ),
    // },
    // {
    //   name: "Option Strategy",
    //   link: routes.optionStrategy,
    //   icon:
    //     props?.router.location?.pathname === routes.optionStrategy ? (
    //       <OptionIcon color="#00a3be" />
    //     ) : (
    //       <OptionInactiveIcon
    //         color={
    //           props?.router.location?.pathname === routes.optionStrategy
    //             ? "#00a3be"
    //             : "#888888"
    //         }
    //       />
    //     ),
    // },
    {
      name: "Charting Tool",
      link: routes.chartingTool,
      icon:
        props?.router.location?.pathname === routes.chartingTool ? (
          <ChartingToolIcon color="#00a3be" />
        ) : (
          <ChartingToolInactiveIcon
            color={
              props?.router.location?.pathname === routes.chartingTool
                ? "#00a3be"
                : "#888888"
            }
          />
        ),
    },
    {
      name: "Order Book",
      link: routes.orders,
      icon:
        props?.router.location?.pathname === routes.orders ? (
          <OrdersIcon
            color={
              props?.router.location?.pathname === routes.orders
                ? "#00a3be"
                : "#888888"
            }
          />
        ) : (
          <OrdersInactiveIcon color="#888888" />
        ),
    },
    {
      name: "Advisory",
      link: routes.advisors,
      icon:
        props?.router.location?.pathname === routes.advisors ? (
          <ViewIcon
            color={
              props?.router.location?.pathname === routes.advisors
                ? "#00a3be"
                : "#888888"
            }
            boxSize={6}
          />
        ) : (
          <ViewIcon    boxSize={6} color="#888888" />
        ),
    },
    {
      name: "Open Position",
      link: routes.openorders,
      icon:
        props?.router.location?.pathname === routes.openorders ? (
          <UnlockIcon
            color={
              props?.router.location?.pathname === routes.openorders
                ? "#01A2BB"
                : "#888888"
            }
            boxSize={5}
          />
        ) : (
          <UnlockIcon color="#888888"   boxSize={5} />
        ),
    },

    // {
    //   name: "Basket",
    //   link: null,
    //   icon: <BasketIcon cursor="pointer" />,
    //   onClick: () => onOpenBasket(),
    // },
    // {
    //   name: "Marketplace",
    //   link: routes.customer,
    //   icon: [routes.customer, routes.advisor].includes(
    //     props?.router.location?.pathname
    //   ) ? (
    //     <StrategiesIcon
    //       color={
    //         [routes.customer, routes.advisor].includes(
    //           props?.router.location?.pathname
    //         )
    //           ? "#00a3be"
    //           : "#888888"
    //       }
    //     />
    //   ) : (
    //     <StrategyInactiveIcon
    //       color={
    //         [routes.customer, routes.advisor].includes(
    //           props?.router.location?.pathname
    //         )
    //           ? "#00a3be"
    //           : "#888888"
    //       }
    //     />
    //   ),
    //   children: [
    //     {
    //       name: "Customer",
    //       link: routes.customer,
    //     },
    //     {
    //       name: "Advisor",
    //       link: routes.advisor,
    //     },
    //   ],
    // },
  ];
  return (
    <>
      <div className={`${styles.sidebar} scrips-table`}>
        <Stack spacing={0}>
          {sideLinks.map((item, i) => {
            if (!item.children) {
              return (
                <Box
                  p={4}
                  display="flex"
                  alignItems={"center"}
                  gap={4}
                  key={i}
                  _hover={{
                    background: colorMode === "light" ? "#f8f9fa" : "#25262b",
                  }}
                  borderLeft={
                    item.link === props?.router.location?.pathname
                      ? "5px solid #00a3be"
                      : "5px solid transparent"
                  }
                  cursor="pointer"
                  background={
                    item.link === props?.router.location?.pathname
                      ? colorMode === "light"
                        ? "rgba(208, 235, 255, 0.65)"
                        : "#1971c233"
                      : null
                  }
                  color={
                    item.link === props?.router.location?.pathname
                      ? "#00a3be"
                      : colorMode === "light"
                      ? "rgb(136, 136, 136)"
                      : "#c1c2c5"
                  }
                  as={Link}
                  to={item.link}
                  onClick={item?.link ? null : item?.onClick}
                >
                  {item.name.includes("Option") ? (
                    <Box position="relative">
                      <Badge
                        bg={
                          item.link === props?.router.location?.pathname
                            ? "brand.900"
                            : "#888888"
                        }
                        fontSize={8}
                        color="#fff"
                        position="absolute"
                        top={0}
                        right={-4}
                      >
                        Beta
                      </Badge>
                      {item.icon}
                    </Box>
                  ) : (
                    item.icon
                  )}
                  {item.name}
                </Box>
              );
            } else {
              return (
                <Accordion allowToggle key={i}>
                  <AccordionItem border="none">
                    <h2>
                      <AccordionButton
                        borderLeft={
                          item.children
                            .map((t) => t.link)
                            .includes(props?.router.location?.pathname)
                            ? "5px solid #00a3be"
                            : "5px solid transparent"
                        }
                        cursor="pointer"
                        background={
                          item.children
                            .map((t) => t.link)
                            .includes(props?.router.location?.pathname)
                            ? colorMode === "light"
                              ? "rgba(208, 235, 255, 0.65)"
                              : "#1971c233"
                            : null
                        }
                        color={
                          item.children
                            .map((t) => t.link)
                            .includes(props?.router.location?.pathname)
                            ? "#00a3be"
                            : colorMode === "light"
                            ? "rgb(136, 136, 136)"
                            : "#c1c2c5"
                        }
                        p={4}
                      >
                        <Box
                          display="flex"
                          alignItems={"center"}
                          gap={4}
                          onClick={() => props.router.navigate(item?.link)}
                        >
                          {item?.icon}
                          {item.name}
                        </Box>
                        <AccordionIcon marginLeft={"auto"} />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <Stack pl={10} spacing={4}>
                        {item.children.map((child, ind) => {
                          return (
                            <Link to={child.link} key={ind}>
                              <Text
                                color={
                                  child.link ===
                                  props?.router.location?.pathname
                                    ? "#00a3be"
                                    : colorMode === "light"
                                    ? "rgb(136, 136, 136)"
                                    : "#c1c2c5"
                                }
                              >
                                {child.name}
                              </Text>
                            </Link>
                          );
                        })}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            }
          })}
        </Stack>
      </div>
      <Modal
        isOpen={isOpenBasket}
        onClose={onCloseBasket}
        isCentered
        closeOnOverlayClick={false}
        size="xl"
        scrollBehavior={"inside"}
      >
        <AllBasket onCloseBasket={onCloseBasket} />
      </Modal>
    </>
  );
};

export default withRouter(Sidebar);
