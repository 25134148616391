import FormButton from "controls/Button";
import ReferIcon from "assets/refer-n-earn.svg";
import {
  Stack,
  Text,
  Image,
  GridItem,
  Grid,
  useToast,
  Avatar,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RE from "assets/RE.svg";
import FormInput from "controls/FormInput";
import { useForm } from "react-hook-form";
import OverView from "components/dashboard/OverView";
import Price from "assets/price.svg";
import pro from "assets/pro.svg";
import { getReffCode } from "apis/profile";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Refer = (props) => {
  const methods = useForm();
  const [reffCode, setreffCode] = useState();
  const [Datas, setDatas] = useState();
  const [page, setPage] = useState(false);
  const toast = useToast();
  const {
    token
  } = useSelector((state) => state.auth);
  const RefErnDetaile = [
    {
      title: "Total Invite",
      value: `₹ ${Datas?.total_sign_up}`,
      color: "#F5E2FF",
      border: "#8104C6",
      icon: pro,
    },
    {
      title: "Credits Recived",
      value: `₹ ${Datas?.pending_commission}`,
      color: "#FFE2E2",
      border: "#6D3500",
      icon: Price,
    },
  ];

  useEffect(() => {
    if(token !== null){
   getReffCode()
   .then((res) => [
     setreffCode(res?.code),
     methods.setValue(
       "refInput",
       `${window.location.origin}/sign-up/${reffCode}`
     ),
     setDatas(res),
   ])
   .catch((err) => console.log(err))}
  }, [token,setreffCode]);
  return (
    <>
      {props?.isRefer || page ? (
        <>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={4}
            p={4}
            bg={"white"}
            height={"100vh"}
          >
            <GridItem w="100%">
              <Image src={RE} />
            </GridItem>
            <GridItem w="100%">
              <Stack>
                <Text color={"#888888"} fontWeight={"400"} fontSize={"50px"}>
                  Refer & Earn
                </Text>
                {/* <Text color={"#00a3be"} fontWeight={"600"} fontSize={"60px"}>
                  3 Free Credits
                </Text>
                <Text color={"#888888"} fontWeight={"400"} fontSize={"60px"}>
                  on each referral
                </Text> */}
              </Stack>
              <Text display={"flex"} width={"50%"}>
                Refferal Link
              </Text>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                alignItems={"flex-end"}
              >
                <FormInput
                  name="refInput"
                  control={methods.control}
                  error={methods.formState.errors}
                  disabled={true}
                  width={"95%"}
                  _disabled={{
                    background: "#EBFAFC",
                    cursor: "not-allowed",
                  }}
                  defaultValue={methods.setValue(
                    "refInput",
                    `${window.location.origin}/sign-up/${reffCode}`
                  )}
                  rules={{
                    //   required: "This field is required",
                    validate: (val) =>
                      /^\S+@\S+$/.test(val) ? null : "Enter a valid email",
                  }}
                />
                <FormButton
                  style={{ marginRight: "3.5%" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/sign-up/${reffCode}`
                    );
                    toast({
                      title: "Copied successfully!",
                      status: "success",
                      position: "top-right",
                      isClosable: true,
                    });
                  }}
                  text="Copy Link"
                />
              </Stack>
              <Text
                style={{
                  display: "flex",
                  width: "50%",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#00a3be",
                  cursor:"pointer",
                 
                }}
                mt="30px"
                mb="20px"
                onClick={() => window.open("https://www.youtube.com/channel/UC_bW6BJvjk9m06n62tl13UQ")}
              >
                How it works?
              </Text>
              <Stack>
                <GridItem >
                  <OverView data={RefErnDetaile} refer={"refer"} />
                </GridItem>
              </Stack>
            </GridItem>
          </Grid>
        </>
      ) : (
        <Stack
          p={4}
          bg="white"
          //   height="100%"
          justifyContent={"center"}
          alignItems="center"
        >
          <Avatar src={ReferIcon} bg="brand.900" />
          <Text fontSize={18}>Refer & Earn</Text>
          <Text color="#6c6c6c" fontSize={13} textAlign="center">
            Refer your friends and get 50% of their fee as cashback for first 3
            months.
          </Text>
          <NavLink to={{ pathname: "/refer", isRefer: { isRefer: true } }}>
            <FormButton text="Refer a friend" onClick={() => setPage(true)} />
          </NavLink>
        </Stack>
      )}
    </>
  );
};

export default Refer;
