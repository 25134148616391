import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import styles from "./styles/App.module.css";
import routes from "./utils/routes";
import Header from "./components/common/Header";
import Loading from "./components/elements/Loading";
import PrivateRoute from "utils/PrivateRoute";
import withRouter from "components/hoc/withRouter";
import Sidebar from "components/common/Sidebar";
import { Stack, useColorMode, Show, Hide } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
// import ContactModal from "components/modals/ContactModal";
import Refer from "components/elements/Refer";
import { callApiToServer } from "apis/promiseResolve";
import { API_URL } from "utils/interceptors";
import { setUserDetails,setFirstTime } from "app/slices/auth";
import {
  getAssistData,
  getComparators,
  getIndicators,
} from "app/slices/indicator";
import FooterMenu from "components/common/Footer";
import ContactModal from "components/modals/ContactModal";
import Methodology from "components/learn-more/Methodology";
// const Login = lazy(() => import("components/login/Login"));
// const SignUp = lazy(() => import("components/login/SignUp"));
// const ForgotPassword = lazy(() => import("components/login/ForgotPassword"));
// const ResetPassword = lazy(() => import("components/login/ResetPassword"));
// const OtpVerification = lazy(() => import("components/login/OtpVerification"));
const Terms = lazy(() => import("components/terms/Terms"));
const LiveTradingTerms = lazy(() =>
  import("components/terms/LiveTradingTerms")
);

const Dashboard = lazy(() => import("components/dashboard"));
// const OptionAnalyzer = lazy(() => import("components/option-analyzer"));
const NewStrategy = lazy(() => import("components/strategy/new-strategy"));
const Notification = lazy(() => import("components/notification"));
const OrderBook = lazy(() => import("components/orderbook"));
const AdvisorsBook = lazy(() => import("components/advisorsbook"));
const OpenOrderBook =lazy(()=>import("components/openOrder/OpenOrderBook"))
const Broker = lazy(() => import("components/broker"));
const MyStrategy = lazy(() => import("components/myStrategy"));
const MyScanner = lazy(() => import("components/scanner/my-scanner"));
const NewScanner = lazy(() => import("components/scanner/new-scanner"));
// const Connectors = lazy(() => import("components/connectors"));
const DailyUsage = lazy(() => import("components/daily-usage"));
const ChartingTool = lazy(() => import("components/charting-tool"));
const Profile = lazy(() => import("components/profile"));
const Returns = lazy(() => import("components/returns"));
const Optimize = lazy(() => import("components/optimize"));
const Virtual = lazy(() => import("components/virtual"));
const Live = lazy(() => import("components/live"));
const DetailStrategy = lazy(() => import("components/detailStrategy"));
const Pricing = lazy(() => import("components/pricing"));

const App = (props) => {
  const { colorMode } = useColorMode();
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [contact, isContact] = useState(false);

  const allRoutes = useRoutes([
    // {
    //   path: routes.login,
    //   element: <Login />,
    // },
    // {
    //   path: routes.signup,
    //   element: <SignUp />,
    // },
    // {
    //   path: routes.signupId,
    //   element: <SignUp />,
    // },
    // {
    //   path: routes.forgotPassword,
    //   element: <ForgotPassword />,
    // },
    // {
    //   path: routes.activationLink,
    //   element: <ForgotPassword isActivation={true} />,
    // },
    // {
    //   path: routes.resetPassword,
    //   element: <ResetPassword />,
    // },
    // {
    //   path: routes.otpVerification,
    //   element: <OtpVerification />,
    // },
    {
      path: routes.terms,
      element: <Terms />,
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: routes.home,
          element: <Dashboard />,
        },
        {
          path: routes.stratezy,
          element: <MyStrategy isAstra={false} />,
        },
        {
          path: routes.optimize,
          element: <Optimize />,
        },
        {
          path: routes.virtual,
          element: <Virtual isVirtual={true} />,
        },
        {
          path: routes.live,
          element: <Virtual isVirtual={false} />,
        },
        {
          path: routes.broker,
          element: <Broker />,
        },
        {
          path: routes.returns,
          element: <Returns />,
        },
        {
          path: routes.astra,
          element: <MyStrategy isAstra={true} />,
        },
        {
          path: routes.scanners,
          element: <MyScanner />,
        },
        {
          path: routes.createScanner,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <NewScanner />,
            },
            {
              path: routes.editScanner,
              element: <NewScanner isEdit={true} />,
            },
            {
              path: routes.copyScanner,
              element: <NewScanner isCopy={true} />,
            },
          ],
        },
        {
          path: routes.createStratezy,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <NewStrategy />,
            },
            {
              path: routes.editStrategy,
              element: <NewStrategy isEdit={true} />,
            },
            {
              path: routes.copyStrategy,
              element: <NewStrategy isCopy={true} />,
            },
          ],
        },
        // {
        //   path: routes.connectors,
        //   element: <Connectors />,
        // },
        // {
        //   path: routes.optionStrategy,
        //   element: <OptionAnalyzer />,
        // },
        {
          path: routes.orders,
          element: <OrderBook />,
        },
        {
          path: routes.advisors,
          element: <AdvisorsBook />,
        },
        {
          path: routes.openorders,
          element: <OpenOrderBook />,
        },
        {
          path: routes.backtest,
          element: <DetailStrategy />,
        },
        {
          path: routes.optimised,
          element: <DetailStrategy />,
        },
        {
          path: routes.notifications,
          element: <Notification />,
        },
        {
          path: routes.profile,
          element: <Profile />,
        },
        {
          path: routes.chartingTool,
          element: <ChartingTool />,
        },
        {
          path: routes.virtualInsight,
          element: <DetailStrategy />,
        },
        {
          path: routes.liveInsight,
          element: <DetailStrategy />,
        },
        {
          path: routes.dailyUsage,
          element: <DailyUsage />,
        },
        {
          path: routes.pricing,
          element: <Pricing />,
        },
        {
          path: routes.join,
          element: <h1>Join keev</h1>,
        },
        {
          path: routes.refer,
          element: <Refer isRefer={true} />,
        },
        {
          path:routes.methodology,
          element:<Methodology/>
        },
        {
          path: routes.liveTerms,
          element: <LiveTradingTerms />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={routes.login} />,
    },
  ]);

  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  useEffect(() => {
    let mounted = true;
    async function getParameter(props) {
      const url = new URLSearchParams(window.location.search);
      const oldcid = url.get("RequestToken");
      if (oldcid !== null) {
        dispatch(setFirstTime());
        try {
          deleteAllCookies();
          const cid = oldcid.replaceAll(" ", "+");
          callApiToServer(`${API_URL}api/user/v2/login_by_cidsid/`, "POST", {
            client_id: "",
            session_id: oldcid,
            broker: "5paisa",
            source:"CWA"
          })
            .then((res) => {
              localStorage.setItem("accessToken", res.data.token);
              dispatch(setUserDetails(res.data));
              dispatch(getIndicators());
              dispatch(getComparators());
              dispatch(getAssistData());
            })
            .catch((e) => {
              localStorage.clear();
               window.open("https://infinity.compoundingwealth.co.in/", "_self");
              console.log("error", e);
            });
          return true;
        } catch (err) {
          return false;
        }
      } else {
        return true;
      }
    }
    getParameter(props);
    return () => (mounted = false); // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className={styles.App}>
        {token && (
          <div className={styles.header}>
            <Header />
          </div>
        )}
        {token && (
          <Show above="lg">
            <Sidebar />
          </Show>
        )}
        <Stack
          className={styles.main}
          style={{
            background:
              colorMode === "dark"
                ? "#1A1A1A"
                : [
                    routes.login,
                    routes.signup,
                    routes.forgotPassword,
                    routes.activationLink,
                    routes.otpVerification,
                  ].includes(props.router.location.pathname) ||
                  props.router.location.pathname.includes(
                    (routes.resetPassword.split(":")[0] + "/").split("//")[0]
                  ) ||
                  props.router.location.pathname.includes(
                    (routes.signupId.split(":")[0] + "/").split("//")[0]
                  )
                ? "#ebfafc"
                : null,
          }}
          p={
            [
              routes.login,
              routes.signup,
              routes.forgotPassword,
              routes.activationLink,
              routes.otpVerification,
            ].includes(props.router.location.pathname) ||
            props.router.location.pathname.includes(
              (routes.resetPassword.split(":")[0] + "/").split("//")[0]
            ) ||
            props.router.location.pathname.includes(
              (routes.signupId.split(":")[0] + "/").split("//")[0]
            )
              ? 0
              : 4
          }
          gridColumn={
            [
              routes.login,
              routes.signup,
              routes.forgotPassword,
              routes.activationLink,
              routes.otpVerification,
            ].includes(props.router.location.pathname) ||
            props.router.location.pathname.includes(
              (routes.resetPassword.split(":")[0] + "/").split("//")[0]
            ) ||
            props.router.location.pathname.includes(
              (routes.signupId.split(":")[0] + "/").split("//")[0]
            )
              ? "1 / 4"
              : null
          }
          gridRow={
            [
              routes.login,
              routes.signup,
              routes.forgotPassword,
              routes.activationLink,
              routes.otpVerification,
            ].includes(props.router.location.pathname) ||
            props.router.location.pathname.includes(
              (routes.resetPassword.split(":")[0] + "/").split("//")[0]
            ) ||
            props.router.location.pathname.includes(
              (routes.signupId.split(":")[0] + "/").split("//")[0]
            )
              ? "1 / 4"
              : null
          }
        >
          <Suspense
            fallback={
              // <div style={{ height: "100vh" }}>
              <Loading />
              // </div>
            }
          >
            {allRoutes}
          </Suspense>
        </Stack>
        <Hide above="lg">
          <FooterMenu />
        </Hide>
      </div>
      {/* {isContact && <ContactModal isOpen={isContact} />} */}
    </>
  );
};

export default withRouter(App);