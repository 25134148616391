import { Icon } from "@chakra-ui/react";
import React from "react";

const StrategiesOptimizeDisabledIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      role="img"
      {...rest}
    >
      <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M20 8.32624C20 9.97193 16.4183 11.306 12 11.306C7.58172 11.306 4 9.97193 4 8.32624M20 8.32624C20 12.0778 17.9318 15.2897 15 16.6158V19.0176C15 19.7701 14.572 20.458 13.8944 20.7945L11.8944 21.7877C10.5646 22.4481 9 21.4877 9 20.0109V16.6158C6.06817 15.2897 4 12.0778 4 8.32624M20 8.32624C20 7.38522 18.8289 6.54609 17 6M4 8.32624C4 7.38522 5.17107 6.54609 7 6M14 5V7M10 7V8M10 2V4"
      fill={color}/>

    </Icon>
  );
};

export default StrategiesOptimizeDisabledIcon;
