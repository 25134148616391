import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import TradeOptionTable from "components/elements/TradeOptionTable";
import FormInput from "controls/FormInput";
import FormNumberInput from "controls/FormNumberInput";
import FormSelect from "controls/FormSelect";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { perabs } from "utils/common";

const TradeOption = (props) => {
  const { onAddAtm, buyData, sellData, position } = props;
  const methods = useFormContext();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [ots, setots] = useState("false");
  const [tlsl, settlsl] = useState(true);
  useEffect(() => {
    methods.setValue("trailing_sl_type", "%");
    methods.setValue("trailing_sl", true);
    methods.setValue("on_option_tp_sl", false);
  }, []);

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      <GridItem>
        {/* <HStack>
          <Box flex={2}>
            <Text
              fontSize="12px"
              fontWeight="500"
              padding="0px 5px 0px 5px"
              borderRadius="5px 1px 5px 1px"
              backgroundColor="#0DA000"
              color="#fff"
              marginRight="12px"
              textAlign={"center"}
              width="fit-content"
            >
              Buy
            </Text>
          </Box>
          <Stack flex={1.5}> */}
        <Text>
          Expiry
          <Tooltip
            label={
              "In case of Indexes it will take weekly expiry and for Stocks it will take monthly expiry"
            }
            //   isOpen
            hasArrow
            bg={"#fff"}
            fontSize={16}
            color={"#00a3be"}
            border={"1.5px solid #00a3be"}
            borderRadius={"5px"}
            arrowShadowColor="#00a3be"
            zIndex={2}
          >
            <InfoIcon
              cursor="pointer"
              marginLeft={"7px"}
              marginBottom={"0.5"}
              color={"#888888"}
            />
          </Tooltip>
        </Text>
        <FormSelect
          name="expiry"
          control={methods.control}
          error={methods.formState.errors}
          options={[
            {
              label: "Current",
              value: "CURRENT",
            },
            {
              label: "Next",
              value: "NEXT",
            },
          ]}
          label=""
          rules={{ required: "This field is required" }}
          defaultValue={
            methods.getValues("expiry") || {
              label: "Current",
              value: "CURRENT",
            }
          }
          onChangeNew={(e) => methods.setValue("expiry", e)}
        />
        {/* </Stack> */}
        {/* <Stack flex={1.5}> */}

        {/* </Stack>
          <Box flex={2} textAlign="right">
            <Text
              fontSize="12px"
              fontWeight="500"
              padding="0px 5px 0px 5px"
              borderRadius="5px 1px 5px 1px"
              backgroundColor="#E90303"
              color="#fff"
              // marginRight="12px"
              textAlign={"center"}
              width="fit-content"
              ml={"auto"}
            >
              Sell
            </Text>
          </Box>
        </HStack> */}
      </GridItem>
      <GridItem>
        <Text>Price will depend on</Text>
        <FormSelect
          name="spotfut"
          control={methods.control}
          error={methods.formState.errors}
          rules={{ required: "This field is required" }}
          options={[
            {
              label: "SPOT",
              value: "SPOT",
            },
            {
              label: "FUT",
              value: "FUT",
            },
          ]}
          label=""
          defaultValue={
            methods.getValues("spotfut") || {
              label: "FUT",
              value: "FUT",
            }
          }
          onChangeNew={(e) => methods.setValue("spotfut", e)}
        />
      </GridItem>
      {/* <GridItem>
        <HStack alignItems={"center"} justifyContent="center" height="100%">
          <Text
            color="brand.900"
            cursor={"pointer"}
            onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          >
            {isAdvancedOpen ? "Hide" : "View"} advanced option
          </Text>
        </HStack>
      </GridItem> */}
      {/* {isAdvancedOpen && (
        <> */}
      <GridItem>
        <Text>Do you want to rollover?</Text>
        <FormSelect
          name="rollover"
          control={methods.control}
          error={methods.formState.errors}
          rules={{ required: "This field is required" }}
          options={[
            {
              label: "True",
              value: true,
            },
            {
              label: "False",
              value: false,
            },
          ]}
          defaultValue={
            methods.getValues("rollover") || {
              label: "False",
              value: false,
            }
          }
          label=""
        />
      </GridItem>
      <GridItem>
        <Text>Rollover days before expiry</Text>
        <FormSelect
          name="days"
          control={methods.control}
          error={methods.formState.errors}
          rules={{ required: "This field is required" }}
          options={[
            {
              label: 0,
              value: 0,
            },
            {
              label: 1,
              value: 1,
            },
            {
              label: 2,
              value: 2,
            },
            {
              label: 3,
              value: 3,
            },
            {
              label: 4,
              value: 4,
            },
          ]}
          label=""
          defaultValue={methods.getValues("days") || { label: 0, value: 0 }}
          onChangeNew={(e) => methods.setValue("days", e)}
        />
      </GridItem>
      <GridItem>
        <Text>Time you want to rollover</Text>
        <FormInput
          name="time"
          type="time"
          control={methods.control}
          error={methods.formState.errors}
          rules={{ required: "This field is required" }}
          defaultValue={methods.getValues("time") || "15:00:00"}
          label=""
        />
      </GridItem>
      <GridItem colSpan={3} display={"flex"} gap={5} marginBottom={5} marginTop={5}>
        <Text fontWeight={"bold !important"}>
          Do you want to have takeprofit & stoploss options premium ?
        </Text>

        <RadioGroup
          name="on_option_tp_sl"
          onChange={(e) => {
            methods.setValue("on_option_tp_sl", e);
            setots(e);
          }}
          defaultValue={"false"}
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="red" value="false">
              No
            </Radio>
            <Radio colorScheme="green" value="true">
              Yes
            </Radio>
          </Stack>
        </RadioGroup>
      </GridItem>

      {ots && methods.getValues("on_option_tp_sl") === "true" ? (
        <>
          <GridItem colSpan={3} display={"flex"} gap={5} marginBottom={5} >
            <Text fontWeight={"bold !important"}>
              Should your take profit & stop loss be in
            </Text>

            <RadioGroup
              name="trailing_sl_type"
              onChange={(e) => methods.setValue("trailing_sl_type", e)}
              defaultValue={"%"}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="red" value="abs">
                  Points
                </Radio>
                <Radio colorScheme="green" value="%">
                  Percentage(%)
                </Radio>
              </Stack>
            </RadioGroup>
          </GridItem>

          <GridItem colSpan={"auto"}>
            <Text>Stop loss on P&L</Text>
            {/* <div style={{ display: "flex" }}>
          <FormSelect
            name="option_sl_type"
            variant="outline"
            label=""
            control={methods.control}
            error={methods.formState.errors}
            options={[{ key: "%", value: "%", label: "%" }]}
            defaultValue={perabs[0]}
            isSearchable={false}
            width={"80%"}
          /> */}
            <FormNumberInput
              name="stop_loss"
              control={methods.control}
              error={methods.formState.errors}
              rules={{
                required: "This field is required.",
                maxLength: {
                  value: 2000,
                  message: "not grater then 2000.",
                },
                minLength: {
                  value: 0,
                  message: "negative value not accept.",
                },
              }}
              defaultValue={
                methods.getValues("stop_loss")
                  ? methods.getValues("stop_loss")
                  : 2
              }
              onChangeNew={(e) => {
                methods.setValue("stop_loss", e.target.value);
              }}
              //    right={"17%"}
            />
            {/* </div> */}
          </GridItem>
          <GridItem colSpan={"auto"}>
            <Text>Take profit on P&L</Text>
            {/* <div style={{ display: "flex" }}>
          <FormSelect
            name="option_tp_type"
            variant="outline"
            label=""
            control={methods.control}
            error={methods.formState.errors}
            options={[{ key: "%", value: "%", label: "%" }]}
            defaultValue={perabs[0]}
            isSearchable={false}
            width={"80%"}
          /> */}
            <FormNumberInput
              name="take_profit"
              control={methods.control}
              error={methods.formState.errors}
              rules={{
                required: "This field is required.",
                maxLength: {
                  value: 2000,
                  message: "not grater then 2000.",
                },
                minLength: {
                  value: 0,
                  message: "negative value not accept.",
                },
              }}
              defaultValue={
                methods.getValues("take_profit")
                  ? methods.getValues("take_profit")
                  : 5
              }
              onChangeNew={(e) => {
                methods.setValue("take_profit", e.target.value);
              }}
              // right={"17%"}
            />
            {/* </div> */}
          </GridItem>

          <GridItem colSpan={3} display={"flex"} gap={5} marginBottom={5} marginTop={5}>
            <Text fontWeight={"bold !important"}>
              Do you want to active trailing stoploss ?
              <Tooltip
                label={`In case of 'NO' it will consider TP & SL of backtested value`}
                //   isOpen
                hasArrow
                bg={"#fff"}
                fontSize={16}
                color={"#00a3be"}
                border={"1.5px solid #00a3be"}
                borderRadius={"5px"}
                arrowShadowColor="#00a3be"
                zIndex={2}
              >
                <InfoIcon
                  cursor="pointer"
                  marginLeft={"7px"}
                  marginBottom={"0.5"}
                  color={"#888888"}
                />
              </Tooltip>
            </Text>

            <RadioGroup
              name="trailing_sl"
              onChange={(e) => {
                methods.setValue("trailing_sl", e);
                settlsl(e);
              }}
              defaultValue={"true"}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="red" value="false">
                  No
                </Radio>
                <Radio colorScheme="green" value="true">
                  Yes
                </Radio>
              </Stack>
            </RadioGroup>
          </GridItem>
          {methods.getValues("trailing_sl") && tlsl !== "false" ? (
            <>
              <GridItem colSpan={2}>
                <Text>At what level should we update trailing stoploss</Text>
                <div style={{ display: "flex" }}>
                  <FormNumberInput
                    name="trailing_sl_value"
                    control={methods.control}
                    error={methods.formState.errors}
                    rules={{
                      required: "This field is required.",
                      maxLength: {
                        value: 2000,
                        message: "not grater then 2000.",
                      },
                      minLength: {
                        value: 0,
                        message: "negative value not accept.",
                      },
                    }}
                    width={"49%"}
                    defaultValue={
                      methods.getValues("trailing_sl_value")
                        ? methods.getValues("trailing_sl_value")
                        : 1
                    }
                    onChangeNew={(e) => {
                      methods.setValue("trailing_sl_value", e.target.value);
                    }}
                  />
                </div>
              </GridItem>
            </>
          ) : null}
        </>
      ) : null}

      <GridItem colSpan={3}>
        <Grid
          templateColumns={`repeat(${position === "both" ? 2 : 1}, 1fr)`}
          gap={4}
        >
          {(position === "long" || position === "both") && (
            <GridItem>
              <GridItem>
                <Text>Trade to be executed when </Text>
              </GridItem>
              <GridItem display={"flex"} alignItems="center">
                <Box
                  fontSize="12px"
                  fontWeight="500"
                  padding="0px 4px"
                  borderRadius="5px 1px 5px 1px"
                  backgroundColor="#0DA000"
                  color="#fff"
                  marginInline="8px"
                  textAlign={"center"}
                >
                  Buy
                </Box>{" "}
                condition triggers
              </GridItem>
              <TradeOptionTable
                data={buyData}
                transactionType="buy"
                onAddAtm={onAddAtm}
              />
            </GridItem>
          )}
          {(position === "short" || position === "both") && (
            <GridItem>
              <GridItem>
                <Text>Trade to be executed when </Text>
              </GridItem>
              <GridItem display={"flex"} alignItems="center">
                <Box
                  fontSize="12px"
                  fontWeight="500"
                  padding="0px 5px 0px 5px"
                  borderRadius="5px 1px 5px 1px"
                  backgroundColor="#E90303"
                  color="#fff"
                  marginInline="8px"
                  textAlign={"center"}
                >
                  Sell
                </Box>{" "}
                condition triggers
              </GridItem>

              <TradeOptionTable
                data={sellData}
                transactionType={"sell"}
                onAddAtm={onAddAtm}
              />
            </GridItem>
          )}
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default TradeOption;
