import withRouter from "components/hoc/withRouter";
import React, { useState } from "react";
import {
  Stack,
  Image,
  Text,
  Box,
  HStack,
  Grid,
  GridItem,
  Flex,
  Tooltip,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Avatar,
} from "@chakra-ui/react";
import FormButton from "controls/Button";
import Ellipse from "assets/Ellipse.svg";
import BlogPost from "assets/blog.svg";
import Volatality from "assets/volatality_indicator.svg";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Subscribe from "components/modals/Subscribe";
import MethodologyIcon from "assets/methodology_icon.svg";
import DefiningUniverse from "assets/universe.svg";
import Research from "assets/research.svg";
import StockScreening from "assets/stockScreening.svg";
import AssetAllocation from "assets/weighting.svg";
import HistoricalBacktesting from "assets/backTesting.svg";
import Content from "components/common/Content";
import jr from 'assets/junior.jpg'
import mini from 'assets/mini.jpg'
import minijr from 'assets/infinityJr.jpg'
const Methodology = (props) => {
  const datas = props?.router?.location?.state.data;
  function handleLinkClick1() {
    window.open("https://cwa.smallcase.com/");
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const data = {
    labels: ["Equity", "Commodities", "Currency", "Equity Derivatives"],
    datasets: [
      {
        data: [0.0, 0.0, 0.0, 100.0],
        backgroundColor: ["#A1B9E3", "#D4E4FF", "#E8B6FF", "#FFEBB8"],
        hoverBackgroundColor: ["#A1B9E3", "#D4E4FF", "#E8B6FF", "#FFEBB8"],
      },
    ],
  };

  const options = {
    cutout: 80,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Stack p={4} bg="white" width="100%" maxW="100%">
        <Stack display={"flex"} flexDirection={"row"} width="100%" maxW="100%">
          <Stack maxW="10%">
             <Image src={datas?.packageName === "Infinity Junior" ? jr : datas?.packageName === "Infinity Option" ||
           datas?.packageName ==="Infinity Intraday Option"  ? minijr : mini} alt="No Position" h="80px" w={"130px"} borderRadius={"11%"}/>
          </Stack>
          <Stack maxW="40%" width="40%">
            <Text
              style={{
                color: "#01A2BB",
                fontSize: "1.225rem",
                marginLeft: "1rem",
              }}
            >
              {datas?.packageName}
            </Text>
            <Stack display={"flex"} flexDirection={"row"}>
              <Text
                style={{
                  color: "#888888",
                  fontSize: "1rem",
                  marginLeft: "1rem",
                }}
              >
                Managed by
                <span
                  style={{
                    color: "#141414",
                    fontSize: "1rem",
                    marginLeft: "4px",
                  }}
                >
                  Compounding Wealth Advisors
                </span>
              </Text>
            </Stack>
          </Stack>

          <Stack
            justifyContent="flex-end"
            alignItems="center"
            maxW="30%"
            width="30%"
          ></Stack>
          <Stack
            justifyContent="flex-end"
            alignItems="end"
            maxW="30%"
            width="30%"
          >
            <Stack>
              <Text
                style={{
                  color: "#141414",
                  fontSize: "1.25rem",
                }}
              >
                ₹ {datas.packagePrice}
                <span
                  style={{
                    color: "#888888",
                    fontSize: ".75rem",
                    marginLeft: "4px",
                  }}
                >
                  Min margin
                </span>
              </Text>
            </Stack>
            <Stack>
            
              <Subscribe data={datas} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack p={3} bg="white" marginTop={"1rem"} width="100%" maxW="100%">
        <Content name={datas?.packageName} />
      </Stack>

      <Modal isOpen={isOpen} onClose={toggleModal} size={"xl"}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader border={"2px solid #EDF1FF"}>
            <Stack direction="row">
              <Avatar src={MethodologyIcon} borderRadius="8px" />
              <Stack spacing={0}>
                <Text fontSize={18} color={"#141414"} fontWeight={500}>
                  Methodology
                </Text>
                <Text fontSize={14} color={"#888888"} fontWeight={500}>
                  Please fill the information in order to add the broker
                </Text>
              </Stack>
            </Stack>
          </ModalHeader>
          <ModalCloseButton mt="5px" border="1.5px solid #bebebe" />

          <ModalBody>
            <Grid templateColumns="repeat(1, 1fr)" mt="10px">
              <GridItem>
                <Box display="flex" flexDirection="row">
                  <Image
                    src={DefiningUniverse}
                    style={{ marginBottom: "auto" }}
                  />

                  <Stack spacing={0} marginLeft={"1rem"}>
                    <Text fontSize={18} color={"#141414"} fontWeight={500}>
                      Defining the universe
                    </Text>
                    <Text fontSize={14} color={"#888888"} fontWeight={500}>
                      All publicly traded ETFs on the National Stock Exchange of
                      India are included in the universe
                    </Text>
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row" marginTop={"1rem"}>
                  <Image src={Research} style={{ marginBottom: "auto" }} />

                  <Stack spacing={0} marginLeft={"1rem"}>
                    <Text fontSize={18} color={"#141414"} fontWeight={500}>
                      Research
                    </Text>
                    <Text fontSize={14} color={"#888888"} fontWeight={500}>
                      CWA studies research papers and documents various asset
                      allocation algorithms & strategies. Algorithms are further
                      developed & adapted to suit the Indian market conditions
                    </Text>
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row" marginTop={"1rem"}>
                  <Image
                    src={StockScreening}
                    style={{ marginBottom: "auto" }}
                  />

                  <Stack spacing={0} marginLeft={"1rem"}>
                    <Text fontSize={18} color={"#141414"} fontWeight={500}>
                      ETF Screening
                    </Text>
                    <Text fontSize={14} color={"#888888"} fontWeight={500}>
                      Proprietary liquidity filters are applied to select ETFs
                      that are liquid
                    </Text>
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row" marginTop={"1rem"}>
                  <Image
                    src={AssetAllocation}
                    style={{ marginBottom: "auto" }}
                  />

                  <Stack spacing={0} marginLeft={"1rem"}>
                    <Text fontSize={18} color={"#141414"} fontWeight={500}>
                      Asset Allocation
                    </Text>
                    <Text fontSize={14} color={"#888888"} fontWeight={500}>
                      The final screened ETFs are then weighted according to the
                      selected asset allocation algorithm
                    </Text>
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row" marginTop={"1rem"}>
                  <Image
                    src={HistoricalBacktesting}
                    style={{ marginBottom: "auto" }}
                  />

                  <Stack spacing={0} marginLeft={"1rem"}>
                    <Text fontSize={18} color={"#141414"} fontWeight={500}>
                      Historical back-testing
                    </Text>
                    <Text fontSize={14} color={"#888888"} fontWeight={500}>
                      All ETF-based asset allocation strategies are checked for
                      historical outperformance over a minimum of 10 year
                      period. Only consistently outperforming asset allocation
                      models are selected
                    </Text>
                  </Stack>
                </Box>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter></ModalFooter>
          <Box
            height="79px"
            background="#E4FDFF"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              fontSize="16px"
              color="#ffff"
              background="#01A2BB"
              marginRight="20px"
              borderRadius="0"
              _hover={"#01a2bb"}
              onClick={closeModal}
            >
              Okay
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default withRouter(Methodology);
