import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import Content from "components/common/Content";
import React, { forwardRef, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

const customComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      {props.data.icon} {children}
    </chakraComponents.Option>
  ),
};
const FormSelect = (props, newRef) => {
  const {
    name,
    label,
    options,
    control,
    defaultValue,
    error,
    rules,
    isMulti,
    onInputChange,
    onChangeNew,
    width,
    disabled,
    unCheck,
    onOpen,
    flex,
    dropIndicator,
    dropIndicatorColor,
    onFocusNew,
    menuHeight,
    myLabel,
    newValue,
    backgroundDisabled,
    height,
    variant,
    ...rest
  } = props;
  const methods = useFormContext();
  useEffect(() => {
    if (defaultValue) {
      methods.setValue(name, defaultValue);
    }
  }, [defaultValue, methods, name]);
  return (
    <FormControl
      isInvalid={
        error ? (error[name]?.message ? error[name] : error?.message) : false
      }
      width={width}
      // height = {height}
    >
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, name: fieldName, ref },
          fieldState: { error },
        }) => {
          return (
            <Select
              {...rest}
              name={name}
              defaultValue={defaultValue}
              isMulti={isMulti}
              ref={newRef ? newRef : ref}
              onChange={
                onChangeNew
                  ? onChangeNew
                  : (e) => {
                      methods.setValue(name, e);
                      methods.clearErrors(name);
                    }
              }
              variant={variant !== undefined ? variant : "outline"}
              onBlur={onBlur}
              onFocus={onFocusNew}
              value={newValue ? newValue : value}
              options={options}
              placeholder={label}
              selectedOptionStyle={unCheck ? unCheck : "check"}
              onInputChange={onInputChange ? onInputChange : null}
              isDisabled={disabled}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPlacement="auto"
              maxMenuHeight={300}
              inputValue={myLabel}
              components={customComponents}
              chakraStyles={{
                control: (provided, state) => ({
                  ...provided,
                  width: width,
                  // height : height,
                  borderColor: "#bebebe",
                  borderRadius: "3px",
                  _hover: {
                    borderColor: "#bebebe",
                  },
                  _disabled: {
                    background: "#EBFAFC",
                    cursor: "not-allowed",
                    opacity: "0.5",
                  },
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: "transparent",
                  px: 2,
                  cursor: "inherit",
                  display: dropIndicator || "flex",
                  color: dropIndicatorColor,
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                menuList: (provided) => ({
                  ...provided,
                  minWidth: "auto",
                  maxHeight: menuHeight || 300,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 999999999999999999,
                }),
                inputContainer: (provided) => ({
                  width: "100%",
                  display:'contents',
                }),
              }}
              // menuIsOpen={true}
              onMenuOpen={onOpen}
              // id={id}
            />
          );
        }}
      />
      {error && (
        <FormErrorMessage>
          {error ? (error[name] ? error[name]?.message : error?.message) : ""}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default forwardRef(FormSelect);
