import { Icon } from "@chakra-ui/react";
import React from "react";

const NoNotificationIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7772 18H6.22281C4.39443 18 3.35076 16.1609 4.44779 14.8721C4.93344 14.3016 5.23274 13.6249 5.31328 12.9153L5.76046 8.97519C6.0016 6.85063 7.58766 5.10719 9.73005 4.37366V4.26995C9.73005 3.01629 10.7463 2 12 2C13.2537 2 14.2699 3.01629 14.2699 4.26995V4.37366C16.4123 5.10719 17.9984 6.85063 18.2395 8.97519L18.6867 12.9153C18.7673 13.6249 19.0666 14.3016 19.5522 14.8721C20.6492 16.1609 19.6056 18 17.7772 18ZM14.9721 20.0715C14.5147 21.1992 13.3565 22 12 22C10.6435 22 9.48526 21.1992 9.02789 20.0715C9.00883 20.0245 9 19.974 9 19.9233C9 19.6895 9.18951 19.5 9.42329 19.5H14.5767C14.8105 19.5 15 19.6895 15 19.9233C15 19.974 14.9912 20.0245 14.9721 20.0715Z"
        fill="#888888"
      />
    </Icon>
  );
};

export default NoNotificationIcon;
