import instance from "utils/interceptors";
import { callApiToServer, callGetApiToServer } from "./promiseResolve";

export async function getLiveDetails(strategyId, scripId) {
  try {
    let responseData = await callGetApiToServer(
      `api/live-trade/v2/values/${strategyId}/${scripId}/`,
      "GET"
    );
    return responseData?.data;
  } catch (error) {
    throw error;
  }
}

export async function runLiveTrade(strategyId, scripId, data) {
  try {
    let responseData = await instance.post(
      `api/live-trade/v2/run/${strategyId}/${scripId}/`,
      data
    );
    return responseData?.data;
  } catch (error) {
    throw error;
  }
}

export async function stopLiveTrade(scripId) {
  try {
    let responseData = await instance.get(`api/live-trade/v2/stop/${scripId}/`);
    return responseData?.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteLiveTrade(scripId) {
  try {
    let responseData = await instance.delete(
      `api/live-trade/v2/delete/${scripId}/`
    );
    return responseData?.data;
  } catch (error) {
    throw error;
  }
}

export async function callLiveOrder(order_id, bodyData) {
  try {
    let body = bodyData;
    let responseData = await callApiToServer(
      `api/live-trade/v2/trade/${order_id}/`,
      "POST",
      body
    );

    return responseData;
  } catch (error) {
    throw error;
  }
}

export const getRunningLive = async () => {
  try {
    const res = await instance.get(`api/live-trade/v2/view_running/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getStoppedLive = async () => {
  try {
    const res = await instance.get(`api/live-trade/v2/view_stopped/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getLiveOrderBook = async () => {
  try {
    const res = await instance.get(`api/live-trade/v2/order_book/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getLiveOpenPosition = async () => {
  try {
    const res = await instance.get(`api/live-trade/v2/open_position/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getLiveTableData = async (id) => {
  try {
    const res = await instance.get(`api/live-trade/v2/view/${id}/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const runAstraLiveTrade = async (data) => {
  const res = await instance.post(
    `api/strategy/v2/run_livetrade_public_strategy/`,
    data
  );
  return res.data;
};
