import React from "react";
import { FormProvider } from "react-hook-form";

const CustomForm = (props) => {
  const { methods, onSubmit } = props;
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} {...props}>
        {props.children}
      </form>
    </FormProvider>
  );
};

export default CustomForm;
