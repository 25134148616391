import instance from "utils/interceptors";

export const userBasket = async (limit) => {
  try {
    const res = await instance.get(
      `/api/scrip/basket/${limit ? `?limit=${limit}` : ""}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const publicBasket = async (limit) => {
  try {
    const res = await instance.get(
      `/api/scrip/publicbasket/${limit ? `?limit=${limit}` : ""}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createBasket = async (data) => {
  try {
    const res = await instance.post("/api/scrip/basket/", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateDeleteBasket = async (method, id, data) => {
  try {
    const res = await instance[method](`/api/scrip/basket/${id}/`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchUserBasket = async (limit, search) => {
  try {
    const res = await instance.get(
      `/api/scrip/basket/?limit=${limit}&search=${search}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchPublicBasket = async (limit, search) => {
  try {
    const res = await instance.get(
      `/api/scrip/publicbasket/?limit=${limit}&search=${search}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
