import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const FormInput = (props) => {
  const {
    name,
    label,
    control,
    defaultValue,
    newValue,
    error,
    variant,
    rules,
    leftIcon,
    rightIcon,
    size,
    onChangeNew,
    disabled,
    width,
    ...rest
  } = props;
  const methods = useFormContext();
  return (
    <FormControl
      isInvalid={error && error[name] ? error[name]?.message : error?.message}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, onBlur, name: fieldName, value, ref },
        }) => (
          <InputGroup>
            <Input
              {...rest}
              onChange={
                onChangeNew
                  ? onChangeNew
                  : (e) => {
                      methods.setValue(name, e.target.value);
                      methods.clearErrors(name);
                    }
              }
              ref={ref}
              value={newValue ? newValue : value}
              defaultValue={defaultValue}
              errorBorderColor={error && error[name]?.message && "red.500"}
              size={size || "md"}
              placeholder={label}
              width = {width}
              variant={variant || "outline"}
              borderRadius={"3px"}
              borderColor={
                error && error[name]?.message ? "red.500" : "#bebebe"
              }
              disabled={disabled}
            />
            {leftIcon && <InputLeftElement children={leftIcon} />}
            {rightIcon && <InputRightElement children={rightIcon} />}
          </InputGroup>
        )}
      />
      {error && (
        <FormErrorMessage>
          {error && error[name] ? error[name]?.message : error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormInput;
