import {
  Box,
  CardBody,
  Divider,
  Grid,
  GridItem,
  Image,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Card,
  Heading,
  Button,
  CardFooter,
  CardHeader,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NotSub from "assets/Not subscription.gif";
import animi from "assets/happy.png";
import { checkSubscriptionDetails } from "apis/profile";
import { useSelector } from "react-redux";

const Investment = (props) => {
  const [active, setactive] = useState(false);
  const [userData, setuserData] = useState();
  const {
    token
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if(token !== null){
    checkSubscriptionDetails()
      .then((res) => {
        setuserData(res);
        res?.map((item) => {
          if (item?.is_subscribed) {
            setactive(true);
          }
        });
      })
      .catch((err) => {
        console.log("err", err);
      });}
  }, [token]);
  return (
    <>
      {!active ? (
        <>
          <Card minW="100%" minH={"100%"}>
            <Center mt={7}>
              <Image src={NotSub} alt="No Position"  />
            </Center>
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading
                  size="sm"
                  display={"flex"}
                  justifyContent={"center"}
                  //fontsize={"18px"}
                  fontWeight={"800"}
                  color={"blue.500"}
                >
                  You have not subscribed to any Suites
                </Heading>

                {/* <Text color={"#888888"} textAlign={"center"}>
                  Your subscription has been reached 
                </Text> */}
                <Text color={"#555555"} fontWeight={"800"} textAlign={"center"}>
                  {" "}
                  Kindly choose Suites to continue using our services
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <Card minW="100%" minH={"100%"}>
            <Center mt={7}>
              <Image src={animi} alt="No Position" />
            </Center>
<CardBody>
            {userData.map((item) => (
              <Stack key={item.id}  mt={2}>
                <Text
                  size="sm"
                  fontsize={"16px !important"}
                  fontWeight={"800"}
                  color={"blue.500"}
                 
                >
                Subscribed Strategy: {item?.strategy_name}
                </Text>
                <Text mt={"unset !important"}>price: {item?.price}</Text>
                <Text mt={"unset !important"}>Renew Date: {item?.end_date}</Text>
              </Stack>
            ))}
            </CardBody>
          </Card>

         
        </>
      )}
    </>
  );
};

export default Investment;