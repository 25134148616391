import {
  ButtonGroup,
  NumberInput,
  NumberInputField,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import FormButton from "controls/Button";

const TradeOptionTable = (props) => {
  const { data, onAddAtm, transactionType } = props;

  function prepareData(type, value, right, index, transaction, isType, qty) {
    let payload = {
      [right]: {
        type: type?.toUpperCase(),
        autostrike: value,
        right,
        transaction_type: transaction,
        isType,
        ...(qty ? { qty: qty } : null),
      },
    };
    onAddAtm(payload, transactionType, index, right, isType);
  }
  return (
    <TableContainer height="300px" overflowY={"scroll"}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th p={3}>CALL</Th>
            <Th p={3}>ATM</Th>
            <Th p={3}>PUT</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((ele, index) => {
            return (
              <Tr key={index}>
                <Td p={3}>
                  <ButtonGroup>
                    <FormButton
                      text="B"
                      size="sm"
                      variant={ele["CE"]?.type === "BUY" ? "solid" : "outline"}
                      borderColor={
                        ele["CE"]?.type === "BUY"
                          ? "#0DA000 !important"
                          : "#bebebe"
                      }
                      color={ele["CE"]?.type === "BUY" ? "#fff" : "#bebebe"}
                      onClick={() =>
                        prepareData(
                          "buy",
                          ele?.value,
                          "CE",
                          index,
                          transactionType,
                          ele["CE"]?.type === "BUY" ? "remove" : "add"
                        )
                      }
                      background={
                        ele["CE"]?.type === "BUY" && "#0DA000 !important"
                      }
                    />
                    <FormButton
                      text="S"
                      size="sm"
                      variant={ele["CE"]?.type === "SELL" ? "solid" : "outline"}
                      borderColor={
                        ele["CE"]?.type === "SELL"
                          ? "#E90303 !important"
                          : "#bebebe"
                      }
                      color={ele["CE"]?.type === "SELL" ? "#fff" : "#bebebe"}
                      onClick={() =>
                        prepareData(
                          "sell",
                          ele?.value,
                          "CE",
                          index,
                          transactionType,
                          ele["CE"]?.type === "SELL" ? "remove" : "add"
                        )
                      }
                      background={
                        ele["CE"]?.type === "SELL" && "#E90303 !important"
                      }
                    />
                  </ButtonGroup>
                  {ele["CE"] && (
                    <Stack mt={2}>
                      <Text>Call Lots</Text>
                      <NumberInput width="80px" size={"xs"} mt={2}>
                        <NumberInputField
                          onChange={(e) => {
                            prepareData(
                              ele["CE"].type,
                              ele?.value,
                              "CE",
                              index,
                              transactionType,
                              ele["CE"].isType,
                              e.target.value
                            );
                          }}
                        />
                      </NumberInput>
                    </Stack>
                  )}
                </Td>
                <Td p={3}>{ele.label}</Td>
                <Td p={3}>
                  <ButtonGroup>
                    <FormButton
                      text="B"
                      size="sm"
                      variant={ele["PE"]?.type === "BUY" ? "solid" : "outline"}
                      borderColor={
                        ele["PE"]?.type === "BUY"
                          ? "#0DA000 !important"
                          : "#bebebe"
                      }
                      color={ele["PE"]?.type === "BUY" ? "#fff" : "#bebebe"}
                      onClick={() =>
                        prepareData(
                          "buy",
                          ele?.value,
                          "PE",
                          index,
                          transactionType,
                          ele["PE"]?.type === "BUY" ? "remove" : "add"
                        )
                      }
                      background={
                        ele["PE"]?.type === "BUY" && "#0DA000 !important"
                      }
                    />
                    <FormButton
                      text="S"
                      size="sm"
                      variant={ele["PE"]?.type === "SELL" ? "solid" : "outline"}
                      borderColor={
                        ele["PE"]?.type === "SELL"
                          ? "#E90303 !important"
                          : "#bebebe"
                      }
                      color={ele["PE"]?.type === "SELL" ? "#fff" : "#bebebe"}
                      onClick={() =>
                        prepareData(
                          "sell",
                          ele?.value,
                          "PE",
                          index,
                          transactionType,
                          ele["PE"]?.type === "SELL" ? "remove" : "add"
                        )
                      }
                      background={
                        ele["PE"]?.type === "SELL" && "#E90303 !important"
                      }
                    />
                  </ButtonGroup>
                  {ele["PE"] && (
                    <Stack mt={2}>
                      <Text>Put Lots</Text>
                      <NumberInput width="80px" size={"xs"} mt={2}>
                        <NumberInputField
                          onChange={(e) => {
                            prepareData(
                              ele["PE"].type,
                              ele?.value,
                              "PE",
                              index,
                              transactionType,
                              e.target.value
                            );
                          }}
                        />
                      </NumberInput>
                    </Stack>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TradeOptionTable;
