import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { runAstraLiveTrade } from "apis/callLive";
import { getActiveBroker, getLiveModalQuantityData } from "apis/strategy";
import LiveTradeIcon from "assets/livestradeup.svg";
import NotActiveIcon from "assets/not-active.svg";
import wifiIcon from "assets/wifi.svg";
import ScripComponent from "components/elements/ScripComponent";
import TradeOption from "components/modals/TradeOption";
import FormNumberInput from "controls/FormNumberInput";
import FormSelect from "controls/FormSelect";
import FormSwitch from "controls/FormSwitch";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  atms,
  blockInvalidChar,
  createOwnObject,
  strategyLiveFor,
} from "utils/common";
import CustomForm from "utils/useForm";
import Loading from "react-fullscreen-loading";
import moment from "moment";
const AstraLiveTradeModal = (props) => {
  const {
    setOpened,
    isOpen,
    isLoading,
    setIsLoading,
    symbolValue,
    stratData,
    setStratData,
    buyselllogic,
  } = props;

  const position = stratData?.strategy?.position_type;
  // const [liveModalData, setLiveModalData] = useState([]);
  const [activeBroker, setActiveBroker] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isAuto, setIsAuto] = useState(false);
  const toast = useToast();
  const [buyLength, setbuyLength] = useState(false);
  const [sellLength, setSellLength] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPlaceOption, setIsPlaceOption] = useState(false);
  const [orderType, setOrderType] = useState(false);
  const [tradeOptions, setTradeOptions] = useState({
    buy: atms,
    sell: atms,
  });
  const { isPremium } = useSelector((state) => state.auth);

  const onAddAtm = (data, transaction, index, right, isType) => {
    try {
      if (isType === "remove") {
        data[right] = null;
      }
      let testIndex = tradeOptions[transaction.toLowerCase()].findIndex(
        (ele, ind) => ind === index
      );
      let testData = [...tradeOptions[transaction.toLowerCase()]];
      testData[testIndex] = {
        ...testData[testIndex],
        ...data,
      };
      setTradeOptions({
        ...tradeOptions,
        [transaction.toLowerCase()]: testData,
      });
    } catch (error) {
      console.error("Error in onAddAtm Function", error);
    }
  };
  const sendData = async (item) => {
    runAstraLiveTrade(item)
      .then((res) => {
        toast({
          title: "Live trade start successfully",
          isClosable: true,
          status: "success",
          position: "top-right",
        });
        setLoader(!loader);
        setOpened(!isOpen);
      })
      .catch((err) => {
        toast({
          title:
            err.response.data.errors.message ||
            err.response.data.errors?.buy_condition[0]?.qty ||
            err.response.data.errors?.sell_condition[0]?.qty,
          isClosable: true,
          status: "error",
          position: "top-right",
        });
      });
  };
  useEffect(() => {
    setIsLoading(false);
    getActiveBroker().then((res) => {
      setActiveBroker(res);
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      liveData: symbolValue,
    },
  });
  const { control } = methods;
  const { fields, update } = useFieldArray({
    control,
    name: "liveData",
  });

  const HandleRun = (data) => {
    setLoader(true);
 
    data = data?.liveData.map((item) => ({
      live_period_days: data.Interval.value,
      quantity: item.qtyamt.label === "qty" ? item.quantity : 0,
      initial_amount: item.qtyamt.label === "amt" ? item.initial_amount : 0,
      is_auto: isAuto,
      qtyamt: item.qtyamt.label,
      strategy_id: item.strategy_id,
      scrip_id: item.scrip_id,
      rollover: item?.rollover?.value,
      days: item?.days?.value,
      is_roll_over: item?.rollover?.value ? item?.rollover?.value : false,
      roll_over_days: item?.days?.value ? item?.days?.value : 0,
      time: item?.time,
      roll_over_time: item?.time ? item?.time : null,
      stop_loss: item?.stop_loss ? item?.stop_loss : null,
      option_sl: item?.stop_loss ? item?.stop_loss : 0,
      take_profit: item?.take_profit ? item?.take_profit : null,
      option_tp: item?.take_profit ? item?.take_profit : 0,
      expiry: item?.expiry?.value ? item?.expiry?.value : null,
      spotfut: item?.spotfut?.value,
      autostrike_atm: item?.spotfut?.value ? item?.spotfut?.value : null,
      is_option_order: isPlaceOption,
      option_sl_type: item?.trailing_sl_type ? item?.trailing_sl_type : "%",
      option_tp_type: item?.trailing_sl_type ? item?.trailing_sl_type : "%",
      on_option_tp_sl: item?.on_option_tp_sl ? item?.on_option_tp_sl : "false",
      trailing_sl: item?.trailing_sl ? item?.trailing_sl : 1,
      trailing_sl_value: item?.trailing_sl_value ? item?.trailing_sl_value : 1,
      trailing_sl_type: item?.trailing_sl_type ? item?.trailing_sl_type : "%",
    }));
    
    let buyCondition = tradeOptions.buy.filter(
      (obj) => obj.hasOwnProperty("PE") || obj.hasOwnProperty("CE")
    );
    let sellCondition = tradeOptions.sell.filter(
      (obj) => obj.hasOwnProperty("PE") || obj.hasOwnProperty("CE")
    );
    buyCondition = buyCondition.map((ele) =>
      createOwnObject(
        ele,
        methods.getValues("expiry")?.value,
        methods.getValues("spotfut")?.value
      )
    );
    sellCondition = sellCondition.map((ele) =>
      createOwnObject(
        ele,
        methods.getValues("expiry")?.value,
        methods.getValues("spotfut")?.value
      )
    );
    let sampledata = [];
    try {
      data.forEach((item) => {
        let sample = {
          ...item,
          buy_condition: buyCondition.flat(),
          sell_condition: sellCondition.flat(),
          is_option_order: isPlaceOption,
          order_type: orderType ? "LIMIT" : "MARKET",
        };
        sampledata.push(sample);
      });
      let strategyData = {
        strategy: stratData.strategy,
        backtest:{
          end_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
          start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
          startcash: stratData?.backtest.startcash
        } ,
        live_trade: sampledata,
      };
      setStratData((stratData) => ({
        ...stratData,
        live_trade: sampledata,
      }));
      sendData(strategyData);
    } catch (error) {
      toast({
        title: "somthing wents to wrong",
        isClosable: true,
        status: "error",
        position: "top-right",
      });
      setOpened(!isOpen);
    }
  };
  useEffect(() => {
    if (!isPlaceOption) {
      setTradeOptions({ buy: atms, sell: atms });
    }
  }, [isPlaceOption]);


  useEffect(() => {
    let arr = [];
    symbolValue?.map((item, i) => {
      getLiveModalQuantityData(item?.strategy_id, item?.scrip_id).then(
        (res) => {
          let temp = res;
          temp.quantity =
            temp.qtyamt === "amt" ? temp.initial_amount : temp.quantity;
          arr.push({ ...symbolValue[i], ...temp });
          const idx = fields.findIndex((x) => x.scrip_id === item.scrip_id);
          if (idx !== -1) {
            update(i, { ...symbolValue[i], ...temp });
            methods.setValue(`liveData[${i}].qtyamt`, {
              label: temp?.qtyamt,
              value: temp?.qtyamt,
            });

            methods.setValue(`liveData[${i}].${"quantity"}`, temp["quantity"]);
           
          }
        }
      );
    });
    setLoading(false);
  }, [symbolValue]);
  return (
    !loading && (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            setOpened(!isOpen);
            setLoading(false);
          }}
          size={"4xl"}
        >
          <ModalOverlay />
          <CustomForm
            methods={methods}
            onSubmit={methods.handleSubmit(HandleRun)}
          >
            <ModalContent>
              <ModalHeader
                display="flex"
                alignItems="center"
                borderBottom="1px solid #cccccc"
              >
                <Box
                  width="40px"
                  height="40px"
                  fontWeight="500"
                  backgroundColor="#D9FFE1"
                  borderRadius="15px"
                  mr="20px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image src={LiveTradeIcon} />
                </Box>
                <Box>
                  <Text fontSize="18px" color="#141414">
                    Run Live Trade
                  </Text>
                  <Text fontSize="14px" color="#888888">
                    Select options from list at lot size and lot at and click on
                    Run Live
                  </Text>
                </Box>
              </ModalHeader>
              <ModalCloseButton
                mt="5px"
                border="1.5px solid #bebebe"
                color="#bebebe"
              />
              <ModalBody p="0">
                {isLoading ? (
                  <Grid color={"red"} templateColumns="repeat(1, 1fr)">
                    <Box w="100%" color={"red"} justifyContent={"center"}></Box>
                  </Grid>
                ) : (
                  <>
                    <Grid templateColumns="repeat(2, 1fr)" p="20px">
                      <GridItem
                        borderRight="2px solid #EDF1FF"
                        paddingTop="0px"
                      >
                        <Text
                          style={{
                            marginBottom: "20px",
                            color: "#00a3be",
                            fontSize: "16px",
                          }}
                        >
                          {stratData?.strategy?.strategy_name}
                        </Text>
                        <Grid
                          templateColumns="repeat(1, 1fr)"
                          padding="0px 0px 15px 0px"
                          borderBottom="1px solid rgba(217, 217, 217, 0.8)"
                          gap={4}
                        >
                          <GridItem display="flex" flexDirection="column">
                            <Box style={{ display: "flex" }}>
                              <Box style={{ width: "12%" }}>
                                <Box
                                  fontSize="12px"
                                  fontWeight="500"
                                  padding="0px 5px 0px 5px"
                                  borderRadius="5px 1px 5px 1px"
                                  backgroundColor="#0DA000"
                                  color="#fff"
                                  marginRight="12px"
                                  textAlign={"center"}
                                >
                                  Buy
                                </Box>
                              </Box>
                              <Text
                                fontSize="12px"
                                fontFamily="500"
                                color="#888888"
                                width="300px"
                                style={{ width: " 86%" }}
                              >
                                {buyLength === false
                                  ? buyselllogic?.buy_logic?.substring(0, 100)
                                  : buyselllogic?.buy_logic}

                                <span
                                  style={{
                                    color: "#00a3be",
                                    cursor: "pointer",
                                    marginLeft: "4px",
                                  }}
                                  onClick={() => setbuyLength(!buyLength)}
                                >
                                  {buyselllogic?.buy_logic?.length === 101
                                    ? ""
                                    : buyLength === false
                                    ? "read more"
                                    : "read less"}
                                </span>
                              </Text>
                            </Box>
                          </GridItem>
                          <GridItem display="flex">
                            <Box style={{ display: "flex" }}>
                              <Box style={{ width: "12%" }}>
                                <Box
                                  fontSize="12px"
                                  fontWeight="500"
                                  padding="0px 5px 0px 5px"
                                  borderRadius="5px 1px 5px 1px"
                                  backgroundColor="#E90303"
                                  color="#fff"
                                  marginRight="12px"
                                  textAlign={"center"}
                                >
                                  Sell
                                </Box>
                              </Box>
                              <Box
                                fontSize="12px"
                                fontFamily="500"
                                color="#888888"
                                style={{ width: " 87%" }}
                              >
                                {sellLength === false
                                  ? buyselllogic?.sell_logic?.substring(0, 100)
                                  : buyselllogic?.sell_logic}

                                <span
                                  style={{
                                    color: "#00a3be",
                                    cursor: "pointer",
                                    marginLeft: "4px",
                                  }}
                                  onClick={() => setSellLength(!sellLength)}
                                >
                                  {buyselllogic?.sell_logic?.length === 101
                                    ? ""
                                    : sellLength === false
                                    ? "read more"
                                    : "read less"}
                                  {/* {sellLength === false ? "read more" : "read less"} */}
                                </span>
                              </Box>
                            </Box>
                          </GridItem>
                        </Grid>

                        <Grid templateColumns="repeat(1, 1fr)">
                          <GridItem>
                            <Box
                              style={{
                                display: "flex",
                                margin: "20px 0px 20px 0px",
                              }}
                            >
                              <Text
                                style={{
                                  marginRight: "15px",
                                  fontSize: "16px",
                                  color: "#141414",
                                }}
                              >
                                Fully Automated
                              </Text>
                              <Switch
                                onChange={(e) => setIsAuto(e.target.checked)}
                              />
                            </Box>
                            <Text
                              style={{ fontSize: "12px", color: "#888888" }}
                            >
                              Market order placement without user permission
                            </Text>
                          </GridItem>
                          <GridItem my={4}>
                            <HStack>
                              <Text>Market</Text>
                              <FormSwitch
                                name="order_type"
                                control={methods.control}
                                error={methods.formState.errors}
                                onChangeNew={(e) => {
                                  setOrderType(e.target.checked);
                                }}
                                defaultValue={isPlaceOption}
                                isDisabled={true}
                              />
                              <Text>Limit</Text>
                            </HStack>
                          </GridItem>
                          <GridItem mb={2}>
                            <HStack>
                              <Text
                                style={!isPremium ? { color: "#888888" } : {}}
                              >
                                Place Order in Options
                              </Text>
                              <Box position="relative">
                                <Badge
                                  fontSize={9}
                                  color="#000"
                                  position="absolute"
                                  top={-4}
                                  right={-6}
                                >
                                  {" "}
                                  Beta
                                </Badge>
                              </Box>
                              <FormSwitch
                                right={-6}
                                name="optionsOrder"
                                control={methods.control}
                                error={methods.formState.errors}
                                onChangeNew={(e) => {
                                  setIsPlaceOption(e.target.checked);
                                }}
                                defaultValue={isPlaceOption}
                                isDisabled={
                                  !isPremium ||
                                  symbolValue?.some(
                                    (ele) => ele?.exchange === "nse_eq"
                                  )
                                }
                              />
                            </HStack>
                            <Text
                              as="span"
                              display={"block"}
                              fontSize="12px"
                              color="#888888"
                            >
                              Note: Please select all future symbols to place
                              order in options
                            </Text>
                          </GridItem>
                        </Grid>
                        <Grid templateColumns="repeat(2, 95%)">
                          <GridItem>
                            <Text>Brokers</Text>
                            <Box
                              p="10px"
                              border="1.5px solid #BEBEBE"
                              marginTop="15px"
                            >
                              <Grid
                                templateColumns="repeat(1 , 1fr)"
                                alignItems="center"
                              >
                                {/* <GridItem>
                              <Image src={AliceIcon} alt="borker" />
                            </GridItem> */}
                                <GridItem>
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: "14px",
                                        color: "#141414",
                                      }}
                                    >
                                      {activeBroker && activeBroker.broker}
                                    </Text>
                                    <GridItem>
                                      <Box
                                        display="flex"
                                        background={
                                          activeBroker?.active
                                            ? "#D9FFE1"
                                            : "#ffe2e2"
                                        }
                                        padding="1px 10px 1px 10px"
                                        borderRadius="5px"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        {activeBroker?.active ? (
                                          <Image mb="4px" src={wifiIcon} />
                                        ) : (
                                          <Image mb="4px" src={NotActiveIcon} />
                                        )}

                                        <Text
                                          ml="4px"
                                          fontSize="14px"
                                          color={
                                            activeBroker?.active
                                              ? "#0DA000"
                                              : "#e90303"
                                          }
                                        >
                                          {activeBroker?.active
                                            ? "Active"
                                            : "No Broker Active"}
                                        </Text>
                                      </Box>
                                    </GridItem>
                                  </Box>
                                </GridItem>
                              </Grid>
                            </Box>
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem style={{ padding: "0px 10px 20px 20px" }}>
                        <Text style={{ fontSize: "16px", color: "$141414" }}>
                          Strategy Period
                        </Text>
                        <Box style={{ marginTop: "15px" }}>
                          <FormSelect
                            name="Interval"
                            variant="outline"
                            label="Interval"
                            control={methods.control}
                            error={methods.formState.errors}
                            options={strategyLiveFor}
                            rules={{ required: "This is required" }}
                          />
                        </Box>
                        <Text color="#141414" marginTop="20px">
                          {stratData?.strategy?.qtyamt === "amt"
                            ? "Amount"
                            : "Quantity"}
                        </Text>
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#888888",
                            marginTop: "15px",
                          }}
                        >
                          {symbolValue?.length} Symbols selected{" "}
                        </Text>
                        {fields?.map((item, index) => (
                          <Box
                            p="10px"
                            border="1.5px solid #BEBEBE"
                            marginTop="15px"
                          >
                            <Grid
                              templateColumns="repeat(3, 1fr)"
                              alignItems="center"
                            >
                              <GridItem colSpan={1}>
                                <ScripComponent
                                  name={item.scrip_name}
                                  exchange={
                                    item.exchange === "nse_eq" ? "E" : "F"
                                  }
                                />
                              </GridItem>
                              <GridItem colSpan={2}>
                                <HStack
                                  spacing={-7}
                                  display={"flex"}
                                  marginLeft={"10%"}
                                >
                                  <GridItem w="100%">
                                    <FormNumberInput
                                      name={
                                        stratData?.strategy?.qtyamt === "amt"
                                          ? `liveData[${index}].initial_amount`
                                          : `liveData[${index}].quantity`
                                      }
                                      defaultValue={
                                        stratData?.strategy?.qtyamt === "amt"
                                          ? methods.getValues(
                                              `liveData[${index}].initial_amount`
                                            )
                                          : methods.getValues(
                                              `liveData[${index}].quantity`
                                            )
                                      }
                                      onKeyDown={blockInvalidChar}
                                      control={methods.control}
                                      error={methods.formState.errors}
                                      width={"100%"}
                                      p="16px"
                                    />
                                  </GridItem>
                                </HStack>
                              </GridItem>
                            </Grid>
                          </Box>
                        ))}
                      </GridItem>
                    </Grid>
                    <Box p={4}>
                      {isPlaceOption && (
                        <>
                          <Divider my={4} />
                          <TradeOption
                            onAddAtm={onAddAtm}
                            buyData={tradeOptions.buy}
                            sellData={tradeOptions.sell}
                            position={position}
                          />
                        </>
                      )}
                    </Box>
                    <Box
                      style={{
                        height: "79px",
                        background: "#E4FDFF",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "16px",
                          color: "#888888",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpened(!isOpen)}
                      >
                        Cancel
                      </Text>
                      <Button
                        style={{
                          fontSize: "16px",
                          color: "#ffff",
                          background: "#00a3be",
                          marginRight: "20px",
                          borderRadius: "2px",
                        }}
                        type="submit"
                      >
                        Run Live
                      </Button>
                    </Box>
                  </>
                )}
                {loader ? (
                  <Loading loading={true} loaderColor="#00a3be" />
                ) : null}
              </ModalBody>
            </ModalContent>
          </CustomForm>
        </Modal>
      </>
    )
  );
};

export default AstraLiveTradeModal;
