import { useEffect, useState } from "react";
import { request } from "utils/interceptors";
import { getBackTestDetails } from "apis/backtest copy";
import { getStrategyData } from "apis/callListStrategy";
import AstraLiveTradeModal from "./astraliveTradeModal";
import Loading from "react-fullscreen-loading";
const AstraLive = (props) => {
  const { isOpen, setOpened, isLoading , setIsLoading, selectedsymbolValue, stratid } = props;
  const [symbolValue, setSymbolValue] = useState([]);
  const [liveOpen, setLiveOpen] = useState(false);
  const [backtestData, setBackTestData] = useState([]);
  const [buyselllogic, setBuySellLogic] = useState({});
  const [stratData, setStratData] = useState({
    strategy: null,
    backtest: null,
    live_trade: null,
  });
  const timeDelay = () => {
    setTimeout(function () {
    }, 100);
  };
  const strategysetter = async (data, res) => {
    let newdate = new Date();
    data.strategy_name =
      "astra_" + res.strategy_name + "_" + newdate.toLocaleString();
    data.position_type = res.position_type;
    data.chart_type = res.chart_type;
    data.take_profit = parseFloat(res.take_profit);
    data.stop_loss = parseFloat(res.stop_loss);
    data.product_type = res.product_type;
    data.start_time = res.start_time;
    data.end_time = res.end_time;
    data.sl_type = res.sl_type;
    data.tp_type = res.tp_type;
    data.qtyamt = res.qtyamt;
    data.entry_end_time = res.entry_end_time;
    data.quantity = res.quantity;
    data.initial_amount = parseFloat(res.initial_amount);
    data.max_trade_per_day = res.max_trade_per_day.toFixed();
    data.weekday_list_onTrade = JSON.parse(
      res.weekday_list_onTrade.replace(/'/g, '"')
    );
    data.percentage_slippage = res.percentage_slippage;
    data.scrips = res.scrips;
    data.trailing_sl_perc = parseFloat(res.trailing_sl_perc);
    data.description = res.description;
    data.candle_interval_unit = res.candle_interval_unit;
    data.candle_interval = res.candle_interval;
    data.order_type = parseInt(res.order_type);
    let stratindsetter = [];
    res.strategy_ind.map((ele) => {
      let example = {
        indicator_name: ele.indicator_name,
        andor: ele.andor,
        indicatorItem: ele.indicatorItem,
        indicatorDetails: ele.indicatorDetails,
        is_buy: ele.is_buy,
        is_not: ele.is_not,
        compareIndicator: ele.compareIndicator,
        compareIndicatorItem: ele.compareIndicatorItem,
        compareIndicatorDetails: ele.compareIndicatorDetails,
        comparator: ele.comparator,
        comparatorItem: ele.comparatorItem,
        comparatorDetails: ele.comparatorDetails,
      };
      stratindsetter.push(example);
    });
    data.strategy_ind = stratindsetter;
    data.brick_size = parseInt(res.brick_size);
    data.renko_brick_type = res.renko_brick_type;
    setStratData((stratData) => ({
      ...stratData,
      strategy: data,
    }));
    setBuySellLogic({
      buy_logic: res.buy_logic,
      sell_logic: res.sell_logic,
    });
    return data;
  };

  const startegyData = async (id) => {
    let data = {};
    try {
      let reqUrl = "/api/strategy/v2/public/";
      getStrategyData(reqUrl, id).then((res) => {
        data = strategysetter(data, res);
      });
      let backtestdata = await request(`api/backtest/v2/getValues/${id}/`);
      backtestdata.data.startcash = backtestdata.data.initial_amount;
      delete backtestdata.data.initial_amount;
      setStratData((stratData) => ({
        ...stratData,
        backtest: backtestdata.data,
      }));
      getBackTestDetails("/api/backtest/v2/view_list", id).then((res) => {
        setBackTestData(res);
      });
      setLiveOpen(true);
      timeDelay();
    } catch (error) {
      console.error("Error in Strategy Card:", error);
    }
  };

  useEffect(() => {
    startegyData(stratid);
  }, []);
  useEffect(() => {
    let symbols = [];
    backtestData.map((ele) => {
      let arr = {
        scrip_name: ele?.scrip_name,
        exchange: ele?.exchange,
        strategy_id: ele?.strategy_id,
        id: ele?.id,
        scrip_id: ele?.scrip_id,
      };
      symbols.push(arr);
    });
    setSymbolValue(symbols);
  }, [backtestData]);
  return (
    <>
      {selectedsymbolValue.length !== 0 || symbolValue.length !== 0 ? 
        <AstraLiveTradeModal
          isOpen={liveOpen}
          setOpened={setLiveOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          symbolValue={
            selectedsymbolValue.length !== 0 ? selectedsymbolValue : symbolValue
          }
          stratData={stratData}
          setStratData={setStratData}
          buyselllogic={buyselllogic}
        />
       : <Loading loading={true} loaderColor="#00a3be" />
       
       }
    </>
  );
};

export default AstraLive;
