import { Icon } from "@chakra-ui/react";
import React from "react";

const BasketIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "19px"}
      height={height ? height : "21px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 21"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76989 0C7.69882 0 6.01989 1.67893 6.01989 3.75V4.7506C4.12498 4.78323 2.50855 6.14383 2.15811 8.01285L0.658106 16.0128C0.196546 18.4745 2.08504 20.75 4.5896 20.75H14.9502C17.4547 20.75 19.3432 18.4745 18.8817 16.0128L17.3817 8.01285C17.0312 6.14383 15.4148 4.78323 13.5199 4.7506V3.75C13.5199 1.67893 11.841 0 9.76989 0ZM12.0199 4.75V3.75C12.0199 2.50736 11.0125 1.5 9.76989 1.5C8.52725 1.5 7.51989 2.50736 7.51989 3.75V4.75H12.0199Z"
        fill="#888888"
      />
    </Icon>
  );
};

export default BasketIcon;
