import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "utils/interceptors";

const initialState = {
  disclaimer: true,
};

export const getLiveOrderBook = createAsyncThunk(
  "strategy/getLiveOrderBook",
  async (id, { rejectWithValue }) => {
    try {
      const res = await instance.get(`api/live-trade/v2/order_book/`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLiveOpenPosition = createAsyncThunk(
  "strategy/getLiveOpenPosition",
  async (id, { rejectWithValue }) => {
    try {
      const res = await instance.get(`api/live-trade/v2/open_position/`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVirtualOrderBook = createAsyncThunk(
  "strategy/getVirtualOrderBook",
  async (id, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        `api/paper-trade/v2/papertrade-order-book/`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVirtualOpenPosition = createAsyncThunk(
  "strategy/getVirtualOpenPosition",
  async (id, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        `api/paper-trade/v2/papertrade-open-position/`
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {
    clearViewList: (state) => {
      state.viewList = [];
    },
    selectedStrategy: (state, action) => {
      state.selected = action.payload;
    },
    clearSelectedStrategy: (state) => {
      state.selected = null;
    },
    toggleDisclaimer: (state) => {
      state.disclaimer = !state.disclaimer;
    },
  },
  extraReducers: {},
});

export const {
  clearViewList,
  selectedStrategy,
  clearSelectedStrategy,
  toggleDisclaimer,
} = strategySlice.actions;
export default strategySlice.reducer;
