import { Icon } from "@chakra-ui/react";
import React from "react";

const MyAccount = (props) => {
  const { color, height, width, hover } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      xmlns="http://www.w3.org/2000/svg"
      //   width="10"
      viewBox="0 0 22 22"
      _hover={hover}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1H5C2.79086 1 1 2.79086 1 5V17C1 18.8642 2.27532 20.4306 4.00111 20.8743C4.32039 20.9563 4.6551 21 5 21H17C17.3449 21 17.6796 20.9563 17.9989 20.8743C19.7247 20.4306 21 18.8642 21 17V5C21 2.79086 19.2091 1 17 1ZM11 12C13.4617 12 15.5783 13.6062 16.5115 15.9071C16.9491 16.986 16.0067 18 14.8425 18H7.15752C5.99332 18 5.05092 16.986 5.48849 15.9071C6.42174 13.6062 8.53834 12 11 12ZM11 4C12.6569 4 14 5.34315 14 7C14 8.65685 12.6569 10 11 10C9.34315 10 8 8.65685 8 7C8 5.34315 9.34315 4 11 4Z"
        stroke="#888888"
        strokeWidth="1.5"
        fill="none"
      />
    </Icon>
  );
};

export default MyAccount;
