import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
  Avatar,
  Tooltip,
  Box,
  Modal,
  Image,
} from "@chakra-ui/react";
import { publicBasket, updateDeleteBasket, userBasket } from "apis/basket";
import CopyIcon from "assets/copy";
import DeleteIcon from "assets/delete";
import EditIcon from "assets/edit";
import Vector from "assets/Basket.svg";
import ScripComponent from "components/elements/ScripComponent";
import FormButton from "controls/Button";
import React, { useEffect, useState } from "react";
import CreateUpdateBasket from "./CreateUpdateBasket";

const AllBasket = (props) => {
  const [baskets, setBaskets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBasket, setSelectedBasket] = useState(null);
  const [isEditCopy, setIsEditCopy] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openBasket, setBasketOpen] = useState(false);
  const toast = useToast();
  const fetchBaskets = async () => {
    Promise.all([userBasket(), publicBasket()]).then(([res1, res2]) => {
      setBaskets([
        ...res1,
        ...res2.map((item) => {
          return { ...item, isPublic: true };
        }),
      ]);
      setIsLoading(false);
    });
  };

  const deleteBasket = async (id) => {
    try {
      // eslint-disable-next-line
      const res = await updateDeleteBasket("delete", id, {});
      toast({
        title: "Basket deleted successfully",
        status: "success",
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      console.log(error, "!!! Error in deleteBasket");
      toast({
        title: "Error deleting basket",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    } finally {
      fetchBaskets();
    }
  };

  useEffect(() => {
    fetchBaskets();
  }, []);

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display={"flex"}
          borderBottom="2px solid #EDF1FF"
          justifyContent="start"
          gap="4"
          p={3}
        >
          <Box
            height="46px"
            width="46px"
            bg="#E4FDFF"
            borderRadius={"15px"}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Image src={Vector} height="26px" width="24px" />
          </Box>
          <Stack spacing={0}>
            <Text fontSize={18} color="#141414" fontWeight={500}>
              Basket
            </Text>
            <Text fontSize={14} fontWeight={500} color="#888888">
              All your basket are shown here and you edit or delete them
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton
          mt="5px"
          border="1.5px solid #bebebe"
          color="#bebebe"
        />
        <ModalBody>
          {isLoading ? (
            <Stack
              minHeight="380px"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner size="xl" />
            </Stack>
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Tbody>
                  {baskets.map((item) => {
                    return (
                      <Tr>
                        <Td pl="0px">
                          {" "}
                          <Text mb="15px">{item?.basket_name}</Text>
                          <Stack direction="row" alignItems={"center"}>
                            {item?.scrips.length > 2 ? (
                              <>
                                {item?.scrips
                                  .slice(0, true ? 1 : 2)
                                  .map((item) => {
                                    return (
                                      // <ScripComponent
                                      //   exchange={item.exchange === "NSE" ? "E" : "F"}
                                      //   name={
                                      //     item?.symbol.length > 5
                                      //       ? item?.symbol.slice(0, 4).toUpperCase() + "..."
                                      //       : item?.symbol?.toUpperCase()
                                      //   }
                                      //   key={item?.id}
                                      // />
                                      <>
                                        <Box
                                          color={"#FFFFFF"}
                                          borderTopLeftRadius={"5px"}
                                          borderBottomRightRadius={"5px"}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent="center"
                                          fontSize={"14px"}
                                          height="18px"
                                          width="18px"
                                          bg={
                                            item.exchange === "NSE"
                                              ? "#00a3be"
                                              : // : item[exchange] === "nse_eg"
                                                // ? '#934646'
                                                // : '#FF5F1F'
                                                "#934646"
                                          }
                                          lineHeight={1}
                                        >
                                          {item.exchange === "NSE" ? "E" : "F"}
                                        </Box>
                                        <Tooltip
                                          label={item?.exchange.toUpperCase()}
                                          //   isOpen
                                          hasArrow
                                          bg={"#fff"}
                                          fontSize={16}
                                          color={"#00a3be"}
                                          border={"1.5px solid #00a3be"}
                                          borderRadius={"5px"}
                                          arrowShadowColor="#00a3be"
                                          zIndex={2}
                                        >
                                          <Text
                                            fontWeight={"500"}
                                            color="#141414"
                                          >
                                            {item?.symbol.length > 5
                                              ? item?.symbol
                                                  .slice(0, 4)
                                                  .toUpperCase() + "..."
                                              : item?.symbol?.toUpperCase()}
                                          </Text>
                                        </Tooltip>
                                      </>
                                    );
                                  })}
                                <Tooltip
                                  label={item?.scrips
                                    ?.slice(1, item.scrips.length)
                                    .map((item) => item.symbol.toUpperCase())
                                    .join()}
                                  //   isOpen
                                  hasArrow
                                  bg={"#fff"}
                                  fontSize={16}
                                  color={"#00a3be"}
                                  border={"1.5px solid #00a3be"}
                                  borderRadius={"5px"}
                                  arrowShadowColor="#00a3be"
                                  zIndex={2}
                                >
                                  <Box
                                    fontSize={12}
                                    color="#00a3be"
                                    bg="#E8F0F1"
                                    px={1}
                                    borderTopLeftRadius={6}
                                    borderBottomRightRadius={6}
                                  >
                                    +{item?.scrips?.length - (true ? 1 : 2)}{" "}
                                    Others
                                  </Box>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {item?.scrips.map((item) => {
                                  return (
                                    <>
                                      <Box
                                        color={"#FFFFFF"}
                                        borderTopLeftRadius={"5px"}
                                        borderBottomRightRadius={"5px"}
                                        display="flex"
                                        alignItems={"center"}
                                        justifyContent="center"
                                        fontSize={"14px"}
                                        height="18px"
                                        width="18px"
                                        bg={
                                          item.exchange === "NSE"
                                            ? "#00a3be"
                                            : // : item[exchange] === "nse_eg"
                                              // ? '#934646'
                                              // : '#FF5F1F'
                                              "#934646"
                                        }
                                        lineHeight={1}
                                      >
                                        {item.exchange === "NSE" ? "E" : "F"}
                                      </Box>
                                      <Tooltip
                                        label={item?.exchange.toUpperCase()}
                                        //   isOpen
                                        hasArrow
                                        bg={"#fff"}
                                        fontSize={16}
                                        color={"#00a3be"}
                                        border={"1.5px solid #00a3be"}
                                        borderRadius={"5px"}
                                        arrowShadowColor="#00a3be"
                                        zIndex={2}
                                      >
                                        <Text
                                          fontWeight={"500"}
                                          color="#141414"
                                        >
                                          {item?.symbol.length > 5
                                            ? item?.symbol
                                                .slice(0, 4)
                                                .toUpperCase() + "..."
                                            : item?.symbol?.toUpperCase()}
                                        </Text>
                                      </Tooltip>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </Stack>
                        </Td>
                        <Td pr="0px" isNumeric>
                          <Stack
                            direction={"row"}
                            spacing={3}
                            alignItems={"center"}
                            justifyContent={"flex-between"}
                            width={"50%"}
                            marginLeft={"auto"}
                          >
                            {item?.isPublic ? (
                              <>
                                <CopyIcon
                                  // color={"#2d77f4"}
                                  ml="25px"
                                  flex={1}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setIsEditCopy("copy");
                                    setSelectedBasket(item);
                                    onOpen();
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <EditIcon
                                  // color={"#2d77f4"}
                                  width="18px"
                                  height="18px"
                                  // flex={1}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setIsEditCopy("edit");
                                    setSelectedBasket(item);
                                    onOpen();
                                  }}
                                />
                                <DeleteIcon
                                  // color={"#2d77f4"}
                                  width="20px"
                                  height="20px"
                                  flex={1}
                                  cursor={"pointer"}
                                  onClick={() => deleteBasket(item?.id)}
                                />
                              </>
                            )}
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </ModalBody>
        <ModalFooter bg="#E4FDFF">
          <FormButton
            text="Cancel"
            bg="none"
            color="#888888"
            hover={"none"}
            _focus={{ bg: "none", color: "none" }}
            onClick={props.onCloseBasket}
          />
          <FormButton
            text="Add"
            onClick={() => {
              onOpen();
              setIsEditCopy(null);
            }}
          />
        </ModalFooter>
      </ModalContent>

      <CreateUpdateBasket
        isOpen={isOpen}
        onClose={onClose}
        isEditCopy={isEditCopy}
        selectedBasket={selectedBasket ? selectedBasket : []}
        setSelectedBasket={setSelectedBasket}
        refreshData={fetchBaskets}
      />
    </>
  );
};

export default AllBasket;
