
import {  Grid, GridItem, Stack, Text } from "@chakra-ui/react";

import { Chart, registerables } from "chart.js";
import withRouter from "components/hoc/withRouter";
import { useState } from "react";
import { Line } from "react-chartjs-2";

import AstraLive from "components/myStrategy/astraLive";

import FormButton from "controls/Button";
Chart.register(...registerables);

const StrategyCard = (props) => {
  const { item, isPublic, router, Astra } = props;
  const [astraliveOpen, setAstraLiveOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [symbolValue, setSymbolValue] = useState([]);
  const firstValue = Object.values(item ? item?.positions : {})[0];
  const lastValue = Object.values(item ? item?.positions : {})[
    Object.values(item ? item?.positions : {}).length - 1
  ];

  const testData = {
    labels: Object.keys(item ? item?.positions : {}),
    datasets: [
      {
        label: "Earning",
        data: Object.values(item ? item?.positions : {}),
        fill: true,
        tension: 0.4,
        // backgroundColor: firstValue < lastValue ? "#0da0005a" : "#e903035a",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 180);
          gradient.addColorStop(
            0,
            firstValue < lastValue ? "#0da0005a" : "#e903035a"
          );
          gradient.addColorStop(1, "#ffffff");
          return gradient;
        },
        pointRadius: 0,
        borderColor: firstValue < lastValue ? "#0da000" : "#e90303",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },

      y: {
        grid: {
          display: false,
          opacity: 0.2,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };
  const mlstyle = {
    marginLeft: "-.4rem",
  };
  return (
    <>
      <Stack
        p={0}
        // bg={firstValue < lastValue ? "#E2FFE8" : "#FFE2E2"}
        borderRadius="3px"
        spacing={0}
        // height={225}
        cursor="pointer"
        onClick={() => {
          if (!isPublic) {
            props.router.navigate(
              `/strategy/${isPublic ? "copy" : "edit"}/${item.strategy_id}`,
              { state: { isPopular: isPublic } }
            );
          }
        }}
      >
        <Stack p={2}>
          <Text fontSize={18} color="#141414">
            {item?.strategy_name.length > 22
              ? item?.strategy_name.substring(0, 22) + "..."
              : item?.strategy_name}
          </Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem w="100%" colSpan={2}>
              {/* <Text
                fontSize={14}
                mb={1}
                color="#888888"
                display="flex"
                gap={2}
                alignItems="center"
              >
                Backtest Result{" "}
                {isPublic && (
                  <Tooltip
                    label={
                      "Only for reference purpose & does not guarantee future returns"
                    }
                    //   isOpen
                    hasArrow
                    bg={"#fff"}
                    fontSize={16}
                    color={"#00a3be"}
                    border={"1.5px solid #00a3be"}
                    borderRadius={"5px"}
                    arrowShadowColor="#00a3be"
                    zIndex={2}
                  >
                    <InfoIcon cursor="pointer" />
                  </Tooltip>
                )}
              </Text> */}
              {/* <Text
                fontSize={16}
                color={
                  item?.return_value?.return_total__max > 0 ? "green" : "red"
                }
              >
                ({item?.return_value?.return_total__max || 0}%){" "}
                {item?.return_value?.return_total__max > 0 ? (
                  <PositiveNegativeIcon
                    color={
                      item?.return_value?.return_total__max > 0
                        ? "#0DA000"
                        : "#E90303" || 0
                    }
                  />
                ) : (
                  <PositiveNegativeIcon
                    color={
                      item?.return_value?.return_total__max > 0
                        ? "#0DA000"
                        : "#E90303" || 0
                    }
                    transform="rotate(180deg)"
                  />
                )}
              </Text> */}
            </GridItem>
          </Grid>
        </Stack>

        <Stack
          width={"103.5%"}
          position={"relative"}
          height={150}
          p={0}
          style={mlstyle}
          borderBottomRadius={"50%"}
          mt={"1rem !important"}
        >
          <Line data={testData} options={options} className="chart_js" />
        </Stack>
        {isPublic ? (
          <Grid
            position={"relative"}
            top={-4}
            templateColumns="repeat(2, 1fr)"
            gap={2}
          >
            <GridItem>
              <FormButton
                text="Run Backtest"
                variant="ghost"
                color="#00a3be"
                backgroundColor="#FFFFFF"
                width={"100%"}
                onClick={() =>
                  props?.router?.navigate(
                    `/strategy/copy/${item?.strategy_id}`,
                    {
                      state: { isPopular: isPublic },
                    }
                  )
                }
              />
            </GridItem>
            <GridItem>
              <FormButton
                text="Run Live"
                onClick={() => {
                  setAstraLiveOpen(true);
                  setIsLoading(true);
                }}
                width={"100%"}
                isLoading={isLoading}
              />
            </GridItem>

            {astraliveOpen && (
              <AstraLive
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isOpen={astraliveOpen}
                setOpened={setAstraLiveOpen}
                selectedsymbolValue={symbolValue}
                stratid={item.strategy_id}
              />
            )}
          </Grid>
        ) : null}
      </Stack>
    </>
  );
};

export default withRouter(StrategyCard);
