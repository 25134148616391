import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import Sound from "./assets/whatsappsound.mp3";

const config = {
  apiKey: "AIzaSyD62nBuKEtDURufosoQsRRVgfzbCH-5dPs",
  authDomain: "keev-a3472.firebaseapp.com",
  databaseURL: "https://keev-a3472.firebaseio.com",
  projectId: "keev-a3472",
  storageBucket: "keev-a3472.appspot.com",
  messagingSenderId: "664525105879",
  appId: "1:664525105879:web:1f837164423ca3e33cde92",
  measurementId: "G-WC20XEST1S",

  // apiKey: "AIzaSyBZc3BLRrO5sco7y6a7Kqx81Gnu2YmUNNY",
  // authDomain: "pushnotificationtest-c5f4f.firebaseapp.com",
  // projectId: "pushnotificationtest-c5f4f",
  // storageBucket: "pushnotificationtest-c5f4f.appspot.com",
  // messagingSenderId: "548818046725",
  // appId: "1:548818046725:web:fe58c79a2d4e867e4f716c",
  // measurementId: "G-FP87HEZFVC"
};

firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
        const audio = new Audio(Sound);
        audio.play();
      });
    }
  });

export const deleteFcmToken = () => {
  if (messaging) {
    messaging
      .deleteToken()
      .then((res) => console.log("res ", res))
      .catch((err) => console.log("err", err));
  }
};

export default firebase;
