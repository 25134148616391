import instance from "utils/interceptors";

export const totalEarningDetails = async () => {
  try {
    const res = await instance.get("api/live-trade/v2/total_earning_cards/");
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getTopGainers = async () => {
  try {
    const res = await instance.get("api/live-trade/v2/top_gainers/");
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getTopLosers = async () => {
  try {
    const res = await instance.get("api/live-trade/v2/top_losers/");
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getTotalEarningsGraph = async (length = 29) => {
  try {
    const res = await instance.get(
      `api/live-trade/v2/total_earning_graphs/?data_type=${length}`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};
