import React from "react";
import {
  Text,
  Box,
  useColorModeValue,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Image,
  Flex,
  Avatar,
  Heading,
  IconButton,
  Spacer,
  SimpleGrid,
  HStack,
  ButtonGroup,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import routes from "utils/routes";
import Ellipse from "assets/Ellipse.svg";
import { Link, Navigate } from "react-router-dom";
import Methodology from "components/learn-more/Methodology";
import withRouter from "components/hoc/withRouter";
import Subscribe from "components/modals/Subscribe";
import jr from 'assets/junior.jpg'
import mini from 'assets/mini.jpg'
import minijr from 'assets/infinityJr.jpg'
const Pricing = (props) => {
  const navigate = useNavigate();
  const datas = props?.data;
  return (
    <>
      <Card maxW="xl" padding={1}>
        <CardHeader padding={1}>
          <Flex spacing="2">
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
           <Image src={props?.data?.packageName === "Infinity Junior" ? jr : props?.data?.packageName === "Infinity Option" || props?.data?.packageName === "Infinity Intraday Option" ? minijr : mini} alt="No Position" h="60px" w={"100px"} borderRadius={"11%"}/>

              <Box>
                <Heading fontSize={"25px"}>{props?.data?.packageName}</Heading>
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody padding={2}>
          <Text>{
            props?.data?.packageName === "Infinity Option"  ? <>
            This is a suite of multiple non-correlated intra-day & Positional options
            strategies in Nifty and Banknifty with pre-defined rules for entry
            and exit. It combines directional as well as non-directional
            positions aimed to achieve good risk-adjusted returns, with
            controlled drawdowns.
            </> :
            <>
            This is a suite of multiple non-correlated intra-day  options
            strategies in Nifty and Banknifty with pre-defined rules for entry
            and exit. It combines directional as well as non-directional
            positions aimed to achieve good risk-adjusted returns, with
            controlled drawdowns.
            </>
            }
            
          </Text>
          <br></br>
          <Stack direction={"row"} align={"baseline"}>
            <Text fontSize={"3xl"}>₹</Text>
            <Text fontSize={"3xl"}>{props.data?.packagePrice}</Text>
            <Text>Min margin </Text>
          </Stack>
        </CardBody>
        <CardFooter padding={2}>
          <Flex flex="1" gap="4">
            <Spacer />
            <Box>
              
              <Button
                mt={"2rem"}
                color={" #01A2BB"}
                variant="link"
                onClick={() =>
                  props.router.navigate(routes.methodology, {
                    state: { data: datas },
                  })
                }
              >
                Learn more
              </Button>
            </Box>
            <Box display={ props?.data?.packageName == "Infinity Intraday Option" || props?.data?.packageName == "Infinity Option" ? "none" : "block"}>
              <Subscribe data={datas} month={"1"} />
            </Box>

            <Box >
              <Subscribe data={datas} month={"6"}/>
            </Box>
          </Flex>
        </CardFooter>
      </Card>
      
    </>
  );
};

export default withRouter(Pricing);
