import instance from "utils/interceptors";

export const viewProfile = async () => {
  try {
    let res = await instance.get("/api/user/v2/view_profile/");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const secretDetails = async (method) => {
  try {
    let res = await instance[method]("/api/user/v2/user_secret_detail/");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = async (data) => {
  try {
    let res = await instance.put("/api/user/v2/profile_update/", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changeUserPassword = async (data) => {
  try {
    let res = await instance.put("/api/user/v2/change_pass/", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const userBankDetails = async (data, method) => {
  try {
    let res = await instance[method]("/api/user/v2/get_bank_details/", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getReffCode = async () => {
  try {
    let res = await instance.get("api/referral/v2/referral_dashboard_count/");
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const activeSub = async (data) => {
  try {
    let res = await ("https://demo.arjunconsulting.in/api/v1/subscriptions",
    data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const checkSubscription = async (data) => {
  try {
    let res = await instance.post("/api/user/v2/user_strategy_subscription/",data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const checkSubscriptionDetails = async (data) => {
  try {
    let res = await instance.get("/api/user/v2/user_strategy_subscription/");
    return res.data;
  } catch (error) {
    throw error;
  }
};