import instance from "utils/interceptors";

export const  getBackTestDetails = async (url ,id) => {
  try {
    const res = await instance.get(`${url}/${id}/`);
    return res.data;
  } catch (error) {
    return error.response;
  }
};
export async function getDetailsFromBacktestId(backTestId) {
  try {
    let responseData = await instance.get(
      `api/backtest/v2/ohlc_data/${backTestId}/`
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}
