import { CheckCircleIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { callLiveOrder } from "apis/callLive";
import { getCount } from "app/slices/count";
import { setBadgeNumber } from "app/slices/dashboard";
import Logo from "assets/cwa.jpg";
import NoNotificationIcon from "assets/no-notification";
import NotificationIcon from "assets/notification";
import withRouter from "components/hoc/withRouter";
import FormButton from "controls/Button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "styles/Header.module.css";
import routes from "utils/routes";
import { onMessageListener } from "../../firebase";
import Profile from "./Profile";

const Header = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { badgeNumber } = useSelector((state) => state.dashboard);
  const { user_name } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getCount());
    }, 1000);
  }, [dispatch]);
  const handleAcceptOrReject = async (order_id, val) => {
    let ddata = { is_trade_allowed: val };

    try {
      toast.closeAll();
      let response = await callLiveOrder(order_id, ddata);
      toast({
        title: response?.data?.message,
        position: "top-right",
        isClosable: true,
        status: "success",
      });
    } catch (error) {
      toast({
        title: error.response?.data?.errors?.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  onMessageListener()
    .then((payload) => {
      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg={
              payload.notification.body.includes("not")
                ? "red.500"
                : "brand.900"
            }
            borderRadius={"5px"}
            cursor="pointer"
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => {
                props?.router?.navigate(
                  payload.notification.body.includes("not")
                    ? "/orders"
                    : "/notifications"
                );
                toast.closeAll();
              }}
            >
              {payload.notification.body.includes("not") ? (
                <InfoIcon w={8} h={8} />
              ) : (
                <CheckCircleIcon w={8} h={8} />
              )}
              <Stack spacing={0}>
                <Text>{payload?.notification?.title}</Text>
                <Text>{payload?.notification?.body}</Text>
              </Stack>
            </Stack>
            {payload?.notification?.title.includes("Live") && (
              <Stack direction={"row"} mt={2} justifyContent="flex-end">
                <FormButton
                  text="Accept"
                  background="white"
                  color="brand.900"
                  onClick={() =>
                    handleAcceptOrReject(payload?.data?.trade_id, true)
                  }
                />
                <FormButton
                  text="Reject"
                  background="white"
                  color="brand.900"
                  onClick={() =>
                    handleAcceptOrReject(payload?.data?.trade_id, false)
                  }
                />
              </Stack>
            )}
          </Box>
        ),
        status: "success",
        position: "top-right",
        isClosable: true,
        duration: 4000,
      });
      if (props.router.location.pathname !== "/notifications") {
        dispatch(setBadgeNumber(badgeNumber + 1));
      } else {
        dispatch(setBadgeNumber(0));
      }
    })
    .catch((err) => {
      console.log("err", err);
    });

  useEffect(() => {
    if (props.router.location.pathname === "/notifications") {
      dispatch(setBadgeNumber(0));
    }
  }, [dispatch, props.router.location.pathname]);

  return (
    <>
      <div className={styles.head}>
        <div className={styles.logo}>
          <Link to={routes.home} >
            <img src={Logo} alt="logo" width={"50px"} marginLeft={"32%"} />
          </Link>
        </div>
        <Stack direction={"row"} alignItems="center">
          {/* <Button onClick={toggleColorMode}>
            {colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
          </Button>
          <Button>C</Button>
          <Divider orientation="vertical" h={6} /> */}
          {/* <FormButton
            text="Create Strategy"
            onClick={() => props.router.navigate(routes.createStratezy)}
          /> */}
          <Divider orientation="vertical" h={6} />
          {/* <Text>151515</Text> */}
          {/* <Divider orientation="vertical" h={6} /> */}
          {/* <BasketIcon onClick={onOpenBasket} cursor="pointer" />
          <Divider orientation="vertical" h={6} /> */}
          {badgeNumber === 0 ? (
            <NoNotificationIcon
              // h={6}
              // w={6}
              cursor="pointer"
              onClick={() => props?.router.navigate(routes.notifications)}
            />
          ) : (
            <NotificationIcon
              h={6}
              w={6}
              cursor="pointer"
              onClick={() => props?.router.navigate(routes.notifications)}
            />
          )}
          <Divider orientation="vertical" h={6} />
          <Avatar
            name={user_name}
            // size="sm"
            h={10}
            w={10}
            border="1px solid #00a3be"
            background="#ebfafc"
            color="#00a3be"
            onClick={onOpen}
            cursor="pointer"
          />
        </Stack>
      </div>
      {/* Show this banner only when required */}
      {/* <Banner /> */}
      {/* Basket Modal */}
      {/* <Modal
        isOpen={isOpenBasket}
        onClose={onCloseBasket}
        isCentered
        closeOnOverlayClick={false}
        size="xl"
        scrollBehavior={"inside"}
      >
        <AllBasket onCloseBasket={onCloseBasket} />
      </Modal> */}
      {isOpen && <Profile onClose={onClose} isOpen={isOpen} />}
    </>
  );
};

export default withRouter(Header);