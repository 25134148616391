import { callApiToServer, callGetApiToServer } from "./promiseResolve";

export async function callPricing(duration = 1) {
  try {
    let responseData = await callGetApiToServer(
      `api/subscription/v2/pricing/?duration_in_months=${duration}`
    );
    return responseData.data;
  } catch (error) {
    throw error;
  }
}

export async function createOrder(subscription_id, gst_amount, total_amount) {
  try {
    let data = {
      subscription_id: subscription_id,
      total_gst_amount: parseInt(gst_amount),
      total_amount_paid: parseFloat(total_amount),
    };
    let responseData = await callApiToServer(
      `api/subscription/v2/create_order/`,
      "POST",
      data
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function savePayment(data) {
  try {
    let responseData = await callApiToServer(
      `api/subscription/v2/save_payment_details/`,
      "POST",
      data
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function checkValid(subsid) {
  try {
    let responseData = await callApiToServer(
      `api/subscription/v2/check_valid_subscription/${subsid}/`,
      "GET"
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function getUserPayment() {
  try {
    let responseData = await callApiToServer(
      `api/subscription/v2/get_user_payment/`,
      "GET"
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function checkValidReferralCode(data) {
  try {
    let responseData = await callApiToServer(
      `api/referral/v2/check_referral_code/`,
      "POST",
      data
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

// export async function getReferralData() {
//   try {
//     let responseData = await callApiToServer(
//       `api/referral/v2/referral_dashboard_count/`,
//       "GET"
//     );
//     return responseData;
//   } catch (error) {
//     throw error;
//   }
// }

export async function labelsList() {
  try {
    let responseData = await callApiToServer(`api/strategy/v2/get_tags`, "GET");
    return responseData;
  } catch (error) {
    throw error;
  }
}

export async function getDiscount(data) {
  console.log("data",data)
  try {
    let responseData = await callApiToServer(`api/subscription/v2/get_discount/`, "POST",data);
    return responseData;
  } catch (error) {
    throw error;
  }
}
