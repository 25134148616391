import { Icon } from "@chakra-ui/react";
import React from "react";

const LogoutIcon = (props) => {
  const { color, height, width, hover } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "18px"}
      xmlns="http://www.w3.org/2000/svg"
      //   width="10"
      viewBox="0 0 18 21"
      _hover={hover}
    >
      <path
        d="M18.4697 10.4697C18.1768 10.7626 18.1768 11.2374 18.4697 11.5303C18.7626 11.8232 19.2374 11.8232 19.5303 11.5303L18.4697 10.4697ZM20.2929 9.70711L20.8232 10.2374L20.8232 10.2374L20.2929 9.70711ZM20.2929 8.29289L20.8232 7.76256L20.8232 7.76256L20.2929 8.29289ZM19.5303 6.46967C19.2374 6.17678 18.7626 6.17678 18.4697 6.46967C18.1768 6.76256 18.1768 7.23744 18.4697 7.53033L19.5303 6.46967ZM19.5303 11.5303L20.8232 10.2374L19.7626 9.17678L18.4697 10.4697L19.5303 11.5303ZM20.8232 7.76256L19.5303 6.46967L18.4697 7.53033L19.7626 8.82322L20.8232 7.76256ZM20.8232 10.2374C21.5066 9.55402 21.5066 8.44598 20.8232 7.76256L19.7626 8.82322C19.8602 8.92086 19.8602 9.07915 19.7626 9.17678L20.8232 10.2374Z"
        fill="#E90303"
      />
      <path
        d="M1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5V13C9 15.2091 7.20914 17 5 17C2.79086 17 1 15.2091 1 13V5Z"
        fill="#E90303"
      />
      <path
        d="M20 9.75C20.4142 9.75 20.75 9.41421 20.75 9C20.75 8.58579 20.4142 8.25 20 8.25V9.75ZM12 8.25C11.5858 8.25 11.25 8.58579 11.25 9C11.25 9.41421 11.5858 9.75 12 9.75V8.25ZM17.75 13C17.75 12.5858 17.4142 12.25 17 12.25C16.5858 12.25 16.25 12.5858 16.25 13H17.75ZM16.25 5C16.25 5.41421 16.5858 5.75 17 5.75C17.4142 5.75 17.75 5.41421 17.75 5H16.25ZM20 8.25H12V9.75H20V8.25ZM8.25 5V13H9.75V5H8.25ZM1.75 13V5H0.25V13H1.75ZM5 16.25C3.20507 16.25 1.75 14.7949 1.75 13H0.25C0.25 15.6234 2.37665 17.75 5 17.75V16.25ZM8.25 13C8.25 14.7949 6.79493 16.25 5 16.25V17.75C7.62335 17.75 9.75 15.6234 9.75 13H8.25ZM5 1.75C6.79493 1.75 8.25 3.20507 8.25 5H9.75C9.75 2.37665 7.62335 0.25 5 0.25V1.75ZM5 0.25C2.37665 0.25 0.25 2.37665 0.25 5H1.75C1.75 3.20507 3.20507 1.75 5 1.75V0.25ZM5 1.75H13V0.25H5V1.75ZM13 16.25H5V17.75H13V16.25ZM16.25 13C16.25 14.7949 14.7949 16.25 13 16.25V17.75C15.6234 17.75 17.75 15.6234 17.75 13H16.25ZM13 1.75C14.7949 1.75 16.25 3.20507 16.25 5H17.75C17.75 2.37665 15.6234 0.25 13 0.25V1.75Z"
        fill="#E90303"
      />
    </Icon>
  );
};

export default LogoutIcon;
