import { Box, Stack, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

const ScripComponent = (props) => {
  const { exchange, name } = props
  return (
    <Stack direction="row" spacing={2} alignItems="center" width="fit-content">
      <Box
        color={'#FFFFFF'}
        borderTopLeftRadius={'5px'}
        borderBottomRightRadius={'5px'}
        display="flex"
        alignItems={'center'}
        justifyContent="center"
        fontSize={'14px'}
        height="18px"
        width="18px"
        bg={
          exchange === 'E'
            ? '#468493'
            : exchange === 'F'
            ? '#934646'
            : '#FF5F1F'
        }
        lineHeight={1}
      >
        {exchange}
      </Box>
      <Text fontWeight={'500'} color="#141414">
        {name}
      </Text>
    </Stack>
  )
}

export default ScripComponent
