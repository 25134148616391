// theme.js

// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig } from "chakra-ui-steps";
import { getColor, mode } from "@chakra-ui/theme-tools";
// 3. extend the theme

const multiSegmentFilledTrack = (props) => {
  const { theme, values, max } = props;

  const breakpoints = [];
  let totalPct = 0;
  const trackColor = getColor(theme, mode("gray.100", "gray.600")(props));

  for (const [color, val] of Object.entries(values)) {
    const fillColor = getColor(
      theme,
      mode(`${color}.500`, `${color}.200`)(props)
    );
    let pct = +Number.parseFloat((val / max) * 100).toFixed(1);

    breakpoints.push(`${fillColor} ${totalPct}%`);

    totalPct += pct;
    if (totalPct > max) {
      totalPct = max;
    }

    breakpoints.push(`${fillColor} ${totalPct}%`);
  }

  if (totalPct < max) {
    breakpoints.push(`${trackColor} ${totalPct}%`);
    breakpoints.push(`${trackColor} 100%`);
  }

  const gradient = `
    linear-gradient(
    to right,
    ${breakpoints.join(", ")}
  )`;

  // Need to override the width specified by style
  return {
    minWidth: "100%",
    bgImage: gradient,
  };
};

const multiColorFilledTrack = (props) => {
  const { theme, colors, value } = props;
  const breakpoints = [];
  let prevColor;

  for (const [pct, color] of Object.entries(colors)) {
    const fillColor = getColor(
      theme,
      mode(`${color}.500`, `${color}.200`)(props)
    );
    if (prevColor && prevColor !== fillColor) {
      breakpoints.push(`${prevColor} ${pct}`);
    }
    prevColor = fillColor;
    breakpoints.push(`${fillColor} ${pct}`);
  }
  breakpoints.push(`${prevColor} 100%`);

  const trackColor = getColor(theme, mode("gray.100", "gray.600")(props));

  const gradient = `
    linear-gradient(
      to right, 
      transparent 0%, transparent ${value}%, 
      ${trackColor} 0%, ${trackColor} 100%), 
    linear-gradient(
    to right,
    ${breakpoints.join(", ")}
  )`;

  // Need to override the width specified in the style
  // on progress bar
  return {
    minWidth: "100%",
    bgImage: gradient,
  };
};

export const ProgressTheme = {
  components: {
    Progress: {
      variants: {
        multiColor: (props) => ({
          filledTrack: multiColorFilledTrack(props),
        }),
        multiSegment: (props) => ({
          filledTrack: multiSegmentFilledTrack(props),
        }),
      },
    },
  },
};

const CustomSteps = {
  ...StepsStyleConfig,
  baseStyle: (props) => {
    return {
      ...StepsStyleConfig.baseStyle(props),
      icon: {
        ...StepsStyleConfig.baseStyle(props).icon,
        // your custom styles here
        strokeWidth: "1px",
      },
      label: {
        ...StepsStyleConfig.baseStyle(props).label,
        fontSize: "sm",
        color: "#141414",
      },
      connector: {
        marginInline: -2,
        _highlighted: {
          borderColor: "#00a3be",
        },
      },
      stepIconContainer: {
        ...StepsStyleConfig.baseStyle(props).stepIconContainer,
        borderRadius: 0,
        borderTopLeftRadius: "15px",
        borderBottomRightRadius: "15px",
        _activeStep: {
          borderColor: "#00a3be",
        },
        _highlighted: {
          bg: "#00a3be",
          borderColor: "#00a3be",
        },
      },
    };
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
const theme = extendTheme({
  colors: {
    brand: {
      900: "#00a3be",
      700: "#00a3be",
      500: "#00a3be",
    },
    blue: {
      900: "#00a3be",
      700: "#00a3be", //active
      600: "#00a3be", //hover
      500: "#00a3be", //actual
    },
  },
  components: {
    Steps: CustomSteps,
    Progress: {
      variants: {
        multiColor: (props) => ({
          filledTrack: multiColorFilledTrack(props),
        }),
        multiSegment: (props) => ({
          filledTrack: multiSegmentFilledTrack(props),
        }),
      },
    },
  },
  ...config,
});

export default theme;
