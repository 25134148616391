import { useEffect, useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  PinInput,
  PinInputField,
  useDisclosure,
  Stack,
  Text,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  GridItem,
  useToast,
  Spinner,
  VStack,
  Flex,
} from "@chakra-ui/react";
import Logo from "assets/cwa.jpg";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { genrateOTP } from "apis/subscriptionApis";
import instance from "utils/interceptors";
import FormNumberInput from "controls/FormNumberInput";
import { useForm } from "react-hook-form";
import {
  checkSubscription,
  secretDetails,
  updateProfile,
  viewProfile,
} from "apis/profile";
import agreementPdf from "assets/pdf/cwaRiaAgree.pdf";
import SubscribeDetails from "./SubscribeDetails";
import RazorpayButton from "controls/RazorpayButton";
import { useSelector } from "react-redux";

Chart.register(ArcElement);
const Subscribe = (props) => {
  const popupData = props?.data;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOTP] = useState(false);
  const [enteredotp, setEnteredOTP] = useState("");
  const [accept, setAccepted] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [result, setResult] = useState(false);
  const [isPrice, setisPrice] = useState(false);
  const toast = useToast();
  const data = {
    datasets: [
      {
        data: [15, 15, 15],
        backgroundColor: ["#55DC16", "#F5AB0C", "#FF0000"],
        display: true,
        borderColor: "#D1D6DC",
      },
    ],
  };
  const options_doughnut = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: "85%",
    maintainAspectRatio: true,
    responsive: true,
  };
  const questions = [
    {
      content:
        "What's your risk tolerance or willingness to take financial risk ?",
      value: [1, 2, 3],
      options: ["Low", "Moderate ", "High"],
    },
    {
      content: "What is your preference when holding risky assets ?",
      value: [1, 2, 3],
      options: ["Mostly Safety", "Mix of Safety & Return ", "Mostly Return"],
    },
    {
      content:
        "How many years of experience you have with investment products ?",
      value: [1, 2, 3],
      options: ["Very Little", "Some", "Modest"],
    },
  ];
  const options = ["A", "B", "C", "D", "E"];
  const [score, setScore] = useState(0);
  const methods = useForm();
  const [index_main, setIndexMain] = useState(0);
  const [names, setnames] = useState();
  const [email, setemail] = useState();
  const [secretkey, setsecretkey] = useState();
  const [qty, setqty] = useState();
  const [isLoding, setisLoding] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [subscription, setsubscription] = useState();
  const [formData] = useState(new FormData());
  const [isCalled, setisCalled] = useState(false);
  const [onlyOne, setonlyOne] = useState(false)

  const {
    token
  } = useSelector((state) => state.auth);

  if (isOpen && !isCalled) {
    checkSubscription({
      strategy_name: popupData?.packageName,
      price: 1000,
      duration: "1M",
    })
      .then((res) => {
        if (res?.is_subscribed) {
          setsubscription(true);
        } else {
          setsubscription(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setisCalled(true);
  }

  const handleQuestion = (index) => {
    if (index_main <= 2) {
      setIndexMain(index_main + 1);
      setScore(score + questions[index_main].value[index]);
    }
    if (index_main === 2) {
      setQuestionnaire(true);
      setResult(true);
      // setIndexMain(0);
      // setScore(0);
    }
  };

  useEffect(() => {
    methods.setValue("Quantity", "1");
    if (!isOpen) {
      setIndexMain(0);
      setScore(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if(token !== null && !onlyOne && isOpen){
    Promise.all([secretDetails("get"), viewProfile()]).then((res) => {
      setsecretkey(res[0]?.secret_key);
      setnames(res[1].name);
      setemail(res[1].email);
    })
  }
  setonlyOne(true)
  }, [token]);

  const getOtp = async (e) => {
    const data = {
      strategy_name: popupData?.packageName,
      price: parseFloat(
        popupData?.packageName === "Infinity Mini"
          ? props.month == 1
            ? "3000"
            : "15000"
          : props.month == 1
          ? "5000"
          : " 25000"
      ),
      duration: `${props.month}M`,
    };
    let res = await instance.post(`api/user/v2/send_agreement/`, data);
    toast({
      title: res?.data?.message,
      status: "success",
      position: "top-right",
      isClosable: true,
    });
    alert("Please check your mail");
    setisLoding(false);
  };
  const sendOtp = async (e) => {
    const data = {
      multiplier: methods.getValues("Quantity"),
      otp: enteredotp,
      strategy_name: popupData?.packageName,
      duration: `${props.month}M`,
    };
    let res = await instance
      .post(`api/user/v2/verify_agreement/`, data)
      .then((res) => {
        toast({
          title: res?.data.message,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        setOTP(false);
        setisPrice(true);
      })
      .catch((err) => {
        setsubmitLoading(false);
        toast({
          title: "Invalid Otp or Request failed with status code 400",
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      });
    subscriptionRequest();
    formData.set(
      "risk_profile",
      score < 3 ? "Low" : score < 6 ? " Moderate" : "High"
    );
    updateProfile(formData);
  };

  const subscriptionRequest = async (data) => {
    if (1 == 1) {
      await fetch(
        "https://arjunbulleyes.compoundingwealth.co.in/api/v1/create-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip",
          },
          body: JSON.stringify({
            email: "amits.keev@gmail.com",
            secret_key: "secret333",
            name: names,
            keev_email_id: email,
            keev_secret_id: secretkey,
            portfolio_name: `${popupData?.packageName}-${methods.getValues(
              "Quantity"
            )}`,
            //variant: props?.data.day,
            amount_allocated: 1.1,
            quantity_allocated: parseInt(methods.getValues("Quantity")),
            max_loss: 1.1,
            tp: "na",
            sl: "na",
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          toast({
            title: data?.data,
            status: "info",
            position: "top-right",
            isClosable: true,
          });
        });
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    if (keyValue > 3) {
      event.preventDefault();
      toast({
        title: "Quantity Not Multiplayed by grater then 3",
        status: "error",
        position: "top-right",
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Button
        bg={"#01a2bb"}
        color={"white"}
        _hover={{ backgroundColor: "#07798a", color: "white" }}
        onClick={onOpen}
        p={7}
      >
        {(popupData?.packageName === "Infinity Option" || popupData?.packageName === "Infinity Intraday Option"  )? (
         <p style={{ fontSize: "18px" }}>
          Subscribe <br />{" "}
          <span style={{ fontSize: "12px" }}>Fee as per performance</span>
        </p>
        ) : popupData?.packageName === "Infinity Mini" ? (
          props.month == 1 ? (
            <p style={{ fontSize: "18px" }}>
              Rs. 3,000 <br />{" "}
              <span style={{ fontSize: "12px" }}>for 1 month</span>
            </p>
          ) : (
            <p style={{ fontSize: "18px" }}>
              Rs. 15,000 <br />
              <span style={{ fontSize: "12px" }}> for 6 month</span>
            </p>
          )
        ) : props.month == 1 ? (
          <p style={{ fontSize: "18px" }}>
            Rs. 5,000 <br />
            <span style={{ fontSize: "12px" }}> for 1 month</span>
          </p>
        ) : (
          <p style={{ fontSize: "18px" }}>
            Rs. 25,000 <br />
            <span style={{ fontSize: "12px" }}> for 6 month</span>
          </p>
        )}
      </Button>

      <Modal
        isOpen={isOpen}
        size={subscription || isPrice ? "3xl" : "lg"}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          {!subscription ? (
            <>
              {popupData?.packageName === "Infinity Mini" ||
              popupData?.packageName === "Infinity Junior" ? (
                <>
                  <ModalHeader textAlign={"center"}>
                    <Center>
                      <Image
                        src={Logo}
                        height="50px"
                        width={"50px"}
                        borderRadius={"lg"}
                      />{" "}
                    </Center>

                    <Text fontSize={"25px"}>
                      {" "}
                      {result || !questionnaire
                        ? "Your Risk Factor"
                        : isPrice
                        ? ""
                        : "Sign In Agreement"}
                    </Text>
                    <Text
                      fontSize={isPrice ? "25px" : "15px"}
                      marginTop={isPrice && "5"}
                    >
                      {" "}
                      {result
                        ? "Click on Next to read Terms & Conditions"
                        : isPrice
                        ? "Please select the plan"
                        : questionnaire
                        ? "To confirm your details & terms of services"
                        : ""}
                    </Text>
                  </ModalHeader>
                  <ModalCloseButton />
                  {!isPrice ? (
                    <>
                      <ModalBody>
                        <>
                          {!questionnaire && (
                            <>
                              {
                                <>
                                  <Text>{questions[index_main].content}</Text>
                                  {questions[index_main].options.map(
                                    (ele, index) => {
                                      return (
                                        <>
                                          <Box
                                            as={"button"}
                                            _hover={{
                                              bg: "#d2eaf7",
                                              boxShadow: "inner",
                                            }}
                                            onClick={() => {
                                              handleQuestion(index);
                                            }}
                                            margin={"5px"}
                                            h={"35px"}
                                            w="100%"
                                            bg={"blue.50"}
                                            boxShadow={"md"}
                                            borderRadius={"md"}
                                          >
                                            <Text
                                              align={"left"}
                                              paddingLeft={"10px"}
                                              paddingTop={"5px"}
                                            >
                                              {options[index]}. {ele}
                                            </Text>
                                          </Box>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              }
                            </>
                          )}
                          {result && (
                            <>
                              <Stack flexDirection={"column"}>
                                <Center>
                                  <Box w={"250px"} h={"200px"}>
                                    <Doughnut
                                      data={data}
                                      options={options_doughnut}
                                    />
                                    <Text
                                      position={"absolute"}
                                      top={
                                        score < 3 || score < 6 ? "55%" : "65%"
                                      }
                                      left={"50%"}
                                      transform={"translate(-50%, -50%)"}
                                      textAlign={"center"}
                                      fontSize={"48px"}
                                      fontWeight={"700"}
                                    >
                                      {score}{" "}
                                    </Text>
                                  </Box>
                                </Center>
                              </Stack>
                              <Stack>
                                <Center>
                                  <Text as={"span"}>Your Risk Factor is </Text>

                                  <Text
                                    marginLeft={2}
                                    fontSize={"xl"}
                                    color={
                                      score < 3
                                        ? "#55DC16"
                                        : score < 6
                                        ? "#F5AB0C"
                                        : "#FF0000"
                                    }
                                  >
                                    {" "}
                                    {score < 3
                                      ? " Low"
                                      : score < 6
                                      ? " Moderate"
                                      : " High"}
                                  </Text>
                                </Center>
                              </Stack>
                              {score < 3 ||
                                (score < 6 && (
                                  <Stack>
                                    <Text
                                      color={"gray"}
                                      fontSize={"sm"}
                                      padding={"10px"}
                                    >
                                      {" "}
                                      Note:- As per your risk profile this suite
                                      is not favorable for you, but if you still
                                      want to continue please press 'NEXT' or
                                      else 'CANCEL'
                                    </Text>
                                  </Stack>
                                ))}
                            </>
                          )}

                          {!otp && questionnaire && !result && (
                            <>
                              <Box
                                borderRadius={"xl"}
                                as={"button"}
                                w={"100%"}
                                bgColor={"blue.50"}
                                padding={"15px"}
                                marginBottom={"15px"}
                                variant={"outline"}
                                onClick={() => {
                                  window.open(agreementPdf, "_blank");
                                }}
                              >
                                <Text fontSize={"18px"}>
                                  Read the Agreement
                                </Text>
                              </Box>
                              <Checkbox
                                onChange={() => {
                                  setAccepted(!accept);
                                }}
                                value="accepted"
                              >
                                I accept the agreement
                              </Checkbox>
                              <Stack marginTop={5}>
                                <Text> Quantity Multiplayed by</Text>
                                <FormNumberInput
                                  name="Quantity"
                                  control={methods.control}
                                  error={methods.formState.errors}
                                  maxLength={1}
                                  rules={{
                                    required: "This Field is required.",
                                  }}
                                  onKeyPress={handleKeyPress}
                                  onChangeNew={(e) => {
                                    if (e.target.value > 3) {
                                      toast({
                                        title:
                                          "Quantity Not Multiplayed by grater then 3",
                                        status: "error",
                                        position: "top-right",
                                        isClosable: true,
                                      });
                                    }
                                    methods.setValue(
                                      "Quantity",
                                      e.target.value
                                    );
                                    setqty(
                                      methods.getValues("Quantity") *
                                        (popupData?.packageName ===
                                        "Infinity Mini"
                                          ? 1100000
                                          : 2100000)
                                    );
                                  }}
                                />
                              </Stack>
                              <Text fontSize={"sm"}>
                                {" "}
                                Note:-Margin required as per your selection is
                                Rs.{" "}
                                {qty
                                  ? qty
                                  : popupData?.packageName === "Infinity Mini"
                                  ? 1100000
                                  : 2100000}
                              </Text>
                            </>
                          )}

                          <>
                            {otp &&
                              (!isLoding ? (
                                <Stack spacing={"5px"} m={"5px"}>
                                  <Text fontSize={"18px"} fontWeight={500}>
                                    Enter OTP
                                  </Text>
                                  <HStack
                                    marginTop={"10px"}
                                    justifyContent={"space-between"}
                                  >
                                    <PinInput
                                      size={"lg"}
                                      otp
                                      placeholder=""
                                      onChange={(e) => setEnteredOTP(e)}
                                    >
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                      <PinInputField />
                                    </PinInput>
                                  </HStack>
                                </Stack>
                              ) : (
                                <Stack display={"flex"} alignItems={"center"}>
                                  <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                  />
                                </Stack>
                              ))}
                          </>
                        </>
                      </ModalBody>
                    </>
                  ) : popupData?.packageName === "Infinity Mini" ? (
                    <>
                      <ModalBody>
                        <Stack
                          justifyContent={"center"}
                          width={"100%"}
                          alignItems={"center"}
                          flexDirection={"row"}
                        >
                          <VStack mr={10}>
                            <Text>One Month</Text>
                            <Button
                              bg={"#01a2bb"}
                              color={"white"}
                              _hover={{
                                backgroundColor: "#07798a",
                                color: "white",
                              }}
                              onClick={() =>
                                window.open(
                                  "https://pages.razorpay.com/pl_Lo02LDSl6RKBFI/view"
                                )
                              }
                              width={"175px"}
                            >
                              Pay Rs.3,000
                            </Button>
                          </VStack>

                          <VStack marginTop={"0 !important"}>
                            <Text>Six Month</Text>
                            <Button
                              bg={"#01a2bb"}
                              color={"white"}
                              _hover={{
                                backgroundColor: "#07798a",
                                color: "white",
                              }}
                              width={"175px"}
                              onClick={() =>
                                window.open(
                                  "https://pages.razorpay.com/pl_Lo0BOUaopn8MSe/view"
                                )
                              }
                            >
                              Pay Rs.15,000
                            </Button>
                          </VStack>
                        </Stack>
                      </ModalBody>
                    </>
                  ) : (
                    <>
                      <ModalBody>
                        <Flex
                          minWidth="max-content"
                          alignItems="center"
                          justifyContent={"center"}
                          gap="10"
                        >
                          <VStack>
                            <Text>One Month</Text>
                            <Button
                              bg={"#01a2bb"}
                              color={"white"}
                              width={"175px"}
                              _hover={{
                                backgroundColor: "#07798a",
                                color: "white",
                              }}
                              onClick={() =>
                                window.open(
                                  "https://pages.razorpay.com/pl_Lo0FCJzbcjNxMR/view"
                                )
                              }
                            >
                              Pay Rs.5,000
                            </Button>
                          </VStack>

                          <VStack>
                            <Text>Six Month</Text>
                            <Button
                              bg={"#01a2bb"}
                              color={"white"}
                              width={"175px"}
                              _hover={{
                                backgroundColor: "#07798a",
                                color: "white",
                              }}
                              onClick={() =>
                                window.open(
                                  "https://pages.razorpay.com/pl_Lo0J2Bk9M9y8VN/view"
                                )
                              }
                            >
                              Pay Rs.25,000
                            </Button>
                          </VStack>
                        </Flex>
                      </ModalBody>
                    </>
                  )}
                  <ModalFooter>
                    {(!questionnaire || result) && (
                      <>
                        <Button
                          color={"#00a3be"}
                          display={index_main < 3 ? "none" : "block"}
                          onClick={() => {
                            window.location.reload(false);
                          }}
                          marginRight={2}
                          bg={"white"}
                          _hover={{ bg: "white" }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          colorScheme="blue"
                          isDisabled={index_main < 2}
                          onClick={() => {
                            setResult(false);
                          }}
                        >
                          Next
                        </Button>
                      </>
                    )}
                    {!otp && questionnaire && !result && !isPrice && (
                      <Button
                        colorScheme="blue"
                        isDisabled={!accept}
                        onClick={(e) => {
                          getOtp(e);
                          setOTP(true);
                        }}
                      >
                        Send OTP
                      </Button>
                    )}
                    {otp && (
                      <>
                        <Button
                          color="#01a2bb"
                          bg={"white"}
                          onClick={(e) => {
                            getOtp(e);
                            setOTP(true);
                            setisLoding(true);
                          }}
                        >
                          {submitLoading ? (
                            <Spinner
                              // thickness="4px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                            />
                          ) : (
                            "Re-Send OTP"
                          )}
                        </Button>

                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            sendOtp();
                            setsubmitLoading(true);
                          }}
                          marginLeft={3}
                        >
                          {submitLoading ? (
                            <Spinner
                              // thickness="4px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                            />
                          ) : (
                            "Check OTP"
                          )}
                        </Button>
                      </>
                    )}
                  </ModalFooter>
                </>
              ) : (
                <SubscribeDetails />
              )}
            </>
          ) : (
            <>
              <ModalHeader textAlign={"center"} marginTop={7} marginBottom={7}>
                <Text fontSize={"25px"}>Hi Investors !!</Text>
                <Text marginTop={2}>
                  You are already subscribed. Thank you.
                </Text>
                <Text>
                  In case of any queries, please contact
                  info@compoundingwealth.co.in
                </Text>

                <Text fontSize={"15px"}>
                  {" "}
                  {result
                    ? "Click on Next to read Terms & Conditions"
                    : questionnaire
                    ? "To confirm your details & terms of services"
                    : ""}{" "}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Subscribe;
