import { Icon } from "@chakra-ui/react";
import React from "react";

const NotificationIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "17px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 20"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9637 7.88516C14.4222 7.88516 14.8662 7.82441 15.288 7.71062L15.6298 10.683C15.7155 11.4286 16.0223 12.1303 16.5095 12.6952C17.5434 13.8941 16.7042 15.7705 15.1342 15.7705H2.78853C1.21846 15.7705 0.37933 13.8941 1.41323 12.6952C1.90043 12.1303 2.20718 11.4286 2.29292 10.683L2.63796 7.68215C3.01232 4.42633 5.73074 1.97119 8.96134 1.97119C8.9947 1.97119 9.028 1.97145 9.06125 1.97197C8.99573 2.29021 8.96134 2.61957 8.96134 2.95685C8.96134 5.67868 11.201 7.88516 13.9637 7.88516ZM11.9347 17.8123C11.4771 18.9238 10.3183 19.7131 8.96115 19.7131C7.60397 19.7131 6.44521 18.9238 5.98762 17.8123C5.96855 17.7659 5.95972 17.7162 5.95972 17.6662C5.95972 17.4358 6.14932 17.249 6.38321 17.249H11.5391C11.773 17.249 11.9626 17.4358 11.9626 17.6662C11.9626 17.7162 11.9538 17.7659 11.9347 17.8123Z"
        fill="#888888"
      />
      <ellipse cx="13.9638" cy="3" rx="3.00143" ry="3" fill="#00a3be" />
    </Icon>
  );
};

export default NotificationIcon;
