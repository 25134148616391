import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "utils/interceptors";

const initialState = {
  indicators: [],
  comparators: [],
  assistData: [],
};

export const getIndicators = createAsyncThunk(
  "indicator/getIndicators",
  async (x, { rejectWithValue }) => {
    try {
      const res = await instance.get("/api/strategy/v2/indicator/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getComparators = createAsyncThunk(
  "indicator/getComparators",
  async (x, { rejectWithValue }) => {
    try {
      const res = await instance.get("/api/strategy/v2/comparator/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getAssistData = createAsyncThunk(
  "indicator/getAssistData",
  async (x, { rejectWithValue }) => {
    try {
      const res = await instance.get("/api/strategy/v2/assist/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const indicatorSlice = createSlice({
  name: "indicator",
  initialState,
  reducers: {},
  extraReducers: {
    [getIndicators.fulfilled]: (state, action) => {
      let arrIndiCom = [];
      action.payload.map((item) => {
        return arrIndiCom.push({
          key: `${item.id}-${item.name}`,
          label: item.field.name,
          name: item.name,
          value: item.id,
          data: item.field.allowed_comparison,
          syntax: item.field.syntax,
          tooltip: item.field.tooltip,
          description: item.field.description,
          link: item.field.link,
          allowed_comparison: item.field.allowed_comparison,
          function_group: item.field.function_group,
          params: item.field.params,
        });
      });
      return {
        ...state,
        indicators: arrIndiCom,
      };
    },
    [getComparators.fulfilled]: (state, action) => {
      const rightIndicatorData = action.payload.map((right, index) => {
        return {
          key: `${right.id}-${index}`,
          id: right.id,
          label: right.field.name,
          field: right.field,
          value: right.id,
        };
      });
      return {
        ...state,
        comparators: rightIndicatorData,
      };
    },
    [getAssistData.fulfilled]: (state, action) => {
      return {
        ...state,
        assistData: action.payload,
      };
    },
  },
});

export default indicatorSlice.reducer;
