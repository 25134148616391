import { Icon } from "@chakra-ui/react";
import React from "react";

const StrategyInactiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      _hover={hover}
      role="img"
      {...rest}
    >
      <path
        d="M22.75 10C22.75 9.58579 22.4142 9.25 22 9.25C21.5858 9.25 21.25 9.58579 21.25 10H22.75ZM14 2.75C14.4142 2.75 14.75 2.41421 14.75 2C14.75 1.58579 14.4142 1.25 14 1.25V2.75ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75V11.25ZM19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25V12.75ZM18 21.25H6V22.75H18V21.25ZM2.75 18V6H1.25V18H2.75ZM22.75 18V10H21.25V18H22.75ZM6 2.75H14V1.25H6V2.75ZM6 21.25C4.20507 21.25 2.75 19.7949 2.75 18H1.25C1.25 20.6234 3.37665 22.75 6 22.75V21.25ZM18 22.75C20.6234 22.75 22.75 20.6234 22.75 18H21.25C21.25 19.7949 19.7949 21.25 18 21.25V22.75ZM2.75 6C2.75 4.20507 4.20507 2.75 6 2.75V1.25C3.37665 1.25 1.25 3.37665 1.25 6H2.75ZM5 12.75H6V11.25H5V12.75ZM8.75 10V9H7.25V10H8.75ZM11.25 9V15H12.75V9H11.25ZM16.75 15V14H15.25V15H16.75ZM18 12.75H19V11.25H18V12.75ZM16.75 14C16.75 13.3096 17.3096 12.75 18 12.75V11.25C16.4812 11.25 15.25 12.4812 15.25 14H16.75ZM14 17.75C15.5188 17.75 16.75 16.5188 16.75 15H15.25C15.25 15.6904 14.6904 16.25 14 16.25V17.75ZM11.25 15C11.25 16.5188 12.4812 17.75 14 17.75V16.25C13.3096 16.25 12.75 15.6904 12.75 15H11.25ZM10 7.75C10.6904 7.75 11.25 8.30964 11.25 9H12.75C12.75 7.48122 11.5188 6.25 10 6.25V7.75ZM8.75 9C8.75 8.30964 9.30964 7.75 10 7.75V6.25C8.48122 6.25 7.25 7.48122 7.25 9H8.75ZM6 12.75C7.51878 12.75 8.75 11.5188 8.75 10H7.25C7.25 10.6904 6.69036 11.25 6 11.25V12.75ZM21.25 4.5C21.25 5.4665 20.4665 6.25 19.5 6.25V7.75C21.2949 7.75 22.75 6.29493 22.75 4.5H21.25ZM19.5 6.25C18.5335 6.25 17.75 5.4665 17.75 4.5H16.25C16.25 6.29493 17.7051 7.75 19.5 7.75V6.25ZM17.75 4.5C17.75 3.5335 18.5335 2.75 19.5 2.75V1.25C17.7051 1.25 16.25 2.70507 16.25 4.5H17.75ZM19.5 2.75C20.4665 2.75 21.25 3.5335 21.25 4.5H22.75C22.75 2.70507 21.2949 1.25 19.5 1.25V2.75Z"
        fill={color}
      />
    </Icon>
  );
};

export default StrategyInactiveIcon;
