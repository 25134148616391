import instance from "utils/interceptors";

export const userOrders = async (activePage = 1, skipNumber = 10) => {
  try {
    let res = await instance.get(`/api/live-trade/v2/order_book/?page=${activePage}&page_size=${skipNumber}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const openPositions = async (activePage = 1, skipNumber = 10) => {
  try {
    let res = await instance.get(`/api/live-trade/v2/open_position/?page=${activePage}&page_size=${skipNumber}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const ordersInBroker = async (data) => {
  try {
    let res = await instance.post(`api/broker/v2/user_common_format_order_history/`,data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const userOrdersVirtual = async (activePage = 1, skipNumber = 10) => {
  try {
    let res = await instance.get(`api/paper-trade/v2/papertrade-order-book/?page=${activePage}&page_size=${skipNumber}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const openPositionsVirtual = async (activePage = 1, skipNumber = 10) => {
  try {
    let res = await instance.get(
      `api/paper-trade/v2/papertrade-open-position/?page=${activePage}&page_size=${skipNumber}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getOrderCount = async () => {
  try {
    let res = await instance.get("/api/broker/v2/order-data/");
    return res.data;
  } catch (error) {
    throw error;
  }
};


  export const offFromKeev = async (data) => {
    try {
      const res = await instance.post(
        `api/live-trade/v2/manual_squareoff/`,
        data
      );
      return res;
    } catch (error) {
      // console.log(error)
      return error;
    }
  };
  export const virtualoffFromKeev = async (data) => {
    try {
      const res = await instance.post(
        `api/paper-trade/v2/manual_squareoff/`,
        data
      );
      return res;
    } catch (error) {
      // console.log(error)
      return error;
    }
  };
  
  export const actualSquareOffFromBroker   = async (data) => {
    try {
      const res = await instance.post(
        `api/live-trade/v2/squareoff/`,
        data
      );
      return res;
    } catch (error) {
      // console.log(error)
      return error;
    }
  };

  export const offFromBroker = async (data) => {
    try {
      const res = await instance.post(
        `api/live-trade/v2/squareoff/`,
        data
      );
      return res;
    } catch (error) {
      // console.log(error)
      return error;
    }
  };
