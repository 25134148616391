import { Icon } from "@chakra-ui/react";
import React from "react";

const OrdersIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4.37808C9.13095 2.92228 6.18953 2.25933 3.99433 2.01127C2.89642 1.88721 2 2.80402 2 3.93518V15.2C2 16.3311 2.89642 17.2483 3.99433 17.3723C6.18953 17.6204 9.13095 18.2833 11.25 19.7391V4.37808ZM12.75 19.7391C14.869 18.2833 17.8105 17.6204 20.0057 17.3723C21.1036 17.2483 22 16.3311 22 15.2V3.93518C22 2.80402 21.1036 1.88721 20.0057 2.01127C17.8105 2.25933 14.869 2.92228 12.75 4.37808V19.7391ZM4.25882 7.13996C4.32221 6.73063 4.70543 6.45019 5.11477 6.51358C6.41754 6.71534 7.88469 7.05257 9.27049 7.58846C9.65682 7.73786 9.8489 8.17215 9.6995 8.55849C9.55011 8.94482 9.11581 9.1369 8.72948 8.9875C7.47083 8.50078 6.11398 8.18621 4.8852 7.99591C4.47587 7.93252 4.19543 7.5493 4.25882 7.13996ZM5.11477 10.5136C4.70543 10.4502 4.32221 10.7306 4.25882 11.14C4.19543 11.5493 4.47587 11.9325 4.8852 11.9959C5.50444 12.0918 6.15812 12.2196 6.81651 12.3857C7.21814 12.487 7.62587 12.2436 7.7272 11.8419C7.82853 11.4403 7.58509 11.0326 7.18346 10.9313C6.47445 10.7524 5.7745 10.6158 5.11477 10.5136Z"
        fill={color}
      />
    </Icon>
  );
};

export default OrdersIcon;
