import { Icon } from "@chakra-ui/react";
import React from "react";

const ScannerInactiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      _hover={hover}
      role="img"
      {...rest}
    >
      <path
        d="M15.4444 0.25C15.0302 0.25 14.6944 0.585786 14.6944 1C14.6944 1.41421 15.0302 1.75 15.4444 1.75V0.25ZM6.55556 1.75C6.96977 1.75 7.30556 1.41421 7.30556 1C7.30556 0.585786 6.96977 0.25 6.55556 0.25V1.75ZM20.25 6.55556C20.25 6.96977 20.5858 7.30556 21 7.30556C21.4142 7.30556 21.75 6.96977 21.75 6.55556H20.25ZM21.75 15.4444C21.75 15.0302 21.4142 14.6944 21 14.6944C20.5858 14.6944 20.25 15.0302 20.25 15.4444H21.75ZM15.4444 20.25C15.0302 20.25 14.6944 20.5858 14.6944 21C14.6944 21.4142 15.0302 21.75 15.4444 21.75V20.25ZM6.55556 21.75C6.96977 21.75 7.30556 21.4142 7.30556 21C7.30556 20.5858 6.96977 20.25 6.55556 20.25V21.75ZM0.25 6.55556C0.25 6.96977 0.585786 7.30556 1 7.30556C1.41421 7.30556 1.75 6.96977 1.75 6.55556H0.25ZM1.75 15.4444C1.75 15.0302 1.41421 14.6944 1 14.6944C0.585786 14.6944 0.25 15.0302 0.25 15.4444H1.75ZM15.4444 1.75H17V0.25H15.4444V1.75ZM5 1.75H6.55556V0.25H5V1.75ZM20.25 5V6.55556H21.75V5H20.25ZM20.25 15.4444V17H21.75V15.4444H20.25ZM17 20.25H15.4444V21.75H17V20.25ZM6.55556 20.25H5V21.75H6.55556V20.25ZM1.75 6.55556V5H0.25V6.55556H1.75ZM1.75 17V15.4444H0.25V17H1.75ZM5 20.25C3.20507 20.25 1.75 18.7949 1.75 17H0.25C0.25 19.6234 2.37665 21.75 5 21.75V20.25ZM20.25 17C20.25 18.7949 18.7949 20.25 17 20.25V21.75C19.6234 21.75 21.75 19.6234 21.75 17H20.25ZM17 1.75C18.7949 1.75 20.25 3.20507 20.25 5H21.75C21.75 2.37665 19.6234 0.25 17 0.25V1.75ZM5 0.25C2.37665 0.25 0.25 2.37665 0.25 5H1.75C1.75 3.20507 3.20507 1.75 5 1.75V0.25ZM15.25 11C15.25 13.3472 13.3472 15.25 11 15.25V16.75C14.1756 16.75 16.75 14.1756 16.75 11H15.25ZM11 15.25C8.65279 15.25 6.75 13.3472 6.75 11H5.25C5.25 14.1756 7.82436 16.75 11 16.75V15.25ZM6.75 11C6.75 8.65279 8.65279 6.75 11 6.75V5.25C7.82436 5.25 5.25 7.82436 5.25 11H6.75ZM11 6.75C13.3472 6.75 15.25 8.65279 15.25 11H16.75C16.75 7.82436 14.1756 5.25 11 5.25V6.75Z"
        fill={color}
      />
    </Icon>
  );
};

export default ScannerInactiveIcon;
