import React from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
   
  } from "@chakra-ui/react";
function InfoItem() {
  return (
    <Accordion defaultIndex={[0]}>
    <AccordionItem
      bg="white"
      _hover={{
        bg: "white",
      }}
      border="none"
    >
      <h2>
        <AccordionButton
          bg="white"
          _hover={{
            bg: "white",
          }}
        >
          <Box flex="1" textAlign="left">
            What is Algorithmic Trading Strategy?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        A 'Strategy' is a combination of various conditions that define
        when to buy or sell a particular Trading Symbol on predefined
        algorithms to trade. For every trading strategy, one needs to
        define assets to trade, entry/exit points, and money{" "}
        <Box
          onClick={() =>
            window.open(
              "https://support.keev.tech/portal/en/kb/articles/what-is-algorithm-strategy"
            )
          }
          as="span"
          ml="4px"
          color="#00a3be"
          cursor="pointer"
        >
          read more
        </Box>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem
      bg="white"
      _hover={{
        bg: "white",
      }}
      border="none"
      mt="4"
    >
      <h2>
        <AccordionButton
          bg="white"
          _hover={{
            bg: "white",
          }}
        >
          <Box flex="1" textAlign="left">
            How to Create Algorithm Trading Strategy?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        The Keev Strategy Builder is a simple as selecting technical
        indicators, keying in stop loss and target profit percentage and
        selecting the stocks you want to trade with that enables users
        to create relatively complex strategies without programming{" "}
        <Box
          onClick={() =>
            window.open(
              "https://support.keev.tech/portal/en/kb/articles/how-to-create-algorithm-trading-strategy"
            )
          }
          as="span"
          ml="4px"
          color="#00a3be"
          cursor="pointer"
        >
          read more
        </Box>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
  )
}

export default InfoItem