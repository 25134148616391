import { Icon } from "@chakra-ui/react";
import React from "react";

const ChartingToolIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      _hover={hover}
      {...rest}
    >
      <path
        d="M5 1H17C19.2091 1 21 2.79086 21 5V17C21 19.2091 19.2091 21 17 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1Z"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect x="5" y="14" width="1.5" height="4" rx="0.75" fill="#888888" />
      <rect x="10" y="11" width="1.5" height="7" rx="0.75" fill="#888888" />
      <rect x="15" y="8" width="1.5" height="10" rx="0.75" fill="#888888" />
      <path
        d="M4.35815 10.626C4.15159 10.7043 4.04765 10.9353 4.126 11.1419C4.20434 11.3484 4.4353 11.4523 4.64185 11.374L4.35815 10.626ZM16 3L11.546 4.22285L14.832 7.46869L16 3ZM4.64185 11.374C8.56928 9.8844 10.9847 8.43705 13.7438 5.8529L13.1969 5.269C10.5243 7.77213 8.20215 9.16803 4.35815 10.626L4.64185 11.374Z"
        fill="#888888"
      />
    </Icon>
  );
};

export default ChartingToolIcon;
