import { Icon } from "@chakra-ui/react";
import React from "react";

const StrategiesVirtualIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      role="img"
      {...rest}
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V13.25H2V5ZM2 14.75H22V15C22 16.6569 20.6569 18 19 18H12.75V20.5H15C15.4142 20.5 15.75 20.8358 15.75 21.25C15.75 21.6642 15.4142 22 15 22H9C8.58579 22 8.25 21.6642 8.25 21.25C8.25 20.8358 8.58579 20.5 9 20.5H11.25V18H5C3.34315 18 2 16.6569 2 15V14.75Z" fill={color}/>
        <path d="M6 10.001L10 6.00098L14 10.001L19 6.00098" stroke={color}/>

    </Icon>
  );
};

export default StrategiesVirtualIcon;
