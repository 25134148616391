import instance from "utils/interceptors";

export const getMyAstraDetails = async () => {
  try {
    const res = await instance.get("api/strategy/v2/public/");
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getMyStrategyDetails = async () => {
  try {
    const res = await instance.get("/api/strategy/v2/");
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getMyStrategyTableData = async (strategy_id) => {
  try {
    const res = await instance.get(`api/backtest/v2/view_list/${strategy_id}/`);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getSymbolData = async (symbol) => {
  try {
    const res = await instance.get(
      `api/scrip/symbols/?limit=25&search=${symbol}`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getModalData = async (id , isAstra) => {
  try {
    let url  = isAstra ? "api/strategy/v2/public/" : "api/strategy/v2/" ;
    const res = await instance.get(url + id + '/');
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getVirtualModalQuantityData = async (strategyId, scripId) => {
  try {
    const res = await instance.get(
      `api/paper-trade/v2/values/${strategyId}/${scripId}`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const virtualModalDataPost = async (strategyId, scripId, data) => {
  const res = await instance.post(
    `api/paper-trade/v2/run/${strategyId}/${scripId}/`,
    data
  );
  return res.data;
};

export const liveModalDataPost = async (strategyId, scripId, data) => {
  const res = await instance.post(
    `api/live-trade/v2/run/${strategyId}/${scripId}/`,
    data
  );
  return res.data;
};

export const getActiveBroker = async (broker = true) => {
  if(broker)
  {try {

    const res = await instance.get(`api/broker/v2/active_broker/`);
    return res.data;
  } catch (error) {
    return error.response;
  }}
};

export const getLiveModalQuantityData = async (strategyId, scripId) => {
  try {
    const res = await instance.get(
      `api/live-trade/v2/values/${strategyId}/${scripId}/`
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getOptimiseData = async (strategyId) => {
  try {
    const res = await instance.get(`api/backtest/v2/getValues/${strategyId}`);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const optimiseModalDataPost = async (strategyId, scripId, data) => {
  const res = await instance.post(
    `api/optimisation/v2/run/${strategyId}/${scripId}/`,
    data
  );
  return res.data;
};

export const detailsStrategy = async (type , id) =>{
  try {
    const res = await instance.get(
    `api/broker/v2/view_${type}_order/${id}/`
  );
  return res.data;
    }
    catch (error) {
      return error.response
    }
}

export const detailsStrategyForLiveOption = async (id) =>{
  try {
    const res = await instance.get(
    `api/live-trade/v2/view_option_order/${id}/`
  );
  return res.data;
    }
    catch (error) {
      return error.response
    }
}

export const detailsStrategyForVirtualOption = async (id) =>{
  try {
    const res = await instance.get(
    `api/paper-trade/v2/view_option_order/${id}/`
  );
  return res.data;
    }
    catch (error) {
      return error.response
    }
}