import { Icon } from "@chakra-ui/react";
import React from "react";

const DashboardInactiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      preserveAspectRatio="xMidYMid meet"
      role="img"
      aria-hidden="true"
      _hover={hover}
      {...rest}
    >
      <path
        d="M3 1.75H7V0.25H3V1.75ZM8.25 3V7H9.75V3H8.25ZM7 8.25H3V9.75H7V8.25ZM1.75 7V3H0.25V7H1.75ZM3 8.25C2.30964 8.25 1.75 7.69036 1.75 7H0.25C0.25 8.51878 1.48122 9.75 3 9.75V8.25ZM8.25 7C8.25 7.69036 7.69036 8.25 7 8.25V9.75C8.51878 9.75 9.75 8.51878 9.75 7H8.25ZM7 1.75C7.69036 1.75 8.25 2.30964 8.25 3H9.75C9.75 1.48122 8.51878 0.25 7 0.25V1.75ZM3 0.25C1.48122 0.25 0.25 1.48122 0.25 3H1.75C1.75 2.30964 2.30964 1.75 3 1.75V0.25ZM20.25 5C20.25 6.79493 18.7949 8.25 17 8.25V9.75C19.6234 9.75 21.75 7.62335 21.75 5H20.25ZM17 8.25C15.2051 8.25 13.75 6.79493 13.75 5H12.25C12.25 7.62335 14.3766 9.75 17 9.75V8.25ZM13.75 5C13.75 3.20507 15.2051 1.75 17 1.75V0.25C14.3766 0.25 12.25 2.37665 12.25 5H13.75ZM17 1.75C18.7949 1.75 20.25 3.20507 20.25 5H21.75C21.75 2.37665 19.6234 0.25 17 0.25V1.75ZM8.25 17C8.25 18.7949 6.79493 20.25 5 20.25V21.75C7.62335 21.75 9.75 19.6234 9.75 17H8.25ZM5 20.25C3.20507 20.25 1.75 18.7949 1.75 17H0.25C0.25 19.6234 2.37665 21.75 5 21.75V20.25ZM1.75 17C1.75 15.2051 3.20507 13.75 5 13.75V12.25C2.37665 12.25 0.25 14.3766 0.25 17H1.75ZM5 13.75C6.79493 13.75 8.25 15.2051 8.25 17H9.75C9.75 14.3766 7.62335 12.25 5 12.25V13.75ZM15 13.75H19V12.25H15V13.75ZM20.25 15V19H21.75V15H20.25ZM19 20.25H15V21.75H19V20.25ZM13.75 19V15H12.25V19H13.75ZM15 20.25C14.3096 20.25 13.75 19.6904 13.75 19H12.25C12.25 20.5188 13.4812 21.75 15 21.75V20.25ZM20.25 19C20.25 19.6904 19.6904 20.25 19 20.25V21.75C20.5188 21.75 21.75 20.5188 21.75 19H20.25ZM19 13.75C19.6904 13.75 20.25 14.3096 20.25 15H21.75C21.75 13.4812 20.5188 12.25 19 12.25V13.75ZM15 12.25C13.4812 12.25 12.25 13.4812 12.25 15H13.75C13.75 14.3096 14.3096 13.75 15 13.75V12.25Z"
        fill="#888888"
      />
    </Icon>
  );
};

export default DashboardInactiveIcon;
