import { Icon } from "@chakra-ui/react";
import React from "react";

const EditIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "15px"}
      height={height ? height : "15px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6883 0.677084C11.7855 -0.225695 10.3218 -0.225695 9.41904 0.677084L7.96974 2.12639C8.02608 2.24018 8.08701 2.35989 8.15237 2.48403C8.59218 3.31945 9.20947 4.31082 9.94934 5.05069C10.6892 5.79057 11.6806 6.40786 12.516 6.84767C12.6401 6.91302 12.7598 6.97394 12.8736 7.03028L14.3229 5.58096C15.2257 4.67818 15.2257 3.21449 14.3229 2.31171L12.6883 0.677084ZM11.7595 8.14441C10.8777 7.67594 9.7642 6.98687 8.88868 6.11135C8.01315 5.23582 7.32407 4.12234 6.8556 3.24053L1.15616 8.93996C0.802479 9.29364 0.57305 9.7525 0.502313 10.2477L0.0119258 13.6804C-0.0970492 14.4432 0.556801 15.097 1.31963 14.9881L4.75234 14.4977C5.2475 14.4269 5.70635 14.1975 6.06004 13.8438L11.7595 8.14441Z"
        fill="#888888"
      />
    </Icon>
  );
};

export default EditIcon;
