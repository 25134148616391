import { Icon } from "@chakra-ui/react";
import React from "react";

const ChartingToolInactiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      _hover={hover}
      {...rest}
    >
      <path
        d="M4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0Z"
        fill="#00a3be"
      />
      <rect x="4" y="13" width="1.5" height="4" rx="0.75" fill="white" />
      <rect x="9" y="10" width="1.5" height="7" rx="0.75" fill="white" />
      <rect x="14" y="7" width="1.5" height="10" rx="0.75" fill="white" />
      <path
        d="M3.35815 9.626C3.15159 9.70434 3.04765 9.9353 3.126 10.1419C3.20434 10.3484 3.4353 10.4523 3.64185 10.374L3.35815 9.626ZM15 2L10.546 3.22285L13.832 6.46869L15 2ZM3.64185 10.374C7.56928 8.8844 9.9847 7.43705 12.7438 4.8529L12.1969 4.269C9.52433 6.77213 7.20215 8.16803 3.35815 9.626L3.64185 10.374Z"
        fill="white"
      />
    </Icon>
  );
};

export default ChartingToolInactiveIcon;
