import { Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import MockUp from "assets/create-strategy.png";
import withRouter from "components/hoc/withRouter";
import routes from "utils/routes";

const CreateStrategyMockup = (props) => {
  return (
    <Stack
      bg="white"
      p={4}
      spacing={0}
      cursor="pointer"
      onClick={() => props?.router?.navigate(routes.optionStrategy)}
    >
      <Text color="brand.900" fontSize="14">
        Create
      </Text>
      <Text color="#141414" fontSize="18">
        Option Strategy
      </Text>
      <Image src={MockUp} mt="10px !important" Width="100%" maxHeight={"11.5rem"} />
    </Stack>
  );
};

export default withRouter(CreateStrategyMockup);
