import { request } from "../utils/interceptors";
// import { HandleNetworkError } from "../utils/common";

export function callApiToServer(endPoint, method, data) {
  return new Promise((resolve, reject) => {
    request(endPoint, method, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        //reject(err);
        if (err.response === undefined) {
          // <HandleNetworkError />;
          return;
        }
        reject(err);
      });
  });
}

export function callGetApiToServer(endPoint, method = "GET") {
  return new Promise((resolve, reject) => {
    request(endPoint, method, JSON.stringify({}))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response === undefined) {
          // <HandleNetworkError />;
          alert("network error");
          return;
        }
        reject(err);
      });
  });
}
