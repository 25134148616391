import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Stack,
  Tooltip,
  useToast,
  Box,
  Image,
} from "@chakra-ui/react";
import { createBasket, updateDeleteBasket } from "apis/basket";
import { getSymbols, searchSymbols } from "apis/symbol";
import FormButton from "controls/Button";
import FormInput from "controls/FormInput";
import FormSelect from "controls/FormSelect";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomForm from "utils/useForm";
import { components } from "chakra-react-select";
import ScripComponent from "components/elements/ScripComponent";
import Vector from "assets/Basket.svg";

const CreateUpdateBasket = (props) => {
  const {
    onClose,
    isOpen,
    isEditCopy,
    selectedBasket,
    setSelectedBasket,
    refreshData,
  } = props;
  const methods = useForm();
  const toast = useToast();
  const [optionsSymbols, setOptionsSymbols] = useState([]);
  const [search, setSearch] = useState("");
  const symbolCount = methods.getValues("scrips")?.length || 0;

  const symbols = selectedBasket?.scrips?.map((item) => {
    return {
      key: item.value,
      label: item.symbol,
      value: item.symbol,
      exchange: item.exchange,
      lot_size: item.lot_size,
    };
  });

  useEffect(() => {
    if (isOpen) {
      getSymbols()
        .then((res) => {
          setOptionsSymbols(
            res.map((item) => {
              return {
                key: item.id,
                label: item.symbol,
                value: item.symbol,
                exchange: item.exchange,
                lot_size: item.lot_size,
              };
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isOpen]);
  useEffect(() => {
    methods.reset({
      scrips: symbols,
      basket_name: isEditCopy === "copy" ? "" : selectedBasket?.basket_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  const formatOptionLabel = ({
    value,
    label,
    exchange,
    lot_size,
    ...props
  }) => {
    let segment_short =
      exchange === "NFO" ? "F" : exchange === "basket" ? "B" : "E";

    return (
      <Tooltip label={`Lot-size: ${lot_size}`} key={value}>
        <ScripComponent
          exchange={segment_short}
          name={`${label || props.symbol}-${exchange}`.toUpperCase()}
        />
      </Tooltip>
    );
  };
  const onCreateUpdateBasket = async (data) => {
    try {
      data["scrips"] = data?.scrips?.map((item) => {
        return {
          exchange: item.exchange,
          lot_size: item.lot_size,
          symbol: item.label,
          value: item.key,
        };
      });
      // eslint-disable-next-line
      const res =
        isEditCopy === "edit"
          ? await updateDeleteBasket("patch", selectedBasket?.id, data)
          : await createBasket(data);
      toast({
        title:
          isEditCopy === "edit"
            ? "Basket updated successfully"
            : "Basket created successfully",
        position: "top-right",
        status: "success",
        isClosable: true,
      });
    } catch (e) {
      console.log(e, "!!! Error in CreateUpdateBasket");
      toast({
        title: "Error creating basket",
        position: "top-right",
        status: "error",
        isClosable: true,
      });
    } finally {
      onClose();
      setSelectedBasket(null);
      methods.reset();
      refreshData();
    }
  };
  const onSearchSymbol = async (e) => {
    try {
      setSearch(e);
      if (e === "") return;
      let res = await searchSymbols(e);
      setOptionsSymbols(
        res.map((item) => {
          return {
            key: item.id,
            label: item.symbol,
            value: item.symbol,
            exchange: item.exchange,
            lot_size: item.lot_size,
          };
        })
      );
    } catch (error) {
      console.log(error, "error in searchSymbols");
    }
  };

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5;

  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 50 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Symbol limit is reached</div>
        )}
      </components.Menu>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setSelectedBasket(null);
      }}
      isCentered
      closeOnOverlayClick={false}
      size="xl"
      // scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <CustomForm
        methods={methods}
        onSubmit={methods.handleSubmit(onCreateUpdateBasket)}
      >
        <ModalContent minHeight={"calc(100% - 7.5rem)"}>
      
          <ModalHeader display={"flex"} alignItems="center"  borderBottom="2px solid #EDF1FF" justifyContent="start" gap="4" p={3}>
          <Box
            height="46px"
            width="46px"
            bg="#E4FDFF"
            borderRadius={'15px'}
            display="flex"
            alignItems={'center'}
            justifyContent="center"
          >
            <Image src={Vector} height="26px" width="24px" />
          </Box>
          <Stack spacing={0}>
          <Text fontSize={18} color="#141414" fontWeight={500}>

            {isEditCopy === "edit"
              ? "Edit Basket"
              : isEditCopy === "copy"
              ? "Copy Basket"
              : "Create Basket"}
          </Text>
                          <Text fontSize={14} fontWeight={500} color="#888888">
              Add Basket Name and Symbols then click on Add Button.
            </Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton mt="5px" border="1.5px solid #bebebe" color="#bebebe"/>
          <ModalBody>
            <Stack>
              <Text> Basket Name</Text>
              <FormInput
                // label="Basket Name"
                name="basket_name"
                placeholder="Basket Name"
                control={methods.control}
                error={methods.formState.errors}
                rules={{ required: "Basket Name is required" }}
              />
            </Stack>
            <Stack mt={4}>
              <Text>Select Symbols</Text>
              <FormSelect
                // label="Symbols"
                components={{ Menu }}
                name="scrips"
                placeholder="Symbols"
                control={methods.control}
                error={methods.formState.errors}
                options={optionsSymbols}
                isMulti
                formatOptionLabel={formatOptionLabel}
                rules={{ required: "Symbols is required" }}
                onInputChange={onSearchSymbol}
                myLabel={search}
                isValidNewOption={isValidNewOption}
              />
              <Text  textAlign={"right"}>
               <span style={{color:"#888888"}} >Remaining</span>  {50 - symbolCount} <span style={{color:"#888888"}} >out of 50</span> 
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter bg="#E4FDFF" gap={2}>
            <FormButton
              text="Back"
              bg="none"
              color="#888888"
              hover={"none"}
              onClick={() => {
                onClose();
                setSelectedBasket(null);
                methods.reset({
                  scrips: [],
                  basket_name: "",
                });
              }}
            />
            <FormButton text="Save" type="submit" />
          </ModalFooter>
        </ModalContent>
      </CustomForm>
    </Modal>
  );
};

export default CreateUpdateBasket;
