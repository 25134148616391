import { Icon } from "@chakra-ui/react";
import React from "react";

const OptionInactiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      _hover={hover}
      {...rest}
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="20"
        height="20"
        rx="3"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect x="5" y="8" width="2" height="10" rx="0.2" fill={color} />
      <rect x="10" y="8" width="2" height="10" rx="0.2" fill={color} />
      <rect x="15" y="10" width="2" height="8" rx="0.2" fill={color} />
      <path
        d="M5 5.1C4.77909 5.1 4.6 5.27909 4.6 5.5C4.6 5.72091 4.77909 5.9 5 5.9V5.1ZM17 5.5L13 3.1906V7.8094L17 5.5ZM5 5.9H13.4V5.1H5V5.9Z"
        fill={color}
      />
    </Icon>
  );
};

export default OptionInactiveIcon;
