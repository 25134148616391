const routes = {
  login: "/",
  signup: "/sign-up",
  signupId: "/sign-up/:id",
  forgotPassword: "/forgot-password",
  activationLink: "/resend-email",
  resetPassword: "/reset-password/:uidb64/:token",
  home: "/dashboard",
  stratezy: "/myStrategy",
  popular: "/ready-made",
  createStratezy: "/strategy",
  editStrategy: "edit/:id",
  copyStrategy: "copy/:id",
  optionStrategy: "/option-analyzer",
  virtual: "/virtual",
  live: "/live",
  methodology:"/methodology",
  orders: "/orders",
  advisors:"/advisors",
  openorders: "/openorders",
  notifications: "/notifications",
  backtest: "/backtest/:id",
  virtualInsight: "/virtual/:id",
  liveInsight: "/live/:id",
  optimised: "/optimised/:id",
  broker: "/broker",
  astra: "/astra",
  optimize: "/optimize",
  connectors: "/connectors",
  scanners: "/my-scanners",
  createScanner: "/scanners",
  editScanner: "edit/:id",
  copyScanner: "copy/:id",
  returns: "/returns",
  profile: "/profile",
  chartingTool: "/charting-tool",
  dailyUsage: "/daily-usage",
  pricing: "/pricing",
  join: "/join",
  refer: "/refer",
  tnc: "/terms",
  otpVerification: "/otp-verification",
  liveTerms: "/live-terms",
  terms: "/terms",
};
export default routes;
