import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "utils/interceptors";

const initialState = {
  showBanner: true,
  popularStrategies: [],
  userStrategies: [],
  badgeNumber: 0,
};

export const getUserStrategies = createAsyncThunk(
  "strategy/getUserStrategies",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await instance.get("/api/strategy/v2/user_strategy_details/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPopularStrategies = createAsyncThunk(
  "strategy/getPopularStrategies",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        "/api/strategy/v2/public_strategy_details/"
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    toggleBanner: (state) => {
      state.showBanner = !state.showBanner;
    },
    setBadgeNumber: (state, action) => {
      state.badgeNumber = action.payload;
    },
  },
  extraReducers: {
    [getUserStrategies.fulfilled]: (state, action) => {
      state.userStrategies = action.payload;
    },
    [getPopularStrategies.fulfilled]: (state, action) => {
      state.popularStrategies = action.payload;
    },
  },
});

export const { toggleBanner, setBadgeNumber } = dashboardSlice.actions;
export default dashboardSlice.reducer;
