import instance from "utils/interceptors";

export const getSymbols = async () => {
  try {
    let res = await instance.get("/api/scrip/symbols/?limit=25");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchSymbols = async (text) => {
  try {
    let res = await instance.get(`/api/scrip/symbols/?limit=25&search=${text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
