import { Switch, FormControl, FormErrorMessage } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const FormSwitch = (props) => {
  const {
    name,
    control,
    defaultValue,
    error,
    rules,
    size,
    onChangeNew,
    isIndeterminate,
    isChecked,
    defaultChecked,
    ...rest
  } = props;
  const methods = useFormContext();
  return (
    <FormControl isInvalid={error && error[name]} {...rest} width="auto">
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        rules={rules}
        render={({
          field: { onChange, onBlur, name: fieldName, value, ref },
        }) => (
          <Switch
            onChange={
              onChangeNew
                ? onChangeNew
                : (e) => {
                    methods.setValue(name, e.target.checked);
                    methods.clearErrors(name);
                  }
            }
            ref={ref}
            defaultChecked={defaultChecked}
            colorScheme={"brand"}
          />
        )}
      />
      {error && error[name] && (
        <FormErrorMessage>{error[name]?.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormSwitch;
