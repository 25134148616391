import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { updateProfile } from "apis/profile";
import FormButton from "controls/Button";
import FormInput from "controls/FormInput";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CustomForm from "utils/useForm";

const UserDetails = (props) => {
  const { isOpen, onClose } = props;
  const [formData] = useState(new FormData());
  const toast = useToast();

  const methods = useForm({});
  const sendUserDetails = async (values) => {
    formData.set( "pan_card",values?.pan);
    formData.set( "to_mail",values?.email );
    formData.set( "dob",values?.date );
    formData.set( "address",values?.address );
    formData.set( "state",values?.state );
    formData.set( "pincode",values?.pin );

      updateProfile(formData)
      .then((res) => {
        toast({
          title: res.message,
          status: "success",
          position: "top-right",
          isClosable: true,
        });
    onClose()
      })
      .catch((err) => {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          toast({
            title: value,
            status: "error",
            position: "top-right",
            isClosable: true,
          });
          methods.setError(key, {
            type: "required",
            message: err.response.data.errors[key][0],
          });
          return;
        }
      });
  };
  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"xl"}
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <CustomForm
          methods={methods}
          onSubmit={methods.handleSubmit(sendUserDetails)}
          autoComplete="off"
        >
          <ModalContent borderRadius={"4px"} overflow="hidden">
            <ModalHeader border={"2px solid #EDF1FF"}>
              <Text fontSize={18} color={"#888888"} fontWeight={800}>
                Please update the Details
              </Text>
            </ModalHeader>
            <ModalCloseButton mt="5px" border="1.5px solid #bebebe" />
            <ModalBody px={4}  p={7}>
            <Stack>
                <Text>Email</Text>
                <FormInput
                  name="email"
                  control={methods.control}
                  error={methods.formState.errors}
                  rules={{
                    required: "This field is required",
                    validate: (val) =>
                      /^\S+@\S+$/.test(val) ? null : "Enter a valid email",
                  }}
                />
              </Stack>
              <Stack mt={4}>
                <Text>Date of Birth</Text>
                <FormInput
                name="date"
                label="Date Picker"
                control={methods.control}
                error={methods.formState.errors}
                _placeholder={{
                  color: "#888888",
                }}
                color={methods.getValues("date") ? "#141414" : "gray.400"}
                type="date"
                pattern={"DD/MM/YYYY"}
              />
              </Stack>
              <Stack mt={4}>
                <Text>Enter PAN No</Text>
                <FormInput
                  name="pan"
                  // label="Connection Name"
                  control={methods.control}
                  error={methods.formState.errors}
                  onBlur={(e) => {
                    methods.setValue("pan", e.target.value.trim(), {
                      shouldValidate: true,
                    });
                  }}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 10,
                      message:
                        "Invalid PAN No.",
                    },
                    maxLength:{
                      value:10,
                      message:
                      "Invalid PAN No.",
                    }
                  }}
                />
              </Stack>
              
              <Stack mt={4}>
                <Text>Addres</Text>
                <FormInput
                  name="address"
                  // label="Connection Name"
                  control={methods.control}
                  error={methods.formState.errors}
                  onBlur={(e) => {
                    methods.setValue("address", e.target.value.trim(), {
                      shouldValidate: true,
                    });
                  }}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message:
                        "Connection Name should be more than 3 characters",
                    },
                  }}
                />
              </Stack>

              <Stack mt={4}>
                <HStack>
                <FormInput
                  name="pin"
                  label="Pin Code"
                  control={methods.control}
                  error={methods.formState.errors}
                  onBlur={(e) => {
                    methods.setValue("pin", e.target.value.trim(), {
                      shouldValidate: true,
                    });
                  }}
                  type={"number"}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 6,
                      message:
                        "Enter the correct Pin Code",
                    },
                    maxLength: {
                      value: 6,
                      message:
                      "Enter the correct Pin Code",
                    },
                  }}
                />
                 <FormInput
                  name="state"
                   label="State"
                  control={methods.control}
                  error={methods.formState.errors}
                  onBlur={(e) => {
                    methods.setValue("state", e.target.value.trim(), {
                      shouldValidate: true,
                    });
                  }}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message:
                        "Connection Name should be more than 3 characters",
                    },
                  }}
                />
                </HStack>
              </Stack>
              
            </ModalBody>
            <ModalFooter p={3} bg="#E4FDFF" gap={4} mt="4">
              <Button
                onClick={onClose}
                variant="link"
                style={{ textDecoration: "none" }}
              >
                Cancel
              </Button>
              <FormButton type="submit" text={"Submit"} />
            </ModalFooter>
          </ModalContent>
        </CustomForm>
      </Modal>
    </>
  );
};

export default UserDetails;
