import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "utils/interceptors";

const initialState = {
  liveEarning: 0,
  virtualEarning: 0,
};

export const getCount = createAsyncThunk(
  "count/getCount",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.get("/api/strategy/v2/total_count/");
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEarnings = createAsyncThunk(
  "count/getEarnings",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        "/api/paper-trade/v2/total_earning_cards/"
      );
      const res2 = await instance.get(
        "/api/live-trade/v2/total_earning_cards/"
      );

      return {
        virtual: res.data?.total_earnings,
        live: res2.data?.total_earnings,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const countSlice = createSlice({
  name: "count",
  initialState,
  reducers: {},
  extraReducers: {
    [getCount.fulfilled]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [getEarnings.fulfilled]: (state, action) => {
      return {
        ...state,
        liveEarning: action.payload.live,
        virtualEarning: action.payload.virtual,
      };
    },
  },
});

export default countSlice.reducer;
