import { Icon } from "@chakra-ui/react";
import React from "react";

const UserIcon = (props) => {
  const { color, height, width } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 21C15.866 21 19 19.2091 19 17C19 14.7909 15.866 13 12 13C8.13401 13 5 14.7909 5 17C5 19.2091 8.13401 21 12 21Z"
        fill={color || "#888888"}
      />
    </Icon>
  );
};

export default UserIcon;
