import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import dashboard from "./slices/dashboard";
import auth from "./slices/auth";
import strategy from "./slices/strategy";
import indicator from "./slices/indicator";
import banners from "./slices/banners";
import walkthrough from "./slices/walkthrough";

const reducers = combineReducers({
  dashboard,
  auth,
  // count,
  strategy,
  indicator,
  banners,
  walkthrough,
});

const persistConfig = {
  key: "root",
  storage,
};

const reducerProxy = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducerProxy);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: false,
});

export default store;
