import { Icon } from '@chakra-ui/react'
import React from 'react'

const VirtualIcon = (props) => {
  const { color, height, width } = props
  return (
    <Icon
      color={color}
      width={width ? width : '20px'}
      height={height ? height : '20px'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V11.25H0V3ZM0 12.75H20V13C20 14.6569 18.6569 16 17 16H10.75V18.5H13C13.4142 18.5 13.75 18.8358 13.75 19.25C13.75 19.6642 13.4142 20 13 20H7C6.58579 20 6.25 19.6642 6.25 19.25C6.25 18.8358 6.58579 18.5 7 18.5H9.25V16H3C1.34315 16 0 14.6569 0 13V12.75Z"
        fill={color || '#888888'}
      />
      <path d="M4 8.00098L8 4.00098L12 8.00098L17 4.00098" stroke="white" />
    </Icon>
  )
}

export default VirtualIcon
