import instance from "utils/interceptors";

export const getUserBalance = async () => {
  try {
    let res = await instance.get("/api/broker/v2/get-balance/");
    return res.data;
  } catch (error) {
    throw error;
  }
};
