import { Stack, Text } from "@chakra-ui/react";
import React from "react";

const Content = (props) => {
  return (
    <>
      <Stack>
        {props?.name === "Infinity Option" ? (
          <>
            {/* <Text
              style={{
                color: "#141414",
                fontSize: "36px",
                marginLeft: "1rem",
              }}
            >
              CWA Infinity Junior
            </Text> */}
            <Stack>
              <p>
                <br />
              </p>
              <p>
                CWA Infinity Option is a Suite of Systematic Index Options Trading
                Strategies
              </p>
              <p>
                <br />
              </p>
              <p>It has a margin requirement of INR 30,00,000 .</p>
              <p>
                <br />
              </p>
              <p>
                Each strategy in the suite is entirely rule-based and
                objectively designed taking into consideration various
                quantitative parameters.
              </p>
              <p>
                <br />
              </p>
              <p>
                The suite is a combination of multiple non-correlated strategies
                that aid in risk management through diversification.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                This suite contains a mix of 24 strategies, most of which are
                intra-day. It is a combination of directional and
                non-directional strategies, which further include option buying
                as well as selling strategies in Nifty and Bank Nifty Options
              </p>
              <p>
                <br />
              </p>
              <p>
                They help in attaining &nbsp;superior risk-adjusted returns year
                after year along with a smooth equity curve
              </p>
              <p>
                <br />
              </p>
            </Stack>
          </>
        ) : (
          <>
            {/* <Text
              style={{
                color: "#141414",
                fontSize: "36px",
                marginLeft: "1rem",
              }}
            >
              CWA Infinity Mini
            </Text> */}
            <Text>
              <p>
                CWA Infinity {props?.name === "Infinity Junior" ? "Junior"  : props?.name === "Infinity Intraday Option" ? "Intraday Option"  :  "Mini" } is a Suite of Systematic Index Options Trading
                Strategies.
              </p>
              <p>
                <br />
              </p>
              <p>It has a margin requirement of INR {props?.name === "Infinity Junior" ? "21,00,000"  
              : props?.name === "Infinity Intraday Option" ?   "25,00,000" : "11,00,000" }</p>
              <p>
                <br />
              </p>
              <p>
                Each strategy in the suite is entirely rule-based and
                objectively designed taking into consideration various
                quantitative parameters.
              </p>
              <p>
                <br />
              </p>
              <p>
                The suite is a combination of multiple non-correlated strategies
                that aid in risk management through diversification.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                This is a purely intra-day suite, which includes various
                directional as well as non-directional option selling strategies
                in Nifty and Bank Nifty Options
              </p>
              <p>
                <br />
              </p>
              <p>
                They help in attaining &nbsp;superior risk-adjusted returns year
                after year along with a smooth equity curve
              </p>
            </Text>
          </>
        )}
      </Stack>
    </>
  );
};

export default Content;
