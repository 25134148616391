import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tab,
  TabList,
  SimpleGrid,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import StrategyCard from "components/elements/StrategyCard";
import React, { useEffect, useRef, useState } from "react";
import NoPosition from "assets/not-found.svg";
import { CheckIcon } from "@chakra-ui/icons";
import Pricing from "./Pricing";

const PricingCard = (props) => {
  const { public: data, user } = props;
  const [active, setActive] = useState(0);
  const [savePWDModal, setSavePWD] = useState(false);
  const secretModalRef = useRef();
  const [firstStrg, setfirstStrg] = useState("Subscribe")
  const [secStrg, setsecStrg] = useState("Subscribe")
  
  
  return (
    <>
      <Stack p={4} bg="white">
        <Tabs onChange={(index) => setActive(index)}>
          <TabList width="fit-content">
            <Tab color={active === 0 ? "brand.900" : "#aeaeae"} fontSize={16}>
              Suites
            </Tab>
          </TabList>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            alignItems={"end"}
            gap={"45px"}
            justifyContent={"flex-start"}
            ml={9}
          >
          
            <Pricing
              data={{ packageName: "Infinity Mini", packagePrice: "11,00,000",BtnNAme:firstStrg }}
            />
            <Pricing
              data={{ packageName: "Infinity Junior", packagePrice: "21,00,000", BtnNAme:secStrg }}
            />
            
          </Stack>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            alignItems={"end"}
            gap={"45px"}
            mt={"45px"}
            justifyContent={"flex-start"}
            ml={9}
          >
             <Pricing
              data={{
                packageName: "Infinity Intraday Option",
                packagePrice: "25,00,000",
              }}
            />
            
            <Pricing
              data={{
                packageName: "Infinity Option",
                packagePrice: "30,00,000",
              }}
            />
          </Stack>
        </Tabs>
      </Stack>
    </>
  );
};

export default PricingCard;
