import { Box, Stack, Text } from "@chakra-ui/react";
import FormButton from "controls/Button";
import FormSelect from "controls/FormSelect";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

export const interval = [
  {
    key: "1 Minute",
    label: "1 Minute",
    value: "1 Minutes",
    display: "Minutes",
    tDisplay: "1",
  },
  {
    key: "5 Minute",
    label: "5 Minutes",
    value: "5 Minutes",
    display: "Minutes",
    tDisplay: "5",
  },
  // {
  //   key: "10 Minute",
  //   label: "10 Minutes",
  //   value: "10 Minutes",
  //   display: "Minutes",
  //   tDisplay: "10",
  // },
  {
    key: "15 Minute",
    label: "15 Minutes",
    value: "15 Minutes",
    display: "Minutes",
    tDisplay: "15",
  },
  {
    key: "30 Minute",
    label: "30 Minutes",
    value: "30 Minutes",
    display: "Minutes",
    tDisplay: "30",
  },
  {
    key: "60 Minute",
    label: "1 Hour",
    value: "1 Hours",
    display: "Hours",
    tDisplay: "1",
  },
  {
    key: "180 Minute",
    label: "3 Hours",
    value: "3 Hours",
    display: "Hours",
    tDisplay: "3",
  },
  {
    key: "1440 Minute",
    label: "1 Day",
    value: "1 Days",
    display: "Days",
    tDisplay: "1",
  },
];

export const position = [
  { key: "p-long", value: "long", label: "Long" },
  { key: "p-short", value: "short", label: "Short" },
  { key: "p-both", value: "both", label: "Both" },
];

export const perabs = [
  { key: "%", value: "%", label: "%" },
  { key: "abs", value: "abs", label: "abs" },
];

export const chartType = (val = null, type) => [
  { key: "candlestick-1", value: "candlestick", label: "Candlestick" },
  { key: "heikin-ashi-1", value: "heikin-ashi", label: "Heikin-Ashi" },
  {
    key: "renko-1",
    value: "renko",
    label: `Renko${
      val === "0" || val === 0 ? "" : "(" + val + "," + type + ")"
    }`,
  },
];

export const andOrTypeData = [
  { key: "and", label: "AND", value: "and" },
  { key: "or", label: "OR", value: "or" },
];
export const tradeDays = [
  {
    key: "day-1",
    label: "Monday",
    value: "Monday",
  },
  {
    key: "day-2",
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    key: "day-3",
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    key: "day-4",
    label: "Thursday",
    value: "Thursday",
  },
  {
    key: "day-5",
    label: "Friday",
    value: "Friday",
  },
];

export const kotakRegion = [
  {
    key: 1,
    label: "https://tradeapi.kotaksecurities.com",
    value: "https://tradeapi.kotaksecurities.com",
  },
  {
    key: 2,
    label: "https://ctradeapi.kotaksecurities.com",
    value: "https://ctradeapi.kotaksecurities.com",
  },
];

export const orderTypeData = [
  { key: "order-1", label: "MARKET", value: "market" },
  { key: "order-2", label: "LIMIT", value: "limit" },
];

export const validityData = [
  {
    key: "validity-1",
    label: "DAY",
    value: "day",
  },
  {
    key: "validity-2",
    label: "IOC",
    value: "ioc",
  },
];
export const CommaSeparated = (val) => {
  if (val) {
    return parseFloat(val).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
    });
  }
};
export const CommaSeparatedWithoutFraction = (val) => {
  if (val) {
    return parseFloat(val).toLocaleString("en-IN", {
      minimumFractionDigits: 0,
    });
  }
};

export const tradingHolidays =
  "20160126,20160307,20160324,20160325,20160414,20160415,20160419,20160706,20160815,20160905,20160913,20161011,20161012,20161031,20161114,20170126,20170224,20170313,20170404,20170414,20170501,20170626,20170815,20170825,20171002,20171019,20171020,20171225,20180126,20180213,20180302,20180329,20180330,20180501,20180815,20180822,20180913,20180920,20181002,20181018,20181107,20181108,20181123,20181225,20190126,20190304,20190321,20190417,20190419,20190429,20190501,20190605,20190812,20190815,20190902,20190910,20191002,20191008,20191021,20191027,20191028,20191112,20191225,20200126,20200221,20200310,20200402,20200406,20200410,20200414,20200501,20200525,20200801,20200815,20200822,20200830,20201002,20201025,20201114,20201116,20201130,20201225";

export const ToastError = () => {
  // console.log(props);
  return (
    <Box
      position={"absolute"}
      top={0}
      right="0"
      height="100px"
      width="100px"
      background={"#000"}
    >
      Aasim
    </Box>
  );
};

export const handleNetworkError = () => {
  return <ToastError />;
};

export const segments = [
  { key: "option-1", label: "Option", value: "NFO-OPT" },
  { key: "future-1", label: "Future", value: "NFO-FUT" },
];

export const productType = [
  { key: "intraday-1", label: "INTRADAY", value: "1" },
  { key: "delivery-1", label: "DELIVERY", value: "2" },
];
export const amtqty = [
  { key: "qty-1", value: "qty", label: "qty" },
  { key: "amt-1", value: "amt", label: "amt" },
];

export const maxTradePerDay = [
  { key: "1", value: "1", label: "1" },
  { key: "2", value: "2", label: "2" },
  { key: "3", value: "3", label: "3" },
  { key: "4", value: "4", label: "4" },
  { key: "5", value: "5", label: "5" },
  { key: "all", value: "600", label: "All" },
];

export const orderType = {
  1: "Market",
  2: "Limit",
};

export const orderStatus = [
  { key: "status-1", label: "Completed", value: "completed" },
  { key: "status-2", label: "Rejected", value: "rejected" },
  { key: "status-3", label: "Error", value: "error" },
];

const comparatorObj = {
  crosses_above: "Crosses Above",
  crosses_below: "Crosses Below",
  equal_to: "Equal To",
  higher_than: "Higher Than",
  lower_than: "Lower Than",
};

export const strategyLiveFor = [
  { key: "1", label: "1 Day", value: 1 },
  { key: "2", label: "3 Days", value: 3 },
  { key: "3", label: "5 Days", value: 5 },
  { key: "4", label: "10 Days", value: 10 },
  { key: "5", label: "20 Days", value: 20 },
  { key: "6", label: "50 Days", value: 50 },
];

export const strategyVirtualFor = [
  { key: "1", label: "1 Day", value: 1 },
  { key: "2", label: "3 Days", value: 3 },
  { key: "3", label: "5 Days", value: 5 },
];

export const renkoBrickType = [
  { key: "abs-1", label: "abs", value: "abs" },
  { key: "perc-1", label: "%", value: "%" },
];

export const filterCompartorData = (str_data) => {
  let newLabel = str_data.includes("undefined")
    ? str_data.split("undefined").join("")
    : str_data;
  if (str_data) {
    Object.keys(comparatorObj).forEach((val) => {
      if (str_data.includes(val)) {
        let regex = new RegExp(val, "g");
        newLabel = newLabel.replace(regex, comparatorObj[val]);
      }
    });
    return newLabel;
  } else {
    return str_data;
  }
};

export const thousandLakhFormatted = (val) => {
  if (val) {
    let isNegative = val < 0 ? true : false;
    val = Math.abs(val);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(2) + " K";
    }
    return isNegative ? "-" + val : val;
  }
  return val;
};

export const AddBuyFields = (
  buySellOptions,
  setPopuphandle,
  isBuyOrSellRef,
  opType,
  buyData,
  sellData,
  setCurrentIndexField,
  setBuyData,
  setSellData,
  validateField,
  classNames
) => {
  const methods = useFormContext();
  // eslint-disable-next-line
  const [trigger, setTrigger] = useState(true);
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${opType}Indicator`,
  });
  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields, append]);

  const formatOptionLabel = (props) => {
    return (
      <Stack spacing="0">
        <Text fontSize={16}>{props.label}</Text>
        <Text fontSize={14} color="gray.500">
          {props.description}
        </Text>
      </Stack>
    );
  };

  const handleChange = async (e, index) => {
    // methods.setValue(`${opType}Indicator[${index}].indicatorValue`, e);
    isBuyOrSellRef.current = await `${opType}`;
    setCurrentIndexField(index);
    setPopuphandle(e);
  };

  let resetData =
    opType === "sell" && sellData
      ? sellData
      : opType === "buy" && buyData
      ? buyData
      : null;

  let p_type = methods.getValues("position_type")?.label;
  const valiDate =
    opType === "buy" && p_type === "Long"
      ? "This field is required"
      : opType === "sell" && p_type === "Short"
      ? "This field is required"
      : p_type === "Both"
      ? "This field is required"
      : false;

  const handleOrAndChange = (e, index) => {
    let [newData, setData] =
      opType === "sell" ? [sellData, setSellData] : [buyData, setBuyData];
    let items = [...newData];
    let item = { ...items[index] };
    item.andOrValue = e;
    items[index] = item;
    setData(items);
  };
  const removeFromIndex = (index) => {
    methods.setValue(`${opType}Indicator[${index}].indicatorValue`, null);
    remove(index);
    resetData.splice(index, 1);
    methods.clearErrors(`${opType}Indicator`);
  };
  useEffect(() => {
    if (
      methods.getValues(`${opType}Indicator[0].indicatorValue`) &&
      !methods.getValues(`${opType}Indicator[0].indicator_name`)
    ) {
      methods.setValue(`${opType}Indicator[0].indicatorValue`, null);
    }
  }, [methods, opType]);
  let current_data = null;
  let errors = methods.formState.errors;
  const generateErrorsForNestedField = (name, index, field) => {
    if (field !== undefined) {
      return errors[`${opType}Indicator`] &&
        errors[`${opType}Indicator`][index] &&
        errors[`${opType}Indicator`][index][field]
        ? errors[`${opType}Indicator`][index][field][name]
        : errors;
    } else {
      return errors[`${opType}Indicator`] && errors[`${opType}Indicator`][index]
        ? errors[`${opType}Indicator`][index][name]
        : errors;
    }
  };
  const [required , setRequired] = useState(false);
  return (
    <>
      {fields?.map((item, index) => {
        current_data =
          opType === "sell" && sellData && sellData[index]
            ? sellData[index].indicatorValue
            : opType === "buy" && buyData && buyData[index]
            ? buyData[index].indicatorValue
            : null;
        return (
          <Stack direction={"row"} key={item.id} marginTop={index > 0 && 4}>
            {index > 0 && (
              <Stack flex={1} className={classNames && classNames[2]}>
                <FormSelect
                  control={control}
                  error={methods.formState.errors}
                  name={`${opType}Indicator[${index}].andOrValue`}
                  options={andOrTypeData}
                  label=""
                  defaultValue={
                    resetData && resetData[index]?.andOrValue
                      ? resetData[index]?.andOrValue
                      : methods.getValues(
                          `${opType}Indicator[${index}].andOrValue`
                        )
                      ? methods.getValues(
                          `${opType}Indicator[${index}].andOrValue`
                        )
                      : andOrTypeData[0]
                  }
                  onChangeNew={(e) => {
                    methods.setValue(
                      `${opType}Indicator[${index}].andOrValue`,
                      e
                    );
                    handleOrAndChange(e, index);
                  }}
                  isSearchable={false}
                  rules={{
                    required:
                      resetData?.length > 0 ? false : "This is required",
                  }}
                />
              </Stack>
            )}
            <Stack flex={3} className={classNames && classNames[0]}>
              <FormSelect
                variant="outline"
                onOpen={
                  resetData && resetData[index]?.indicatorValue
                    ? () => handleChange(resetData[index].indicatorValue, index)
                    : null
                }
                // onSelect={(value) => console.log(value)}
                name={`${opType}Indicator[${index}].indicatorValue`}
                label=""
                options={buySellOptions}
                control={methods.control}
                error={
                  generateErrorsForNestedField("indicatorValue", index)
                  // methods.formState.errors[
                  //   `${isBuyOrSellRef.current}Indicator`
                  // ] &&
                  // methods.formState.errors[
                  //   `${isBuyOrSellRef.current}Indicator`
                  // ][index]
                  //   ? methods.formState.errors[
                  //       `${isBuyOrSellRef.current}Indicator`
                  //     ][index]["indicatorValue"]
                  //   : methods.formState.errors
                }
                onChangeNew={(value) => {
                  handleChange(value, index);
                }}
                myLabel={resetData && resetData[index]?.my_label?.value}
                defaultValue={resetData && resetData[index]?.indicatorValue}
                rules={{ required: validateField ? validateField : required ? "This is required" : valiDate }}
                formatOptionLabel={formatOptionLabel}
                filterOption={(option, searchText) => {
                  return (
                    option?.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    option?.data?.description
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  );
                }}
              />
            </Stack>
            <FormButton
              text="x"
              variant="outline"
              onClick={() => {
                setRequired(false);
                removeFromIndex(index);
              }}
            />
            {index === fields.length - 1 && (
              <FormButton
                text="+"
                onClick={
                  current_data
                    ? () => {
                        append({});
                        setTrigger(true);
                        setRequired(true);
                        methods.setValue(
                          `${opType}Indicator[${fields.length}].indicatorValue`,
                          null
                        );
                      }
                    : null
                }
                className={classNames && classNames[1]}
              />
            )}
          </Stack>
        );
      })}
    </>
  );
};

// Orders
export const STATUS_ORDER = {
  0: "user_not_responded",
  1: "order_notification_send",
  2: "user_accepted",
  3: "user_rejected",
  4: "submitted_to_exchange",
  5: "accepted_by_exchange",
  6: "order_partially_completed",
  7: "order_completed",
  8: "order_cancelled",
  9: "order_rejected",
};

export const TriggerDefination = {
  LBEN: "Long Buy Entry",
  LSEX: "Long Sell Exit",
  LSSL: "Long Sell StopLoss",
  LSTP: "Long Sell TakeProfit",
  LSTSL: "Long Sell TrailingStopLoss",
  LSSO: "Long Sell SquareOff",
  SBSO: "Short Buy SquareOff",
  SSEN: "Short Sell Entry",
  SBEX: "Short Buy Exit",
  SBSL: "Short Buy StopLoss",
  SBTP: "Short Buy TakeProfit",
  SBTSL: "Short Buy TrailingStopLoss",
};

export const removeSpaceAndMerge = (val, reverse = false) => {
  /*
    This function does two things 
    a) if value is passed like order_completed then it convert into 
      it "Order Completed" (for this to work reverse argument need to pass true)
    b) if value is passed like Order Completed then it convert into
      it "order_completed"
  */
  if (val) {
    let new_data = "";
    let arr = reverse ? val.split("_") : val.split(" ");
    if (reverse) {
      for (let i = 0; i < arr.length; i++) {
        if (i) {
          new_data += " ";
        }
        new_data += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (i) {
          new_data += "_";
        }
        new_data += arr[i].charAt(0).toLowerCase() + arr[i].slice(1);
      }
    }
    return new_data;
  } else {
    // If value is empty then it simply return the empty string
    return val;
  }
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const arr1 = Array(20)
  .fill(-1)
  .map((item, i) => ({
    label: `ATM${item * (i + 1)}`,
    value: item * (i + 1),
  }))
  .reverse();
const arr2 = Array(21)
  .fill(1)
  .map((item, i) => ({
    label: `ATM+${item * i}`,
    value: item * i,
  }));
export const atms = [...arr1, ...arr2];

export const createOwnObject = (obj, expiry, sptFut) => {
  let objKey = Object.keys(obj);
  let res = [];
  for (let i = 0; i < objKey.length; i++) {
    if (typeof obj[objKey[i]] !== "object") {
      delete obj[objKey[i]];
    } else {
      res.push({
        expiry: expiry,
        transaction_type: obj[objKey[i]]?.type.toUpperCase(),
        autostrike: obj[objKey[i]]?.autostrike,
        autostrike_atm: sptFut,
        right: obj[objKey[i]]?.right,
        qty: obj[objKey[i]]?.qty ? obj[objKey[i]]?.qty : obj[objKey[i]]?.isType,
      });
    }
  }
  return res;
};

export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== "")
      .filter(([_, v]) => v !== null)
      .filter(([_, v]) => v !== undefined)
  );
};
