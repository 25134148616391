import { Icon } from "@chakra-ui/react";
import React from "react";

const BrokerActiveIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "20px"}
      height={height ? height : "21px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      _hover={hover}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3H18.5C19.3284 3 20 2.32843 20 1.5C20 0.671573 19.3284 0 18.5 0H1.5ZM19 4.5H1V14C1 15.1046 1.89543 16 3 16H9.25V20C9.25 20.4142 9.58579 20.75 10 20.75C10.4142 20.75 10.75 20.4142 10.75 20V16H17C18.1046 16 19 15.1046 19 14V4.5ZM14.6431 8.38599C14.8562 8.0308 14.741 7.5701 14.3859 7.35699C14.0307 7.14388 13.57 7.25906 13.3569 7.61424L12.2746 9.41799C11.905 10.034 11.0344 10.0926 10.5856 9.53159C9.48386 8.15436 7.34655 8.29812 6.43912 9.81049L5.35687 11.6142C5.14376 11.9694 5.25893 12.4301 5.61412 12.6432C5.9693 12.8563 6.43 12.7412 6.64311 12.386L7.72536 10.5822C8.09498 9.96621 8.96555 9.90765 9.41434 10.4686C10.5161 11.8459 12.6534 11.7021 13.5609 10.1897L14.6431 8.38599Z"
        fill="#00a3be"
      />
    </Icon>
  );
};

export default BrokerActiveIcon;
