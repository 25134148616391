import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createStrategy: false,
};

const walkthroughSlice = createSlice({
  name: "walkthrough",
  initialState,
  reducers: {
    toggleUniversalWalkthrough: (state, action) => {
      state[action.payload.myKey] = !state[action.payload.myKey];
    },
  },
  extraReducers: {},
});

export const { toggleUniversalWalkthrough } = walkthroughSlice.actions;

export default walkthroughSlice.reducer;
