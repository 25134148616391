import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Tooltip,
  useToast,
  SimpleGrid,
  Show,
  Hide,
  useDisclosure
} from "@chakra-ui/react";
import {
  getPublicStrategies,
  getTotalCount,
  getUserStrategies,
} from "apis/callListStrategy";
import { getActiveBroker } from "apis/strategy";
import { getUserBalance } from "apis/user";
import LiveIcon from "assets/live";
import UserIcon from "assets/user";
import VirtualIcon from "assets/virtual";
import CreateStrategyMockup from "components/elements/CreateStrategyMockup";
import Refer from "components/elements/Refer";
import withRouter from "components/hoc/withRouter";
import React, { useEffect, useState } from "react";
import { CommaSeparated } from "utils/common";
import { totalEarningDetails } from "apis/returns";
import routes from "utils/routes";
import DashTab from "./DashTab";
import Deployed from "./Deployed";
import Investment from "./Investment";
import OverView from "./OverView";
import TotalFunds from "assets/total-funds.svg";
import TotalReturns from "assets/total-returns.svg";
import OpenPositions from "assets/open-positions.svg";
import ExecutedOrders from "assets/executed-orders.svg";
import { getOrderCount } from "apis/orderbook";
import { createFCM } from "apis/firebaseapi";
import firebase from "../../firebase";
import { toggleUniversalWalkthrough } from "app/slices/walkthrough";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "./useMediaQuery";
import InfoItem from "./InfoItem";
import PricingCard from "components/common/PricingCard";
import { secretDetails, viewProfile } from "apis/profile";
import UserDetails from "components/modals/UserDetails";
const Dashboard = (props) => {
  const [publicStrategies, setPublicStrategies] = useState([]);
  const [userStrategies, setUserStrategies] = useState([]);
  const [userDetails, setUserDetails] = useState({
    broker: null,
    count: null,
  });
  const [totalFunds, setTotalFunds] = useState({});
  const [ordersCount, setOrdersCount] = useState({});
  const [totalEarning, setTotalEarning] = useState(null);
  const toast = useToast();
  const [onlyOne, setonlyOne] = useState(false)
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    token
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      getPublicStrategies().then((res) => setPublicStrategies(res));
      getUserStrategies().then((res) => setUserStrategies(res));
      Promise.all([getActiveBroker(), getTotalCount()]).then((res) => {
        setUserDetails({
          // funds: res[0],
          broker: res[0],
          count: res[1],
        });
      });
      getUserBalance()
        .then((res) => setTotalFunds(res))
        .catch((error) =>
          toast({
            title: error?.response?.data?.errors?.message,
            isClosable: true,
            position: "top-right",
            status: "error",
          })
        );
      getOrderCount().then((res) => setOrdersCount(res));
    }, 2000);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const messaging = firebase.messaging();
      Notification.requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          createFCM(token)
            .then((res) => {})
            .catch((err) => console.log("ERROR ", err.response));
        })
        .catch((err) => {
          console.log("Error ", err);
        });
      totalEarningDetails().then((res) => {
        setTotalEarning(res);
      });
    }, 2000);
  }, []);

  useEffect(() => {
    if(token !== null && !onlyOne){
    Promise.all([viewProfile()]).then((res) => {
      !res[0]?.is_required_data_given &&  onOpen()
      setonlyOne(true)
    });}
  }, [token]);

  const overViewDetails = [
    {
      title: "Total Funds",
      value: `₹ ${CommaSeparated(totalFunds?.equity?.available_funds) || 0}`,
      color: "#F5E2FF",
      link: "/dashboard",
      border: "#8104C6",
      icon: TotalFunds,
      isNotClickable: false,
    },
    {
      title: "Total Returns",
      value: `₹ ${CommaSeparated(totalEarning?.total_earnings) || 0}`,
      color: totalEarning?.total_earnings >= 0 ? "#e2ffe8" : "#FFE2E2",
      link: routes.returns,
      border: totalEarning?.total_earnings >= 0 ? "#0da000" : "#E90303",
      icon: TotalReturns,
      isNotClickable: true,
    },
  ];

  const orderDetails = [
    {
      title: "Open Positions",
      value: ordersCount?.open_orders || 0,
      color: "#FFF0E2",
      link: routes.orders,
      border: "#6D3500",
      icon: OpenPositions,
      tab: 1,
      isNotClickable: true,
    },
    {
      title: "Executed Orders",
      value: ordersCount?.executed_orders || 0,
      color: "#E2F4FF",
      link: routes.orders,
      border: "#005285",
      icon: ExecutedOrders,
      tab: 0,
      isNotClickable: true,
    },
  ];

  const walkthroughData = [
    {
      title: "Create Strategy",
      onClick: () => {
        dispatch(
          toggleUniversalWalkthrough({
            myKey: "createStrategy",
          })
        );
        props.router.navigate("/strategy");
      },
    },
    {
      title: "Optimisation",
    },
    {
      title: "Live Trade",
    },
    {
      title: "Virtual Trade",
    },
    {
      title: "Astra",
    },
    {
      title: "Backtesting",
    },
  ];

  const deployedData = [
    // {
    //   title: "Live Trade",
    //   message: userDetails?.count?.live || "Not Deployed",
    //   icon: <LiveIcon color="#0DA000" height={"18px"} />,
    //   bg: "#D9FFE1",
    //   isActive: userDetails?.count?.live ? true : false,
    //   onClick: () => props.router.navigate(routes.live),
    // },
    // {
    //   title: "Virtual Trade",
    //   message: userDetails?.count?.virtual || "Not Deployed",
    //   icon: <VirtualIcon color="#D08900" height={"18px"} />,
    //   bg: "#FCFFD9",
    //   isActive: userDetails?.count?.virtual ? true : false,
    //   onClick: () => props.router.navigate(routes.virtual),
    // },
    {
      title: "Active Brokers",
      message: userDetails?.broker?.broker || "No Active Brokers",
      icon: <UserIcon color="#4055A0" height={"18px"} />,
      bg: "#EFF3FF",
      isActive: userDetails?.broker?.active,
      onClick: () => props.router.navigate(routes.broker),
    },
  ];

  return (
    <>
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem w="100%" colSpan={3}>
        <Grid gap={4}>
          <GridItem w="100%">
            <OverView data={overViewDetails} title={"Overview"} />
          </GridItem>
          <GridItem w="100%">
            <Grid gap={4} templateColumns="repeat(3, 1fr)">
              <GridItem w="100%" colSpan={4}>
                <Stack spacing={4}>
                  <Deployed
                    title="Deployed"
                    data={deployedData}
                    isDeployed={true}
                  />
                  <OverView data={orderDetails} title={"Orders"} />
                </Stack>
              </GridItem>
              {/* <GridItem w="100%">
                <Deployed
                  title="Walkthrough"
                  data={walkthroughData}
                  isDeployed={false}
                />
              </GridItem> */}
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem w="100%">
      <Investment />
          {/* <CreateStrategyMockup /> */}
       
      </GridItem>
      <GridItem w="100%" colSpan={4}>
        {/* <DashTab public={publicStrategies} user={userStrategies} /> */}
        <PricingCard/>
      </GridItem>
      {/* <GridItem w="100%" colSpan={3}>
        <Accordion defaultIndex={[0]}>
          <AccordionItem
            bg="white"
            _hover={{
              bg: "white",
            }}
            border="none"
          >
            <h2>
              <AccordionButton
                bg="white"
                _hover={{
                  bg: "white",
                }}
              >
                <Box flex="1" textAlign="left">
                  What is Algorithmic Trading Strategy?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              A 'Strategy' is a combination of various conditions that define
              when to buy or sell a particular Trading Symbol on predefined
              algorithms to trade. For every trading strategy, one needs to
              define assets to trade, entry/exit points, and money{" "}
              <Box
                onClick={() =>
                  window.open(
                    "https://support.keev.tech/portal/en/kb/articles/what-is-algorithm-strategy"
                  )
                }
                as="span"
                ml="4px"
                color="#01A2BB"
                cursor="pointer"
              >
                read more
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            bg="white"
            _hover={{
              bg: "white",
            }}
            border="none"
            mt="4"
          >
            <h2>
              <AccordionButton
                bg="white"
                _hover={{
                  bg: "white",
                }}
              >
                <Box flex="1" textAlign="left">
                  How to Create Algorithm Trading Strategy?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The Keev Strategy Builder is a simple as selecting technical
              indicators, keying in stop loss and target profit percentage and
              selecting the stocks you want to trade with that enables users
              to create relatively complex strategies without programming{" "}
              <Box
                onClick={() =>
                  window.open(
                    "https://support.keev.tech/portal/en/kb/articles/how-to-create-algorithm-trading-strategy"
                  )
                }
                as="span"
                ml="4px"
                color="#01A2BB"
                cursor="pointer"
              >
                read more
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </GridItem>
      <GridItem w="100%">
        <Refer />
        
      </GridItem> */}
    
    </Grid>
    <Text height={"22px"}  margin={"10px auto"} width={"100%"} display={"flex"} justifyContent={"center"}>  Powered By Novotoc Infotech Pvt Ltd </Text>
 
 

    {isOpen && (
        <UserDetails
          onClose={onClose}
          isOpen={isOpen}
          
        />
      )}
  </>
  );
};

export default withRouter(Dashboard);
