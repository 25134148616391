import axios from "axios";
import { handleNetworkError } from "./common";
export const API_URL = "https://api.keev.tech/";//"https://uat.keev.co.in/" //"https://bigul.keev.tech/"  //"https://jiffy.keev.tech:8001/"; // "https://choice.keev.tech:8001/"; //

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

instance.defaults.timeout = 250000;
instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept"] = "application/json";

instance.interceptors.request.use(
  (config) => {
    const authToken = window.localStorage.getItem("accessToken");
    if (authToken) {
      config.headers["Authorization"] = "JWT " + authToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    handleNetworkError();
    if (error.response.status === 500) {
    } else if (error.response.status === 403) {
    } else if (error.response.status === 404) {
    } else if (error.response.status === 400) {
    } else if (error.response.status === 409) {
    } else if (error.response.status === 401) {
      localStorage.clear();
      // store.dispatch(logout());
      if (window.location.pathname === "/option-analyzer") {
        return;
      } else if (window.location.pathname === "/notifications") {
        return;
      } else {
        localStorage.clear();
         window.open("https://infinity.compoundingwealth.co.in/", "_self");
      }
    }
    return Promise.reject(error);
  }
);

export const request = (url, method = "GET", data, isBaseChange = false) => {
  return instance({
    url: url,
    //...{baseURL : isBaseChange ? `${SERVER_URL}/tradingview/`:API_URL},
    method: method,
    data: data,
  });
};

export default instance;
