import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Avatar,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import MyAccount from "assets/myaccount";
import DailyUsageIcon from "assets/dailyUsage";
import StatementIcon from "assets/statement";
import JoinKeevIcon from "assets/joinKeev";
import knowedgebase from "assets/KnowledgeBase.png"
import ReferIcon from "assets/Refer&earn.png";
import ReferIcons from "assets/refer";
import TermsIcon from "assets/terms";
import LogoutIcon from "assets/logout";
import withRouter from "components/hoc/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { logout } from "app/slices/auth";
import routes from "utils/routes";

const Profile = (props) => {
  const { isOpen, onClose } = props;
  const { user_name, email } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const chnageBg=props?.isOpen
  const sideLinks = [
    {
      name: "My Account",
      link: routes.profile,
      icon: <MyAccount />,
    },
    // {
    //   name: "Daily Usage",
    //   link: routes.dailyUsage,
    //   icon: <DailyUsageIcon />,
    // },
    // {
    //   name: "Pricing",
    //   link: routes.pricing,
    //   icon: <ReferIcons/>,
    //   state: {state:chnageBg},
    // },
    // {
    //   name: "Join KEEV Community",
    //   link: " ",
    //   icon: <JoinKeevIcon />,
    // },
    // {
    //   name: "Knowledge Base",
    //   link: " ",
    //   icon: <Image  src={knowedgebase} alt="knowedgebase"/>,
    // },
    // {
    //   name: "Refer & Earn",
    //   link: routes.refer,
    //   icon: <Image  src={ReferIcon} alt="refIcon"/>,
    // },
    // {
    //   name: "Terms of Service",
    //   link: routes.tnc,
    //   icon: <TermsIcon />,
    // },
    
  ];
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay top={"60px"} />
      <DrawerContent top="60px !important">
        <DrawerBody>
          <Stack direction="row">
            <Avatar
              name={user_name}
              border="1px solid #00a3be"
              background="#EBFAFC"
              color="#00a3be"
            />
            <Stack spacing={0}>
              <Text fontSize={"18px"} color="#141414">
                {user_name}
              </Text>
              <Text fontSize={14} color="#00a3be">
                {email}
              </Text>
            </Stack>
          </Stack>
          {sideLinks.map((item, i) => {
            if (item.name.includes("Knowledge Base")) {
              return (
                <>
                  <Divider my={3} />
                  <Accordion allowToggle>
                    <AccordionItem border="none !important" p={0}>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            p={0}
                            _hover={{
                              bg: "transparent",
                            }}
                            _focusVisible={{
                              boxShadow: "none",
                            }}
                            onClick={() => {
                              window.open(
                                "https://support.keev.tech/portal/en/kb/keev",
                                "_blank"
                              );
                              onClose();
                            }}
                          >
                            {" "}
                            <HStack spacing={4} alignItems="center">
                              {item?.icon}
                              <Text fontSize={18} color="#888888">
                                {item.name}
                              </Text>
                            </HStack>
                            <ChevronRightIcon
                              w={4}
                              h={4}
                              color="#888888"
                              ml={"auto"}
                            />
                          </AccordionButton>
                        </>
                      )}
                    </AccordionItem>
                  </Accordion>
                </>
              );
            }
            if (!item.name.includes("Community")) {
              return (
                <Stack spacing={0} key={i}>
                  <Divider my={3} />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    cursor="pointer"
                    onClick={() => {
                      item.name === "Join KEEV Community"
                        ? window.open(item?.link)
                        : props?.router?.navigate(item?.link);
                      onClose();
                    }}
                  >
                    <HStack spacing={4} alignItems="center">
                      {item?.icon}
                      <Text fontSize={18} color="#888888">
                        {item.name}
                      </Text>
                    </HStack>
                    <ChevronRightIcon w={4} h={4} color="#888888" />
                  </Stack>
                </Stack>
              );
            } else {
              return (
                <>
                  <Divider my={3} />
                  <Accordion allowToggle>
                    <AccordionItem border="none !important" p={0}>
                      {({ isExpanded }) => (
                        <>
                          <AccordionButton
                            p={0}
                            _hover={{
                              bg: "transparent",
                            }}
                            _focusVisible={{
                              boxShadow: "none",
                            }}
                            onClick={() => {
                              window.open(
                                "//t.me/keev_simplifying_algo",
                                "_blank"
                              );
                              onClose();
                            }}
                          >
                            {" "}
                            <HStack spacing={4} alignItems="center">
                              {item?.icon}
                              <Text fontSize={18} color="#888888">
                                {item.name}
                              </Text>
                            </HStack>
                            <ChevronRightIcon
                              w={4}
                              h={4}
                              color="#888888"
                              ml={"auto"}
                            />
                          </AccordionButton>
                        </>
                      )}
                    </AccordionItem>
                  </Accordion>
                </>
              );
            }
          })}
        </DrawerBody>

        <DrawerFooter
          justifyContent={"flex-start"}
          gap={4}
          cursor="pointer"
          alignItems={"center"}
          onClick={() => {
            dispatch(logout());
            props.router.navigate("/");
            localStorage.clear();
          }}
        >
          <LogoutIcon />
          <Text fontSize={18} color="#E90303">
            Log Out
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default withRouter(Profile);
