import instance from "utils/interceptors";
import { callGetApiToServer } from "./promiseResolve";

export async function getStrategyDetails(backTestId) {
  try {
    let responseData = await callGetApiToServer(
      `api/strategy/v2/${backTestId}/`,
      "GET"
    );
    return responseData.data;
  } catch (error) {
    throw error;
  }
}

export const getStrategyData = async (url, strategyId) => {
  try {
    let responseData = await callGetApiToServer(`${url}${strategyId}/`, "GET");
    return responseData.data;
  } catch (error) {
    throw error;
  }
};

export async function deleteStrategy(strategyId) {
  try {
    let responseData = await callGetApiToServer(
      `api/strategy/v2/delete/${strategyId}/`,
      "GET"
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}

export const universalStrategy = async (method, data, id) => {
  try {
    let response = await instance[method](
      `/api/strategy/v2/${id ? id + "/" : ""}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserStrategies = async () => {
  try {
    const res = await instance.get("/api/strategy/v2/user_strategy_details/");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPublicStrategies = async () => {
  try {
    const res = await instance.get("/api/strategy/v2/public_strategy_details/");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalCount = async () => {
  try {
    let res = await instance.get("/api/strategy/v2/total_count/");
    return res.data;
  } catch (error) {
    throw error;
  }
};
