import { Button } from "@chakra-ui/react";
import React from "react";

const FormButton = (props) => {
  const {
    text,
    variant,
    icon,
    rightIcon,
    leftIcon,
    fontSize,
    fontWeight,
    hover,
    ...rest
  } = props;
  return (
    <Button
      textTransform={"capitalize"}
      fontSize={fontSize || 14}
      colorScheme={"blue"}
      variant={variant || "solid"}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      borderRadius={3}
      bg={variant === "outline" ? "transparent" : "brand.900"}
      fontWeight={fontWeight || "500"}
      borderColor={variant === "outline" ? "brand.900" : "transparent"}
      color={variant === "outline" ? "brand.900" : "white"}
      _hover={
        hover
          ? hover
          : {
              bg: variant === "outline" ? "brand.900" : "brand.900",
              color: variant === "outline" ? "white" : "white",
            }
      }
      {...rest}
    >
      {icon && <span>{icon}</span>}
      {text}
    </Button>
  );
};

export default FormButton;
