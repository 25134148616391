import { ChevronRightIcon } from "@chakra-ui/icons";
import { Stack, Text } from "@chakra-ui/react";
import React from "react";

const Deployed = (props) => {
  const { title, data, isDeployed } = props;
  return (
    <Stack bg="white">
      <Stack p={4} borderBottom="1px solid #e9ecef">
        <Text fontSize={18} color="#141414" fontWeight={500}>
          {title}
        </Text>
      </Stack>
      {data.map((item, i) => {
        return (
          <Stack
            p={3.5}
            borderBottom={i !== data.length - 1 && "1px solid #e9ecef"}
            direction="row"
            alignItems={"center"}
            cursor="pointer"
            onClick={item?.onClick ? item?.onClick : null}
            key={i}
          >
            {isDeployed &&
              // <Stack
              //   h="25px"
              //   w="25px"
              //   bg={item.bg}
              //   alignItems={"center"}
              //   justifyContent="center"
              //   borderRadius={"15px"}
              // >
              // </Stack>
              item.icon}
            <Text flex={2} color="#141414">
              {item.title}
            </Text>
            {isDeployed ? (
              <Stack
                direction={"row"}
                // flex={1.2}
                // justifyContent={"flex-start"}
              >
                {/* <Box
                  h={3}
                  w={3}
                  bg={item?.isActive ? "#0DA000" : "#D9D9D9"}
                  // borderRadius={"50%"}
                  display="block"
                /> */}
                <Text
                  textAlign="right"
                  color={item?.isActive ? "#0DA000" : "#A6A5A5"}
                >
                  {item.message}
                </Text>
              </Stack>
            ) : (
              <ChevronRightIcon />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Deployed;
