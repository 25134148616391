import { callApiToServer } from "./promiseResolve";

export async function createFCM(token) {
  try {
    let data = { registration_id: token, cloud_message_type: "FCM" };
    let responseData = await callApiToServer(
      `api/user/v2/device/gcm/`,
      "POST",
      data
    );
    return responseData;
  } catch (error) {
    throw error;
  }
}
