import Dashboard from "components/dashboard";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { isFirstTime, isAuthenticated } = useSelector((state) => state.auth);
  const openNewTab = async () => {
    await localStorage.clear();
     window.open("https://infinity.compoundingwealth.co.in/", "_self");
  };

  return isFirstTime ? (
    <Dashboard />
  ) : isAuthenticated ? (
    <Outlet />
  ) : (
    openNewTab()
  );
};

export default PrivateRoute;
