import { Icon } from "@chakra-ui/react";
import React from "react";

const BrokerIcon = (props) => {
  const { color, height, width, hover, ...rest } = props;
  return (
    <Icon
      color={color}
      width={width ? width : "22px"}
      height={height ? height : "22px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      _hover={hover}
      {...rest}
    >
      <path
        d="M2 5V4.25C1.58579 4.25 1.25 4.58579 1.25 5H2ZM20 5H20.75C20.75 4.58579 20.4142 4.25 20 4.25V5ZM6.35688 12.6141C6.14377 12.9693 6.25894 13.43 6.61413 13.6431C6.96931 13.8562 7.43001 13.7411 7.64312 13.3859L6.35688 12.6141ZM8.08225 11.1963L7.43913 10.8104L8.08225 11.1963ZM13.9178 10.8037L13.2746 10.4179L13.9178 10.8037ZM15.6431 9.38587C15.8562 9.03069 15.7411 8.56999 15.3859 8.35688C15.0307 8.14377 14.57 8.25894 14.3569 8.61413L15.6431 9.38587ZM11.75 17C11.75 16.5858 11.4142 16.25 11 16.25C10.5858 16.25 10.25 16.5858 10.25 17H11.75ZM10.25 21C10.25 21.4142 10.5858 21.75 11 21.75C11.4142 21.75 11.75 21.4142 11.75 21H10.25ZM3 1.75H19V0.25H3V1.75ZM19 4.25H3V5.75H19V4.25ZM3 4.25C2.30964 4.25 1.75 3.69036 1.75 3H0.25C0.25 4.51878 1.48122 5.75 3 5.75V4.25ZM20.25 3C20.25 3.69036 19.6904 4.25 19 4.25V5.75C20.5188 5.75 21.75 4.51878 21.75 3H20.25ZM19 1.75C19.6904 1.75 20.25 2.30964 20.25 3H21.75C21.75 1.48122 20.5188 0.25 19 0.25V1.75ZM3 0.25C1.48122 0.25 0.25 1.48122 0.25 3H1.75C1.75 2.30964 2.30964 1.75 3 1.75V0.25ZM2 5.75H20V4.25H2V5.75ZM19.25 5V15H20.75V5H19.25ZM18 16.25H4V17.75H18V16.25ZM2.75 15V5H1.25V15H2.75ZM4 16.25C3.30964 16.25 2.75 15.6904 2.75 15H1.25C1.25 16.5188 2.48122 17.75 4 17.75V16.25ZM19.25 15C19.25 15.6904 18.6904 16.25 18 16.25V17.75C19.5188 17.75 20.75 16.5188 20.75 15H19.25ZM7.64312 13.3859L8.72537 11.5821L7.43913 10.8104L6.35688 12.6141L7.64312 13.3859ZM14.5609 11.1896L15.6431 9.38587L14.3569 8.61413L13.2746 10.4179L14.5609 11.1896ZM10.4143 11.4685C11.5161 12.8458 13.6534 12.702 14.5609 11.1896L13.2746 10.4179C12.905 11.0339 12.0344 11.0925 11.5857 10.5315L10.4143 11.4685ZM8.72537 11.5821C9.09499 10.9661 9.96556 10.9075 10.4143 11.4685L11.5857 10.5315C10.4839 9.15425 8.34656 9.298 7.43913 10.8104L8.72537 11.5821ZM10.25 17V21H11.75V17H10.25Z"
        fill={color}
      />
    </Icon>
  );
};

export default BrokerIcon;
